<template>
<v-container lighten-5>

    <v-row v-if="model">

        <v-col cols="12" sm="12" md="12">

            <v-btn class="mx-2 float-left rounded-xl" fab small @click="$router.push('/sistema/basededatos')">

                <v-icon dark>navigate_before</v-icon>

            </v-btn>

        </v-col>

        <v-col cols="12" sm="12" md="12">

            <v-row>

                <v-col cols="auto" md="12">

                    <v-card elevation="0" class="rounded-xl">

                        <v-card-title>

                            <span class="primary--text display-2">Base de datos</span>

                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-text>

                            <v-container>

                                <v-row>

                                    <v-col cols="12" sm="12" md="12"><span class="primary--text headline">Datos de la base de datos</span></v-col>

                                    <v-col cols="12" sm="12" md="4">

                                        <span class="primary--text">

                                            FECHA

                                        </span>{{ model.cobdFecha}}

                                    </v-col>

                                    <v-col cols="12" sm="12" md="4">

                                        <span class="primary--text">

                                            CONTRATO

                                        </span>{{ model.tblConfContratos[0].cocoNombre}}

                                    </v-col>

                                    <v-col cols="12" sm="12" md="4">

                                        <span class="primary--text">

                                            ESTADO

                                        </span>{{ model.estado.comaNombrecorto}}

                                    </v-col>

                                    <v-col cols="12" sm="12" md="4">

                                        <span class="primary--text">

                                            PERIODO

                                        </span>{{ model.anio.comaNombrelargo}} - {{model.mes.comaNombrelargo}}<br>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <span class="primary--text">
                                            REGIMEN
                                        </span>{{ model.regimen.comaNombrecorto}}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="model.cobdId">
            <Basedatospacientes v-bind:cobdId="model.cobdId"  />
        </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="12">
            <Loader />
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';
export default {
    mixins: [generales],
    name: 'Bdpanel',
    components: {
        Basedatospacientes: () => import('./BasededatosPacientes.vue')
    },
    data() {
        return {
            url: "sistema/basededatos/",
            model:null
        }
    },
    created: async function () {
        if (this.$route.params.id) {
            this.model = await this.$apiService.index(this.url + "view/" + this.$route.params.id).then(data => data);
        }
    },
}
</script>
