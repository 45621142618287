<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-form @submit.prevent="agregar">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text :to="{name:'Historias'}">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-text-field outlined dense label="Codigo" :error="$v.form.hiseCodigo.$error" v-model="form.hiseCodigo" v-on:keyup="$data.form.hiseCodigo = $event.target.value.toUpperCase()"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field outlined dense label="Nombre" :error="$v.form.hiseNombre.$error" v-model="form.hiseNombre" v-on:keyup="$data.form.hiseNombre = $event.target.value.toUpperCase()"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                        <v-text-field outlined dense label="Orden" :error="$v.form.hiseOrden.$error" v-model="form.hiseOrden" number></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                        <v-switch v-model="form.hiseHistorico" label="Historico"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                        <v-switch v-model="form.hiseCalculado" label="Calculado"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2">
                                        <v-switch dense v-model="form.hiseTriage" label="Triage"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field outlined label="Sexo" v-model="form.hiseSexo"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field outlined label="Edad inicial" v-model="form.hiseEdadinicio"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field outlined label="Edad final" v-model="form.hiseEdadfinal"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea outlined dense v-on:keyup="$data.form.hiseDescripcion = $event.target.value.toUpperCase()" v-model="form.hiseDescripcion" label="Descripcion"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea outlined dense v-model="form.hiseScript" label="Script"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-text class="grey lighten-3">
                            <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab href="#tab-1">
                                    Campos
                                    <v-icon>mdi-format-textbox</v-icon>
                                </v-tab>
                                <v-tab href="#tab-2">
                                    Servicios
                                    <v-icon>mdi-format-list-numbered</v-icon>
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item value="tab-1">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-row justify="space-around">
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-toolbar>
                                                        <v-toolbar-title>Campos</v-toolbar-title>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <v-btn text class="" @click="agregarCampo">Agregar nuevo</v-btn>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <div class="flex-grow-1"></div>
                                                    </v-toolbar>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" v-if="form.campos.length  > 0">
                                            <v-row v-for="(items,i) in form.campos" :key="'campo'+i">
                                                <v-col cols="12" sm="3" md="3">
                                                    <v-text-field outlined dense label="Codigo" :error="$v.form.campos.$each[i].hiseCodigo.$error" v-model="items.hiseCodigo"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4" md="4">
                                                    <v-text-field outlined dense label="Nombre" :error="$v.form.campos.$each[i].hiseNombre.$error" v-model="items.hiseNombre"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4" md="4">
                                                    <v-autocomplete outlined dense :error="$v.form.campos.$each[i].hiseTipoTipodato.$error" v-model="items.hiseTipoTipodato" :items="tipocampos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo dato" clearable>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="1" md="1">
                                                    <v-btn text icon color="error" @click="eliminarCampo(items)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" sm="1" md="1">
                                                    <v-text-field outlined dense label="Orden" :error="$v.form.campos.$each[i].hiseOrden.$error" v-model="items.hiseOrden" number></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="1" md="1">
                                                    <v-text-field outlined dense label="Num. de columnas(1-12)" :error="$v.form.campos.$each[i].hiseCol.$error" v-model="items.hiseCol" number></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field outlined label="Numero filas TEXTAREA" v-model="items.hiseFilas" number></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="4">
                                                    <v-text-field outlined label="Sexo" v-model="items.hiseSexo"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="4">
                                                    <v-text-field outlined label="Edad inicial" v-model="items.hiseEdadinicio"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="4">
                                                    <v-text-field outlined label="Edad final" v-model="items.hiseEdadfinal"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2" md="2">
                                                    <v-switch v-model="items.hiseRequerido" label="Requerido"></v-switch>
                                                </v-col>
                                                <v-col cols="12" sm="8" md="8">
                                                    <v-text-field outlined dense label="Valores" v-model="items.hiseValores"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-textarea outlined dense v-model="items.hiseDescripcion" label="Descripcion"></v-textarea>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-textarea outlined dense v-model="items.hiseDefault" label="Valor por defecto"></v-textarea>
                                                </v-col>
                                                <!-- <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense v-model="form.hiseScript" label="Script"></v-textarea>
                                        </v-col> -->

                                                <v-col cols="12" sm="12" md="12">
                                                    <v-textarea outlined dense v-model="items.hiseConsulta" label="Consulta"></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                </v-tab-item>
                                <v-tab-item value="tab-2">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-row justify="space-around">
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-toolbar>
                                                        <v-toolbar-title>Servicios</v-toolbar-title>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <v-btn text class="" @click="agregartblConfHistoriasservicios">Agregar nuevo</v-btn>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <div class="flex-grow-1"></div>
                                                    </v-toolbar>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-row justify="space-around" v-for="(items,i) in form.tblConfHistoriasservicios" :key="'servicio'+i">
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-autocomplete outlined v-model="items.coseId" :items="servicios" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio" clearable></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="2">
                                                    <v-btn text icon color="error" @click="eliminartblConfHistoriasservicios(items)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>

                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      loader: false,
      isNew: true,
      url: "sistema/historiasecciones/",
      token: this.$cookies.get("token"),

      secciones: [],
      servicios: [],
      tab: 'tab-1',
      form: {
        hiseCodigo: null,
        hiseNombre: null,
        hiseIdTipo: null,
        hiseIdDependencia: null,
        hiseScript: null,
        hiseTipoTipodato: null,
        hiseOrden: 0,
        hiseHistorico: 0,
        hiseCalculado: 0,
        hiseValores: null,
        hiseDefault: null,
        hiseDescripcion: null,
        hiseRequerido: 0,
        hiseCol: 3,
        hiseFilas: 4,
        hiseConsulta: null,
        tblConfHistoriasservicios: [],
        hiseEstado: 1,
        hiseSexo: null,
        hiseEdadinicio: null,
        hiseEdadfinal: null,
        hiseTriage: 0,
        campos: []

      },

      tiposecciones: [],
      tipocampos: [],
      depe: []
    };
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  validations: {
    form: {
      hiseCodigo: {
        required,
      },
      hiseNombre: {
        required,
      },

      hiseIdTipo: {
        required,
      },

      hiseOrden: {
        required,
      },
      campos: {
        $each: {
          hiseCodigo: {
            required,
          },
          hiseNombre: {
            required,
          },

          hiseIdTipo: {
            required,
          },
          hiseTipoTipodato: {
            required,
          },
          hiseCol: {
            required,
          },
          hiseOrden: {
            required,
          },
        }

      },

    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);

    this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
    this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    this.depe = await this.$apiService.index(`${this.url}indexsecciones`).then(data => data);
    this.servicios = await this.$apiService.index("sistema/servicios/index/").then(data => data);
    this.form.hiseIdTipo = this.tiposecciones.find(e => e.comaNombrecorto == 'SECCION').comaId;

    if (this.$route.params.id) {
      await this.editar(this.$route.params.id);
    }
    this.$store.commit('setCargaDatos', false);
  },
  methods: {
    async editar(id) {
      try {
        let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (data) {

          this.form = data;
          if (!this.form.tblConfHistoriasservicios) {
            this.form.tblConfHistoriasservicios = [];
          } else {
            for (let model of this.form.tblConfHistoriasservicios) {
              model.cups = {
                search: null,
                isLoading: false,
                cups: [model.tblConfCup]
              };
            }

          }
        }
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        this.$store.commit('setCargaDatos', true);
        try {
          if (this.isNew) {

            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.$router.push("/sistema/historias");
            }

          } else {

            var id = this.form.hiseId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.$router.push("/sistema/historias");
            }

          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.$store.commit('setCargaDatos', false);
      }
    },
    agregarCampo() {
      if (this.form.hiseId) {
        this.form.campos.push({
          hiseCodigo: null,
          hiseNombre: null,
          hiseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId,
          hiseScript: null,
          hiseTipoTipodato: null,
          hiseOrden: 0,
          hiseValores: null,
          hiseDefault: null,
          hiseDescripcion: null,
          hiseRequerido: 0,
          hiseCol: 3,
          hiseFilas: 4,
          hiseConsulta: null,
          hiseEstado: 1,
          hiseSexo: null,
          hiseEdadinicio: null,
          hiseEdadfinal: null,
          hiseTriage: 0,

        });
      } else {
        this.form.campos.push({
          hiseCodigo: null,
          hiseNombre: null,
          hiseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId,
          hiseIdDependencia: this.form.hiseId,
          hiseScript: null,
          hiseTipoTipodato: null,
          hiseOrden: 0,
          hiseValores: null,
          hiseDefault: null,
          hiseDescripcion: null,
          hiseRequerido: 0,
          hiseCol: 3,
          hiseFilas: 4,
          hiseConsulta: null,
          hiseEstado: 1,
          hiseSexo: null,
          hiseEdadinicio: null,
          hiseEdadfinal: null,
          hiseTriage: 0,
        });
      }
    },
    eliminarCampo(item) {
      this.form.campos = this.form.campos.filter(e => e != item);
    },
    agregartblConfHistoriasservicios() {
      if (this.form.hiseId) {
        this.form.tblConfHistoriasservicios.push({
          hiseId: this.form.hiseId,
          coseId: null
        });
      } else {
        this.form.tblConfHistoriasservicios.push({
          coseId: null
        });
      }
    },
    eliminartblConfHistoriasservicios(item) {
      this.form.tblConfHistoriasservicios = this.form.tblConfHistoriasservicios.filter(e => e != item);
    },
  },
}

</script>

<style>

</style>
