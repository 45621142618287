<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto" md="4">
                <v-card>
                    <v-card-title>
                        <span class="headline">Componentes</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-btn color="black" tile outlined block @click="addComponente('TEXTO')">
                                                <v-icon>mdi-format-textbox</v-icon>TEXTO
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-dialog v-model="dialogmasivo" persistent width="500">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="black" tile outlined block v-on="on">
                                                        <v-icon>mdi-image-size-select-actual</v-icon>IMAGEN
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title class="headline grey lighten-2" primary-title>
                                                        Cargar imagen
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-file-input outlined v-model="file" show-size label="Imagen" @change="onFileChange"></v-file-input>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="red" text @click="dialogmasivo = !dialogmasivo">
                                                            cerrar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-btn color="black" tile outlined block @click="addComponente('RECTANGULO')">
                                                <v-icon>mdi-image-size-select-actual</v-icon>RECTANGULO
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-btn color="black" tile outlined block @click="addComponente('LINEA')">
                                                <v-icon>mdi-vector-line</v-icon>LINEA
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-title>
                        <span class="headline">Paginas</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" v-for="pagina in  canvasList" :key="'pagina-'+pagina.pag">
                                    <v-btn color="primary darken-1" :text="activo != pagina.pag ?true:false" @click="cambiarPagina(pagina)">Pagina {{ pagina.pag }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card id="contenedor-documento">
                            <v-card-title>
                                <span class="headline">Documento</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" @click="agregarPagina()">
                                    Agregar pagina<v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <template v-if="canvas">
                                    <v-btn color="primary" @click="eliminarComponente()" v-if="canvas.getObjects().length > 0">
                                        Eliminar componente seleccionado<v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <div class="flex-grow-1"></div>
                                <v-btn color="primary darken-1" text :to="{name:'Documentos'}">Cancelar</v-btn>
                                <v-btn color="primary darken-1" dark @click="guardar()" :loading="loadingGuardar">Guardar</v-btn>
                            </v-card-actions>
                            <v-divider></v-divider>
                            <canvas style="border:1px solid;" id="canvascomponentes"></canvas>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card id="contenedor-propiedades" width="400px">
            <v-card-title id="contenedor-propiedades-header">
                <span class="headline">Propiedades</span>
            </v-card-title>
            <v-card-text id="contenedor-propiedades-body">
                <v-container>
                    <v-row v-if="datos" no-gutters>
                        <v-col cols="12" sm="12" md="12">
                            <label>Color</label>
                            <v-color-picker label="Color" class="ma-2" hide-inputs v-model="datos.propiedades.fill"></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Alto" v-model="datos.propiedades.height"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Ancho" v-model="datos.propiedades.width"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Isquierda" v-model="datos.propiedades.left"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Arriba" v-model="datos.propiedades.top"></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="12" md="12">
                            <label>Borde color</label>
                            <v-color-picker label="Borde color" class="ma-2" hide-inputs v-model="datos.propiedades.borderColor"></v-color-picker>
                        </v-col>
                         <v-col cols="12" sm="12" md="12">
                            <label>Color de fondo</label>
                            <v-color-picker label="Color de fondo" class="ma-2" hide-inputs v-model="datos.propiedades.backgroundColor"></v-color-picker>
                        </v-col>
                         <v-col cols="12" sm="12" md="12">
                            <label>Color de fondo del texo</label>
                            <v-color-picker label="Color de fondo del texo" class="ma-2" hide-inputs v-model="datos.propiedades.textBackgroundColor"></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Opacidad" v-model="datos.propiedades.opacity"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Grosor del texto" v-model="datos.propiedades.fontWeight"></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="6" md="6">
                                <v-autocomplete outlined dense v-model="datos.propiedades.textAlign" :items="['left', 'center', 'right', 'justify']" item-color="primary" label="Alineamiento" >
                                </v-autocomplete>
                        </v-col>
                         <v-col cols="12" sm="6" md="6">
                                <v-autocomplete outlined dense v-model="datos.propiedades.fontFamily" :items="['arial' ,'helvetica' ,'myriad pro' ,'delicious', 'verdana', 'georgia' ,'courier' ,'comic sans ms', 'impact', 'monaco', 'optima', 'hoefler text', 'plaster' ,'engagement']" item-color="primary" label="Familia de letra" >
                                </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Tamaño de leta" v-model="datos.propiedades.fontSize"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea outlined dense v-model="datos.propiedades.text" label="Texto"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <label>Linea de exterior</label>
                            <v-color-picker label="Borde caja color" class="ma-2" hide-inputs v-model="datos.propiedades.stroke"></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Borde ancho" v-model="datos.propiedades.strokeWidth"></v-text-field>
                        </v-col>
                       
                        <v-col cols="12" sm="6" md="6">
                            <v-switch dense v-model="datos.propiedades.isWrapping" label="Wrapping"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Angulo" v-model="datos.propiedades.angle"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Zoom X" v-model="datos.propiedades.zoomX"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="Zoom Y" v-model="datos.propiedades.zoomY"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="x1" v-model="datos.propiedades.x1"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="x2" v-model="datos.propiedades.x2"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="y1" v-model="datos.propiedades.y1"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field outlined dense label="y2" v-model="datos.propiedades.y2"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" sm="12" md="12">
                            <v-alert text outlined prominent color="info" icon="mdi-information-outline">
                                <span class="title"> Selecciona algo...</span><br>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="datos">
                <div class="flex-grow-1"></div>
                <v-btn color="primary darken-1" text @click="datos = null">Cerrar</v-btn>
                <v-btn color="primary darken-1" dark @click="aplicarCambios()">Aplicar</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  fabric
} from "fabric";
export default {
  mixins: [generales],
  data() {
    return {
      url: "sistema/documentos/",
      datos: null,
      validaciones: {
        hiseCodigo: {
          $error: false
        },
        hiseNombre: {
          $error: false
        },
      },
      file: null,
      dialogmasivo: false,
      errores: [],
      porcentaje: 0,
      total: 0,
      dialogMasivo: false,
      guardando: false,
      canvas: null,
      canvasList: [],
      activo: null,
      documento: null,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0
    };
  },
  created: async function() {

  },
  async mounted() {
    this.loadingForm = true;
    this.documento = await this.$apiService.index(`${this.url}view/${this.$route.params.codoId}`).then(data => data);
    this.canvasList = JSON.parse(this.documento.codoCanvas);
    this.canvas = new fabric.Canvas(document.querySelector("#canvascomponentes"), {
      height: 1123,
      width: 939,
      selectionColor: "#90ccb7",
      backgroundColor: 'white',
    });
    this.canvas.on({
      'selection:updated': this.seleccionar,
      'selection:created': this.seleccionar
    })
    if (this.canvasList.length > 0) {
      this.activo = 1;
      let json = this.canvasList.find(e => e.pag == 1).json;
      this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas));
    } else {
      this.activo = 1;
      this.canvasList.push({
        pag: this.canvasList.length + 1,
        json: JSON.stringify(this.canvas)
      });
    }
    this.moverPropiedades(document.getElementById("contenedor-propiedades"));
    this.loadingForm = false;
  },
  methods: {
    agregarPagina() {
      let json = JSON.stringify(this.canvas);
      this.canvasList.find(e => e.pag == this.activo).json = json;
      this.canvas.clear();
      let pag = this.canvasList.length + 1
      this.canvasList.push({
        pag: pag,
        json: JSON.stringify(this.canvas)
      });
      this.activo = pag;
    },
    cambiarPagina(canvas) {
      let json = JSON.stringify(this.canvas);
      this.canvasList.find(e => e.pag == this.activo).json = json;
      this.canvas.loadFromJSON(canvas.json, this.canvas.renderAll.bind(this.canvas));
      this.activo = canvas.pag;
    },
    onFileChange(file) {
      if (file) {
        var reader = new FileReader();
        reader.onload = (f) => {
          var data = f.target.result;
          let img = new Image();
          img.src = data;
          let oImg = new fabric.Image(img, {
            left: 100,
            top: 50,
            angle: 0,
            width: img.width,
            height: img.height
          });
          this.canvas.add(oImg);
          let json = JSON.stringify(this.canvas);
          this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas));
          this.canvas.setActiveObject(oImg);
          this.file = null;
        }
        reader.readAsDataURL(file);
      }
    },
    addComponente(tipo) {
      if (tipo == 'RECTANGULO') {
        var rect = new fabric.Rect({
          left: 100,
          top: 50,
          fill: '#00000000',
          width: 200,
          height: 100,
          objectCaching: false,
          stroke: '#0ffffff',
          strokeWidth: 4,
        });

        this.canvas.add(rect);
        this.canvas.setActiveObject(rect);
      }
      if (tipo == 'TEXTO') {
        var textbox = new fabric.Text('Escribe aqui..', {
          left: 50,
          top: 50,
          width: 150,
          fontSize: 20,
          lockUniScaling: true
        });
        this.canvas.add(textbox);
        this.canvas.setActiveObject(textbox);
      }
      if (tipo == 'LINEA') {
        var linea = new fabric.Line([-100, 250, 200, 250], {
          stroke: '#0ffffff',
          left: 50,
          top: 50,
        });
        this.canvas.add(linea);
        this.canvas.setActiveObject(linea);
      }
    },
    seleccionar(e) {
      console.log(e.target);
      this.datos = {
        objeto: e.target,
        propiedades: {
          fill: e.target.fill,
          height: e.target.height,
          left: e.target.left,
          stroke: e.target.stroke,
          strokeWidth: e.target.strokeWidth,
          top: e.target.top,
          width: e.target.width,
          fontSize: e.target.fontSize,
          isWrapping: e.target.isWrapping,
          text: e.target.text,
          zoomX: e.target.zoomX,
          zoomY: e.target.zoomY,
          angle: e.target.angle,
          x1: e.target.x1,
          x2: e.target.x2,
          y1: e.target.y1,
          y2: e.target.y2,
          borderColor: e.target.borderColor,
          textBackgroundColor: e.target.textBackgroundColor,
          backgroundColor: e.target.backgroundColor,
          textAlign: e.target.textAlign,
          opacity: e.target.opacity,
          fontWeight: e.target.fontWeight,
          fontFamily: e.target.fontFamily
        }

      };

    },
    moverPropiedades(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "-header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "-header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
    eliminarComponente() {
      var obj = this.canvas.getActiveObject();
      this.canvas.remove(obj);
    },
    async guardar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let json = JSON.stringify(this.canvas);
        this.canvasList.find(e => e.pag == this.activo).json = json;
        this.documento.codoCanvas = JSON.stringify(this.canvasList);
        let data = await this.$apiService.update(`${this.url}update/${this.documento.codoId}`, this.documento).then((data) => data);
        if (data) {
          this.$swal.fire({
            title: "Completado!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Ok"
          });
        }

      } catch (error) {
        if (error.response && 422 == error.response.status) {
          console.log(error.response.data.errors);
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            console.log(error);
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.loadingGuardar = false;
    },
    aplicarCambios() {

      if (this.datos.propiedades.fill)
        this.datos.objeto.set("fill", this.datos.propiedades.fill);
      if (this.datos.propiedades.height)
        this.datos.objeto.set("height", this.datos.propiedades.height);
      if (this.datos.propiedades.left)
        this.datos.objeto.set("left", parseInt(this.datos.propiedades.left));
      if (this.datos.propiedades.stroke)
        this.datos.objeto.set("stroke", this.datos.propiedades.stroke);
      if (this.datos.propiedades.strokeWidth)
        this.datos.objeto.set("strokeWidth", this.datos.propiedades.strokeWidth);
      if (this.datos.propiedades.top)
        this.datos.objeto.set("top", parseInt(this.datos.propiedades.top));
      if (this.datos.propiedades.width)
        this.datos.objeto.set("width", this.datos.propiedades.width);
      if (this.datos.propiedades.fontSize)
        this.datos.objeto.set("fontSize", this.datos.propiedades.fontSize);
      if (this.datos.propiedades.isWrapping)
        this.datos.objeto.set("isWrapping", this.datos.propiedades.isWrapping);
      if (this.datos.propiedades.text)
        this.datos.objeto.set("text", this.datos.propiedades.text);
      if (this.datos.propiedades.zoomX)
        this.datos.objeto.set("zoomX", this.datos.propiedades.zoomX);
      if (this.datos.propiedades.zoomY)
        this.datos.objeto.set("zoomY", this.datos.propiedades.zoomY);
      if (this.datos.propiedades.angle)
        this.datos.objeto.set("angle", this.datos.propiedades.angle);
      if (this.datos.propiedades.x1)
        this.datos.objeto.set("x1", this.datos.propiedades.x1);
      if (this.datos.propiedades.x2)
        this.datos.objeto.set("x2", this.datos.propiedades.x2);
      if (this.datos.propiedades.y1)
        this.datos.objeto.set("y1", this.datos.propiedades.y1);
      if (this.datos.propiedades.y2)
        this.datos.objeto.set("y2", this.datos.propiedades.y2);
      if (this.datos.propiedades.borderColor)
        this.datos.objeto.set("borderColor", this.datos.propiedades.borderColor);
      if (this.datos.propiedades.textBackgroundColor)
        this.datos.objeto.set("textBackgroundColor", this.datos.propiedades.textBackgroundColor);
      if (this.datos.propiedades.backgroundColor)
        this.datos.objeto.set("backgroundColor", this.datos.propiedades.backgroundColor);
      if (this.datos.propiedades.textAlign)
        this.datos.objeto.set("textAlign", this.datos.propiedades.textAlign);
      if (this.datos.propiedades.opacity)
        this.datos.objeto.set("opacity", this.datos.propiedades.opacity);
      if (this.datos.propiedades.fontWeight)
        this.datos.objeto.set("fontWeight", this.datos.propiedades.fontWeight);
      if (this.datos.propiedades.fontFamily)
        this.datos.objeto.set("fontFamily", this.datos.propiedades.fontFamily);
   this.canvas.renderAll();
    }
  },
}

</script>

<style>
#contenedor-propiedades {
  left: 50px;
  top: 400px;
  position: absolute;
  z-index: 9;
}

#contenedor-propiedades-header {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #107554;
  color: #fff;
}

.campo {
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
}

select.campo {
  -moz-appearance: listbox;
  -webkit-appearance: listbox;
}

.bordes {
  background-color: rgb(255, 255, 255) !important;
}

.bordesCampos {
  border: solid 1px rgb(206, 206, 206);
}

.cursor {
  cursor: pointer;
}

#contenedor-propiedades-body::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#contenedor-propiedades-body::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#contenedor-propiedades-body::-webkit-scrollbar-button:increment,
#contenedor-propiedades-body::-webkit-scrollbar-button {
  display: none !important;
}

#contenedor-propiedades-body::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#contenedor-propiedades-body::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#contenedor-propiedades-body::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#contenedor-propiedades-body::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#contenedor-propiedades-body {
  max-height: 700px;
  overflow: auto;
  scrollbar-color: rgb(76, 113, 159) rgb(199, 220, 242);
  scrollbar-width: thin;
}

</style>
