<style>

</style>

<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-data-table :headers="headers" :items="lista" dense sort-desc sort-by="copeId" class="elevation-1">
                <template v-slot:top>

                    <v-toolbar>
                        <v-toolbar-title class="primary--text">Representantes</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn class="mx-2" tile color="primary" :to="{ name: 'Representantecreate' }">
                            agregar
                        </v-btn>

                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                </template>
                <template v-slot:[`item.copeTipoIdentificacion`]="{ item }">
                    {{ getMaestra(item.copeTipoIdentificacion,tipoidentificacion)}}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon fab color="info" class="mr-2" v-on="on" x-small :to="{ name: 'Representanteupdate',params: { id: item.copeId} }">
                                <v-icon>create</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small icon color="error" v-on="on" @click="eliminar(item.copeId)">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <label class="primary--text">No hay datos disponibles</label>
                </template>
            </v-data-table>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
export default {
    components: {
        
    },
    data() {
        return {
            dialog: false,
            url: "sistema/personas/",
            token: this.$cookies.get("token"),

            filtros: [{
                    text: "Nombre",
                    value: null,
                    tipo: "text",
                },

                {
                    text: "Identificacion",
                    value: null,
                    tipo: "text",
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
                /*  {
                      text: "Tipo de identificacion",
                      value: null,
                      tipo: "lista",
                      lista:[],
                      listatext: "comaNombrelargo",
                      listavalue: "comaId"
                  },

                  {
                      text: "Direccion",
                      value: null,
                      tipo: "text",
                      /*si es tipo lista descomentar esta parte*F/
                      /* lista: [],
                      listatext: "nombre del campo que se mostrara",
                      listavalue: "nombre del valor que se mandara"*F/
                  },*/
            ],
            headers: [

                {
                    text: "Primer nombre",
                    value: "tblConfPersonasnaturales[0].copnNombre1"
                }, {
                    text: "Segundo nombre",
                    value: "tblConfPersonasnaturales[0].copnNombre2"
                }, {
                    text: "Primer apellido",
                    value: "tblConfPersonasnaturales[0].copnApellido1"
                }, {
                    text: "Segundo apellido",
                    value: "tblConfPersonasnaturales[0].copnApellido2"
                }, {
                    text: "Identificacion",
                    value: "copeIdentificacion"
                },

                {
                    text: "Tipo Identificacion",
                    value: "copeTipoIdentificacion"
                },
                {
                    text: "Telefono",
                    value: "copeTelefono"
                },
                {
                    text: "Email",
                    value: "copeEmail"
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false
                }
            ],
            lista: [],
            listaCopia: [],
            tipoidentificacion: []

        };
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        this.$store.commit('setCargaDatos', true);
        this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/representantes").then(data => data);
        this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);
        this.$store.commit('setCargaDatos', false);
    },

    methods: {
        getMaestra(id, maestra) {
            if (id != null) {
                if (maestra.length > 0) {
                    var ma = maestra.filter(e => e.comaId == id);
                    if (ma.length > 0) {
                        var m = ma[0];
                        return m.comaNombrecorto;
                    }
                }
            }

        },
        buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                this.lista = this.lista.filter(
                    e => (e.tblConfPersonasnaturales[0].copnNombre1 + " " + e.tblConfPersonasnaturales[0].copnNombre2 + " " + e.tblConfPersonasnaturales[0].copnApellido1 + " " + e.tblConfPersonasnaturales[0].copnApellido2).indexOf(this.filtros[0].value) != -1
                );
            }
            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                this.lista = this.lista.filter(
                    e => e.copeIdentificacion.indexOf(this.filtros[1].value) != -1
                );
            }
        },

        async eliminar(id) {
            try {
              
                var result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    let data = await this.$apiService.delete(this.url + "delete/" + id).then(data => data);
                    if (data) {
                        this.listaCopia = this.lista = this.lista.filter(
                            e => e.copeId != id
                        );
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

    }
};
</script>
