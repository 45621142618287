<style>
</style>
<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <span class="mx-2 secondary--text display-2 float-left">Base De Datos</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                    <v-icon dark>filter_list</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="tabla">
                <v-card elevation="0" class="rounded-xl">
                    <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
                        <template v-slot:header>
                            <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                            <v-toolbar dark class="mb-1">
                                <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                    agregar
                                </v-btn>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <div class="flex-grow-1"></div>
                            </v-toolbar>
                        </template>
                        <template v-slot:default="{ items, isExpanded, expand }">
                            <v-container class="pt-0">
                                <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                    <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                        <v-card elevation="0" class="rounded-xl">
                                            <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                <v-row no-gutters>
                                                    <v-col cols="10" sm="10" md="10" lg="10">
                                                        <v-row no-gutters>
                                                            <template v-for="(p,i) in tabla.primarios">
                                                                <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                    {{p.titulo}} <span :class="p.class">
                                                                        {{ p.valor(item) }}
                                                                    </span>
                                                                </v-col>
                                                            </template>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" md="2" lg="2">
                                                        <v-menu bottom left>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <template v-for="(m, i) in tabla.menu">
                                                                    <template v-if="m.show(item)">
                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item v-else :key="i" @click="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                    </template>
                                                                </template>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row no-gutters>
                                                    <template v-for="(p,i) in tabla.segundarios">
                                                        <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                            {{p.titulo}} <span :class="p.class">
                                                                {{ p.valor(item) }}
                                                            </span>
                                                        </v-col>
                                                    </template>
                                                    <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                        <v-row>
                                                            <v-col cols="2" sm="2" md="1" lg="1">
                                                                <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                    <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="10" sm="10" md="11" lg="11">
                                                                <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <template v-if="tabla.expandible">
                                                <v-divider v-if="isExpanded(item)"></v-divider>
                                                <v-card-text v-if="isExpanded(item)">
                                                    <v-container>
                                                        <template v-for="items in tabla.expandibleItems(item) ">
                                                            <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                                    <template v-for="(p,i) in tabla.expanItem">
                                                                        <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                            {{p.titulo}} <span :class="p.class">
                                                                                {{ p.valor(items) }}
                                                                            </span>
                                                                        </v-col>
                                                                    </template>
                                                                    <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                        <v-menu bottom left>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <template v-for="(m, i) in tabla.expanMenu">
                                                                                    <template v-if="m.show(items)">
                                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                        <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                    </template>
                                                                                </template>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-sheet>
                                                        </template>
                                                    </v-container>
                                                </v-card-text>
                                            </template>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:no-data>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="10" sm="10" md="10" lg="10">
                                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                        No se encontro nada.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:loading>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:footer>
                            <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                        </template>
                    </v-data-iterator>
                </v-card>
            </v-col>
        </v-row>
        <v-form @submit.prevent="agregarPaciente">
            <v-dialog v-model="dialogPaciente" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Agregar paciente a base de datos</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined :error-messages="errores['coppId']" v-model="formPaciente.coppId" :items="pacientes.pacientes" :loading="pacientes.isLoading" :search-input.sync="pacientes.search" @keypress.enter="searchPaciente" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                        <template v-slot:selection="data">
                                            {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.copeIdentificacion">
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined :error-messages="errores['cbdpTipoEstado']" v-model="formPaciente.cbdpTipoEstado" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" dark @click="agregarPaciente">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
        <v-form enctype="multipart/form-data">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="loadingForm" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                            <v-row v-else-if="!loadingGuardar">
                                <v-col cols="12" sm="12" md="4">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field outlined v-model="form.cobdFecha" required label="Fecha" prepend-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.cobdFecha" :error-messages="errores['cobdFecha']" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined v-model="form.cobdTipoMes" :error-messages="errores['cobdTipoMes']" :items="tipomes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Mes" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined v-model="form.cobdTipoAnio" :error-messages="errores['cobdTipoAnio']" :items="tipoanio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Año" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="7" v-if="contratos.length > 0">
                                    <v-autocomplete outlined :error-messages="errores['cocoId']" v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined v-model="form.cobdTipoRegimen" :error-messages="errores['cobdTipoRegimen']" :items="tiporegimen" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Regimen" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined v-model="form.cobdTipoEstado" :error-messages="errores['cobdTipoEstado']" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-file-input outlined v-model="file" show-size label="Base de datos" @change="onFileChange"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" sm="12" md="12" class="text-center">
                                    <span class="headline">CARGANDO BASE DE DATOS....</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" class="text-center">
                                    <v-progress-linear v-model="porcentaje" color="primary" height="25">
                                        <template v-slot:default="{ value }">
                                            <strong>{{ Math.ceil(value) }}% de {{total}} pacientes..</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary" text target="_blank" :href="plantilla">
                            Descargar formato
                        </v-btn>
                        <v-btn color="primary darken-1" text v-if="!loadingGuardar" @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark v-if="!loadingGuardar" @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
            <v-dialog v-model="dialogTraslate" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="loadingForm" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                            <v-row v-else-if="!loadingGuardar">
                              <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined v-model="form.cobdId" :items="basededatos"  hide-selected item-text="cobdNombre" item-value="cobdId" item-color="primary" label="Base de datos destino" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfContratos[0].cocoNombre + " "+data.item.anio.comaNombrelargo + "-"+data.item.mes.comaNombrelargo + "-"+data.item.regimen.comaNombrelargo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfContratos[0].cocoNombre + ' '+data.item.anio.comaNombrelargo + '-'+data.item.mes.comaNombrelargo + '-'+data.item.regimen.comaNombrelargo"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-file-input outlined v-model="file" show-size label="Pacientes" @change="onFileChange"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" sm="12" md="12" class="text-center">
                                    <span class="headline">TRASLADANDO PACIENTES....</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text v-if="!loadingGuardar" @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark v-if="!loadingGuardar" @click="trasladar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import plantilla from '@/assets/files/plantilla_paciente.xlsx';
import generales from '../../../mixins/generales.js';
export default {
  mixins: [generales],
  data() {
    return {
      plantilla: null,
      url: "sistema/basededatos/",
      dialogPaciente: false,
      dialogTraslate:false,
      formPaciente: {
        cbdpTipoEstado: null,
        coppId: null,
        cobdId: null,
      },
      form: {
        cobdId: null,
        cobdFecha: new Date().yyyymmdd(),
        cobdTipoMes: null,
        cobdTipoAnio: null,
        cobdTipoEstado: null,
        cobdTipoRegimen: null,
        cocoId: null,
        basededatos: null,
      },
      filtros: [{
        campo: "fecha",
        text: "Fecha",
        value: null,
        tipo: "fecha",
        menu: false
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
      }, {
        campo: "mes",
        text: "Mes",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
      }, {
        campo: "anio",
        text: "Año",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
      }, {
        campo: "estado",
        text: "Estado",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
      }, {
        campo: "regimen",
        text: "Regimen",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
      }, {
        campo: "contrato",
        text: "Contrato",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "cocoNombre",
        listavalue: "cocoId",
        default: true,
        defaultselect: (data) => {
          return data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo
        },
        defaultbody: (data) => {
          return {
            title: data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo,
            subtitle: data.item.cocoNombre
          }
        },

      }, ],
      tabla: {
        itemKey: 'cobdId',
        orderBy: 'cobdFecha',
        orderDesc: true,
        expandible: false,
        expandibleKey: 'cobdId',
        primarios: [{
            titulo: 'FECHA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.cobdFecha
            },
            show: (item) => {
              return item ? true : false
            }
          }, {
            titulo: 'AÑO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.anio.comaNombrecorto
            },
            show: (item) => {
              return item ? true : false
            }
          },

          {
            titulo: 'MES',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.mes.comaNombrelargo
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'REGIMEN',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.regimen.comaNombrecorto
            },
            show: (item) => {
              return item ? true : false
            }
          },

        ],
        segundarios: [{
            titulo: 'ESTADO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return (item.estado) ? item.estado.comaNombrecorto : ''
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'CONTRATO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return (item.tblConfContratos.length > 0) ? item.tblConfContratos[0].cocoNombre : ''
            },
            show: (item) => {
              return item ? true : false
            }
          }
        ],
        expanItem: [],
        expanMenu: [],
        menu: [{
            title: 'Descargar',
            icon: 'mdi-arrow-collapse-down',
            color: 'purple',
            tipeAction: 'funcion',
            loader: this.LoadingExp,
            action: (item) => {
              return this.exporttabla(item);
            },
            show: (item) => {
              return item ? this.getPermiso('crearBasedatos') : false
            }
          }, {
            title: 'Agregar Paciente',
            icon: 'mdi-account-supervisor',
            color: 'success',
            tipeAction: 'funcion',
            action: (item) => {
              this.dialogPaciente = true;
              return this.cargarbd(item.cobdId)
            },
            show: (item) => {
              return item ? this.getPermiso('crearBasedatospacientes') : false
            }
          }, {
            title: 'Ver Base De Datos',
            icon: 'mdi-desktop-mac-dashboard',
            color: 'success',
            tipeAction: 'link',
            action: (item) => {
              return {
                name: 'Bdpanel',
                params: {
                  id: item.cobdId
                }
              }
            },
            show: (item) => {
              return item ? this.getPermiso('verBasedatos') : false
            }
          },
          {
            title: 'Trasladar pacientes',
            icon: 'mdi-transit-transfer',
            color: 'info',
            tipeAction: 'funcion',
            action: (item) => {
              this.dialogTraslate = true;
              this.form.oldcobdId = item.cobdId;
              return ()=>{}
            },
            show: (item) => {
              return item ? this.getPermiso('editarBasedatos') : false
            }
          }, {
            title: 'Editar',
            icon: 'create',
            color: 'info',
            tipeAction: 'funcion',
            action: (item) => {
              this.dialog = true;
              return this.editar(item.cobdId)
            },
            show: (item) => {
              return item ? this.getPermiso('editarBasedatos') : false
            }
          },
          {
            title: 'Eliminar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => {
              this.eliminar(item.cobdId)
            },
            show: (item) => {
              return item ? this.getPermiso('eliminarBasedatos') : false
            }
          },
        ]
      },
      menu: false,
      tipomes: [],
      tipoanio: [],
      tiporegimen: [],
      tipoestado: [],
      contratos: [],
      pacientes: {
        search: null,
        isLoading: false,
        pacientes: []
      },
      porcentaje: 0,
      total: 0,
      interval: {},
      basededatos:[]
    };
  },
  created: async function() {
    this.plantilla = plantilla;
    let {
      contratos,
      tipomes,
      tipoanio,
      tiporegimen,
      tipoestado
    } = await this.$apiService.index("sistema/basededatos/generales").then(data => data);
    this.filtros[5].lista = this.contratos = contratos;
    this.filtros[1].lista = this.tipomes = tipomes;
    this.filtros[2].lista = this.tipoanio = tipoanio;
    this.filtros[4].lista = this.tiporegimen = tiporegimen;
    this.filtros[3].lista = this.tipoestado = tipoestado;
    this.contratos = this.contratos.filter(e =>e.estado.comaNombrecorto == 'ACTIVO' && new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
    this.basededatos = await this.$apiService.index(`sistema/basededatos/index`).then(data => data);
    await this.search(this.filtros);
  },
  sockets: {
    sendporcentaje: function(data) {
      this.total = data.cobdTotal;
      this.porcentaje = data.cobdTotal > 0 ? ((data.cobdPorcentaje / data.cobdTotal) * 100) : 0;
    },
  },
  methods: {
    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      const data = await this.$apiService.index(`sistema/basededatos/buscar?${this.filtrar(filtros,this.page)}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
    onFileChange(file) {
      this.file = file;
    },
    async searchPaciente() {
      this.pacientes.isLoading = true;
      if (this.pacientes.search.length > 0 && this.pacientes.search.toString().replace(/ /g, "") != "") {
        this.pacientes.pacientes = await this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.pacientes.search.toUpperCase()).then(data => data);
      }
      this.pacientes.isLoading = false;
    },
    async agregar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
          await this.$apiService.update(`${this.url}update/${this.form.cobdId}`, this.form).then((data) => data);
        if (data) {
          if (this.file != null) {
            let formData = new FormData();
            formData.append('file', this.file);
            this.loader = true;
            var guardo = await this.$apiService.createFile(this.url + "create/bd/" + data.cobdId + "/" + this.user.username, formData).then(data => data);
            if (guardo) {
              this.loader = false;
              this.file = null;
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });

          }
          this.close();
          await this.search(this.filtros);
        }
      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            if (error.response && error.response.data.errores) {
              this.$swal.fire({
                title: "Error en el excel datos invalidos",
                text: error.response.data.errores,
                icon: "error"
              });
            } else {
              console.log(error)
              this.$swal.fire({
                title: "Algo sucedio!",
                text: "contacta con el administrador",
                icon: "error"
              });
            }

          }

        }
      }
      this.loadingGuardar = false;
    },
    async trasladar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('cobdId', this.form.cobdId);
        this.loader = true;
        let data = await this.$apiService.updateFile(`${this.url}traslado/${this.form.oldcobdId}`, formData).then((data) => data);
        if (data) {
              this.loader = false;
              this.file = null;
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
          this.close();
          await this.search(this.filtros);
        }
      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            if (error.response && error.response.data.errores) {
              this.$swal.fire({
                title: "Error en el excel datos invalidos",
                text: error.response.data.errores,
                icon: "error"
              });
            } else {
              console.log(error)
              this.$swal.fire({
                title: "Algo sucedio!",
                text: "contacta con el administrador",
                icon: "error"
              });
            }

          }

        }
      }
      this.loadingGuardar = false;
    },
    async agregarPaciente() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let data = await this.$apiService.create("cita/basedatospacientes/create", this.formPaciente).then(data => data);
        if (data) {
          this.$swal.fire({
            title: "Completado!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Ok"
          });
          await this.search(this.filtros);
        }
        this.close();
      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.loadingGuardar = false;
    },
    async eliminar(id) {
      try {
        if (this.getPermiso("eliminarBasedatos")) {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
          }
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async exporttabla(item) {
      this.LoadingExp = true;
      let bd = await this.$apiService.index(`cita/basedatospacientes/indexcompleto/${item.cobdId}`).then(data => data);
      import('@/vendor/Export2Excel').then(excel => {
        let data = [{sheet:"Page1",data:[]}];
        let head = [{sheet:"Page1",data:['NUM', 'CODEPS', 'TIPOID', 'NUMID', 'APELLIDO_1', 'APELLIDO_2', 'NOMBRE_1', 'NOMBRE_2', 'FECNAC', 'SEXO', 'CODDEPMUN', 'ZONA', 'GRUPO_POBLACIONAL', 'MODALIDAD', 'DIRECCION', 'TELEFONO', 'ESTADO', 'FECHA_AFILIACION']}];
        let cont = 1;
        for (let item of bd) {

          data[0].data.push([
            cont,
            'EPSI01',
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido1,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido2,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre1,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre2,
            item.tblConfPersonaspaciente.coppFechanacimiento,
            item.tblConfPersonaspaciente.sexo.comaNombrecorto,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.departamento.comaNombrecorto + item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.municipio.comaNombrecorto,
            (item.tblConfPersonaspaciente.zona != null) ? item.tblConfPersonaspaciente.zona.comaNombrecorto : '',
            (item.tblConfPersonaspaciente.poblacion != null) ? item.tblConfPersonaspaciente.poblacion.comaValor : '1',
            'ST',
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeDireccion,
            item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeTelefono,
            (item.estado != null) ? item.estado.comaNombrecorto : '',
            item.cbdpFechaAfiliacion
          ]);
          cont++;
        }
        excel.export_json_to_excel({
          header: head, //Header Required
          data, //Specific data Required
          filename: item.tblConfContratos[0].cocoNombre + "-" + item.anio.comaNombrecorto + "-" + item.mes.comaNombrelargo, //Optional
          autoWidth: true, //Optional
          bookType: 'xlsx' //Optional
        })
      });
      this.LoadingExp = false;
    },
    cargarbd(id) {
      this.formPaciente.cobdId = id;
    },
    async editar(id) {
      try {
        this.loadingForm = true;
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
        this.loadingForm = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },

    limpiar() {
      this.form = {
        cobdId: null,
        cobdFecha: new Date().yyyymmdd(),
        cobdTipoMes: null,
        cobdTipoAnio: null,
        cobdTipoEstado: null,
        cobdTipoRegimen: null,
        coemId: null,
        basededatos: null,
      };
      this.formPaciente = {
        cbdpTipoEstado: null,
        coppId: null,
        cobdId: null,
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.dialogPaciente = false;
      this.dialogTraslate = false;
      this.limpiar();
    }
  }
};

</script>
