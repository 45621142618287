<style>

</style>

<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" item-key="foseId" single-expand hide-default-footer sort-desc sort-by="foseCodigo" class="elevation-1">
                    <template v-slot:header>
                        <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                        <v-toolbar dark color="primary darken-3" class="mb-1">
                            <v-toolbar-title class="white--text">Formularios</v-toolbar-title>
                            <v-btn class="mx-2 primary--text" tile color="white" :to="{name:'Formcreate'}">
                                agregar
                            </v-btn>
                            <v-form @submit.prevent="subida" enctype="multipart/form-data">
                                <v-dialog v-model="dialogmasivo" persistent width="500">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="red lighten-2" dark v-on="on">
                                            Carga masiva
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="headline grey lighten-2" primary-title>
                                            Cargar excel
                                        </v-card-title>

                                        <v-card-text>
                                            <v-row v-if="!loader">
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-file-input outlined :rules="nameRules" v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <span class="headline">CARGANDO FORMULARIO....</span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-btn color="primary" text target="_blank" :href="plantilla">
                                                Descargar formato
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                                cancelar
                                            </v-btn>

                                            <v-btn color="primary" v-if="!loader" text @click="subida">
                                                cargar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-form>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab color="white" class="primary--text" :loading="LoadingExp" small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="exportar">
                                        <v-list-item-title>Exportar</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>

                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-row align="center" justify="center">
                            <v-col v-for="item in items" :key="item.foseId" cols="12" sm="12" md="10" lg="10">
                                <v-card>
                                    <v-card-title class="subtitle-1 font-weight-bold">
                                        <v-row no-gutters>
                                            <v-col cols="9" sm="9" md="9" lg="9">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        CODIGO <span class="primary--text">
                                                            {{ item.foseCodigo }}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" lg="12">
                                                        NOMBRE <span class="primary--text">
                                                            {{ item.foseNombre}}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        ORDEN <span class="primary--text">
                                                            {{ item.foseOrden }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="3" sm="3" md="3" lg="3">
                                                <v-tooltip top>
                                                    <!-- @click.stop="dialog = true" @click="editar(item.foseId)" -->
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true;form.foseIdDependencia =item.foseId;form.foseIdTipo =tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Agregar campo</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <!-- @click.stop="dialog = true" @click="editar(item.foseId)" -->

                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined color="info" class="mr-2" v-on="on" small :to="{name:'Formupdate',params:{id:item.foseId}}">
                                                            <v-icon>create</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined small color="error" v-on="on" @click="eliminar(item.foseId)">
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                HISTORICO <label class="green--text" dark v-if="item.foseHistorico">Si</label>
                                                <label class="red--text" dark v-else>No</label>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                CALCULADO <label class="green--text" dark v-if="item.foseCalculado">Si</label>
                                                <label class="red--text" dark v-else>No</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-row>
                                                    <v-col cols="1" sm="1" md="1" lg="1">
                                                        <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                            <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="11" sm="11" md="11" lg="11">
                                                        <span class="headline primary--text">CAMPOS {{item.campos.length}}</span>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="isExpanded(item)">
                                        <v-container>
                                            <template v-for="items in item.campos ">
                                                <v-sheet min-height="100%" width="100%" :key="items.cireId">
                                                    <v-row no-gutters class="grey lighten-2 mb-5 py-2 px-2">
                                                        <v-col cols="12" sm="4" md="4" lg="4">
                                                            CODIGO <span class="primary--text">
                                                                {{ items.foseCodigo }}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="8" md="8" lg="8">
                                                            NOMBRE <span class="primary--text">
                                                                {{ items.foseNombre}}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            ORDEN <span class="primary--text">
                                                                {{ items.foseOrden }}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            REQUERIDO <label class="green--text" dark v-if="items.foseRequerido">Si</label>
                                                            <label class="red--text" dark v-else>No</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3" lg="3">
                                                            TIPO <label class="primary--text" dark>{{items.tipodato.comaNombrecorto}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3" lg="3">
                                                            <v-tooltip top>
                                                                <!-- @click.stop="dialog = true" @click="editar(item.foseId)" -->
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(items.foseId)">
                                                                        <v-icon>create</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Editar</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn outlined small color="error" v-on="on" @click="eliminar(items.foseId)">
                                                                        <v-icon>delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            ESTADO <label class="green--text" dark v-if="items.foseEstado">Si</label>
                                                            <label class="red--text" dark v-else>No</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            SEXO <label class="primary--text" dark>{{items.foseSexo}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            EDAD INICIAL <label class="primary--text" dark>{{items.foseEdadinicio}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            EDAD FINAL <label class="primary--text" dark>{{items.foseEdadfinal}}</label>
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </template>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template> <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <v-row class="mt-2 pb-5" align="center" justify="center">
                            <span class="mr-4 grey--text">
                                Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                            </span>
                            <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Codigo" :error="$v.form.foseCodigo.$error" v-model="form.foseCodigo" v-on:keyup="$data.form.foseCodigo = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Nombre" :error="$v.form.foseNombre.$error" v-model="form.foseNombre" v-on:keyup="$data.form.foseNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <!-- <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined :rules="nameRules" :error="$v.form.foseIdTipo.$error" v-model="form.foseIdTipo" :items="tiposecciones" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo" clearable>
                                    </v-autocomplete>
                                </v-col> -->
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined :rules="nameRules" label="Dependencia" v-model="form.foseIdDependencia" :items="depe" item-text="foseNombre" item-value="foseId" item-color="primary" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-autocomplete outlined :rules="nameRules" v-model="form.foseTipoTipodato" :items="tipocampos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo dato" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Orden" :error="$v.form.foseOrden.$error" v-model="form.foseOrden" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-text-field outlined :rules="nameRules" label="Valores" v-on:keyup="$data.form.foseValores = $event.target.value.toUpperCase()" v-model="form.foseValores"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-text-field outlined :rules="nameRules" label="Numero de columnas(1 - 12)" :error="$v.form.foseCol.$error" v-model="form.foseCol" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined v-on:keyup="$data.form.foseDescripcion = $event.target.value.toUpperCase()" v-model="form.foseDescripcion" label="Descripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-textarea outlined v-on:keyup="$data.form.foseDefault = $event.target.value.toUpperCase()" v-model="form.foseDefault" label="Valor por defecto"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined v-model="form.foseScript" label="Script"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='SECCION'">
                                    <v-switch v-model="form.foseHistorico" label="Historico"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='SECCION'">
                                    <v-switch v-model="form.foseCalculado" label="Calculado"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-switch v-model="form.foseRequerido" label="Requerido"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Sexo" v-model="form.foseSexo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Edad inicial" v-model="form.foseEdadinicio"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Edad final" v-model="form.foseEdadfinal"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.foseEstado" label="Estado"></v-switch>
                                </v-col>
                                
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-textarea outlined v-model="form.foseConsulta" label="Consulta"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='SECCION'">
                                    <v-row justify="space-around">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-toolbar>
                                                <v-toolbar-title>Cups</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn text class="" @click="agregartblConfFormulariosservicios">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.foseIdTipo,tiposecciones)=='SECCION'">
                                    <v-row justify="space-around" v-for="items in form.tblConfFormulariosservicios" :key="items.cocuId">
                                        <v-col cols="12" sm="12" md="8">
                                            <v-autocomplete outlined required v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2">
                                            <v-btn text icon color="error" @click="eliminartblConfFormulariosservicios(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import plantilla from '@/assets/files/plantilla_historia.xlsx';
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  components: {

  },
  data() {
    return {
      plantilla: null,
      dialogmasivo: false,
      dialog: false,
      loader: false,
      LoadingExp: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      isNew: true,
      file: null,
      url: "sistema/formulariosecciones/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],
      secciones: [],
      servicios: [],
      form: {
        foseCodigo: null,
        foseNombre: null,
        foseIdTipo: null,
        foseIdDependencia: null,
        foseScript: null,
        foseTipoTipodato: null,
        foseOrden: 0,
        foseHistorico: 0,
        foseCalculado: 0,
        foseValores: null,
        foseDefault: null,
        foseDescripcion: null,
        foseRequerido: 0,
        foseCol: 3,
        foseConsulta: null,
        tblConfFormulariosservicios: [],
        foseEstado: 1,
        foseSexo: null,
        foseEdadinicio: null,
        foseEdadfinal: null,
        foseMultiple: 0,
      },
      filtros: [{
        text: "Codigo",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Nombre",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Cups",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "cocuNombre",
        listavalue: "cocuId",
        buscar: 'cup',
        isLoading: false,
        search: null,
        //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
        default: true,
        defaultselect: (data) => {
          return data.item.cocuNombre
        },
        defaultbody: (data) => {
          return {
            title: data.item.cocuNombre,
            subtitle: data.item.cocuCups
          }
        },
      }, ],

      lista: [],
      listaCopia: [],
      tiposecciones: [],
      tipocampos: [],
      depe: []
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  validations: {
    form: {
      foseCodigo: {
        required,
      },
      foseNombre: {
        required,
      },

      foseIdTipo: {
        required,
      },

      foseOrden: {
        required,
      },
      foseCol: {
        required,
      },

    },
  },
  created: async function() {
    this.plantilla = plantilla;
    this.$store.commit('setCargaDatos', true);

    this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
    this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    this.depe = await this.$apiService.index(`${this.url}indexsecciones`).then(data => data);
    await this.buscar(this.filtros);
    this.$store.commit('setCargaDatos', false);
  },

  methods: {
    onFileChange(file) {
      this.file = file;
    },
    getMaestraName(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaNombrecorto == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaId;
          }
        }
      }

    },
    getMaestra(id, maestra) {
      if (id != null) {
        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrecorto;
          }
        }
      }

    },
    async searchCup(cups) {
      cups.isLoading = true;
      if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
          cups.isLoading = false;
          cups.cups = data;

        });
      }

    },
    async search(searchP) {
      this.$store.commit('setCargaDatos', true);
      const data = await this.$apiService.index(`${this.url}index?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit('setCargaDatos', false);

    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `foseCodigo=${this.filtros[0].value}&`;
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `foseNombre=${this.filtros[1].value}&`;

      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `cocuId=${this.filtros[2].value}&`;

      }
      search = search + `foseIdTipo=${ this.tiposecciones.find(e => e.comaNombrecorto == 'SECCION').comaId}&`;

      if (search != "") {
        await this.search(search);
      }

    },
    async subida() {
      try {
        if (this.file != null) {
          let formData = new FormData();
          formData.append('file', this.file);
          this.loader = true;
          let data = await this.$apiService.createFile(this.url + "masiva", formData).then(data => data);
          if (data) {
            this.loader = false;
            this.file = null;
            this.$store.commit('setCargaDatos', true);
            await this.buscar(this.filtros);
            this.$store.commit('setCargaDatos', false);
            this.$swal.fire({
              title: "Completado!",
              text: "Subida exitosa",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }

        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Cuidado!",
            text: "Selecciona un archivo primero",
            type: "warnning",
            confirmButtonText: "Ok"
          });
        }
      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;

    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.foseId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.close();
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.buscar(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {

        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (data) {
          this.form = data;
          if (!this.form.tblConfFormulariosservicios) {
            this.form.tblConfFormulariosservicios = [];
          } else {
            for (let model of this.form.tblConfFormulariosservicios) {
              model.cups = {
                search: null,
                isLoading: false,
                cups: [model.tblConfCup]
              };
            }

          }
        }
        this.isNew = false;

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async exportar() {
      this.LoadingExp = true;
      try {
        if (
          this.filtros[2].value != null &&
          this.filtros[2].value.toString().replace(/ /g, "") != ""
        ) {
          let secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + this.filtros[2].value).then(data => data);

          for (let item of secciones) {
            let servicios = await this.$apiService.index("sistema/formulariosecciones/servicios/" + item.foseId).then(data => data);
            item.servicios = servicios;
          }
          import('@/vendor/Export2Excel').then(excel => {
            let data = [{sheet:"Page1",data:[]}];
          let head = [{sheet:"Page1",data:['ID', 'CODIGO', 'NOMBRE', 'TIPO', 'HISTORICO', 'DEPENDENCIA', 'TIPO_DATO', 'ORDEN', 'VALORES', 'COLUMNAS', 'CUPS', 'CALCULADO', 'DESCRIPCION', 'REQUERIDO', 'DEFAULT', 'SCRIPT', 'CONSULTA', 'ESTADO', 'SEXO', 'EDADINICIO', 'EDADFINAL', 'MULTIPLE']}];
            for (let item of secciones) {

              data[0].data.push([
                item.foseId,
                item.foseCodigo,
                item.foseNombre,
                'SECCION',
                item.foseHistorico,
                item.foseIdDependencia,
                (item.tipodato) ? item.tipodato.comaNombrecorto : '',
                item.foseOrden,
                item.foseValores,
                item.foseCol,
                (item.servicios) ? item.servicios.map((e) => {
                  return e.tblConfCup.cocuCups
                }).join(';') : '',
                item.foseCalculado,
                item.foseDescripcion,
                item.foseRequerido,
                item.foseDefault,
                item.foseScript,
                item.foseConsulta,
                item.foseEstado,
                item.foseSexo,
                item.foseEdadinicio,
                item.foseEdadfinal,
                item.foseMultiple,
              ]);
              for (let itemCampo of item.campos) {
                data[0].data.push([
                  itemCampo.foseId,
                  itemCampo.foseCodigo,
                  itemCampo.foseNombre,
                  'CAMPO',
                  itemCampo.foseHistorico,
                  itemCampo.foseIdDependencia,
                  (itemCampo.tipodato) ? itemCampo.tipodato.comaNombrecorto : '',
                  itemCampo.foseOrden,
                  itemCampo.foseValores,
                  itemCampo.foseCol,
                  '',
                  itemCampo.foseCalculado,
                  itemCampo.foseDescripcion,
                  itemCampo.foseRequerido,
                  itemCampo.foseDefault,
                  itemCampo.foseScript,
                  itemCampo.foseConsulta,
                  itemCampo.foseEstado,
                  itemCampo.foseSexo,
                  itemCampo.foseEdadinicio,
                  itemCampo.foseEdadfinal,
                  itemCampo.foseMultiple,
                ]);
              }
            }
            excel.export_json_to_excel({
              header: head, //Header Required
              data, //Specific data Required
              filename: 'secciones-' + new Date().toLocaleString(), //Optional
              autoWidth: false, //Optional
              bookType: 'xlsx' //Optional
            })
          });
        } else {
          this.$swal.fire({
            title: "Escoja un servicio!",
            text: "Debe filtrar por servicio primero.",
            type: "info"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;

    },
    agregartblConfFormulariosservicios() {
      if (this.form.foseId) {
        this.form.tblConfFormulariosservicios.push({
          foseId: this.form.foseId,
          cocuId: null,
          cups: {
            search: null,
            isLoading: false,
            cups: []
          },
        });
      } else {
        this.form.tblConfFormulariosservicios.push({
          cocuId: null,
          cups: {
            search: null,
            isLoading: false,
            cups: []
          },
        });
      }

    },
    eliminartblConfFormulariosservicios(item) {
      this.form.tblConfFormulariosservicios = this.form.tblConfFormulariosservicios.filter(e => this.form.tblConfFormulariosservicios.indexOf(e) != this.form.tblConfFormulariosservicios.indexOf(item));
    },
    limpiar() {
      this.form = {

        foseCodigo: null,

        foseNombre: null,

        foseIdTipo: null,

        foseIdDependencia: null,
        foseScript: null,
        foseTipoTipodato: null,
        foseOrden: 0,
        foseHistorico: 0,
        foseCalculado: 0,
        foseValores: null,
        foseDefault: null,
        foseDescripcion: null,
        foseRequerido: 0,
        foseCol: 3,
        foseConsulta: null,
        tblConfFormulariosservicios: [],
        foseEstado: 1,
        foseSexo: null,
        foseEdadinicio: null,
        foseEdadfinal: null,

      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
