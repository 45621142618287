<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Envio de mensajes masivo</span>
            
        </v-col>
        
        <v-col cols="12" sm="12" md="12">
         <v-alert text outlined prominent color="info" icon="mdi-information-outline" v-if="datosSMS">
                <span>Tienes {{datosSMS.find(a => a.comaNombrecorto == 'CANTIDAD_SMS').comaValor}} de mensajes de texto disponibles para enviar.</span>
            </v-alert>
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
          <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator selectable-key :items="lista" :page.sync="page" :loading="loadingTable"  show-select :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-checkbox class="mt-5" v-if="tabla.isCheck" v-model="enviarTodo" ></v-checkbox>
                            <v-btn v-if="!coppId" class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-btn v-if="seleccionados.length>0 || enviarTodo" class="mx-2 primary--text" :loading="LoadingExp" tile color="white" @click="enviarMensajes">
                                enviarMensajes
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <CardTable :onGetSelects="getSelects" v-bind:table="{ tabla, data:{ items, isExpanded, expand, isCheck:true }}"></CardTable>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
      <v-dialog v-model="dialog4" width="1200px" max-width="1200px">
        <v-card>
          <v-card-title class="text-h5 green lighten-2"> E-MAILS </v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field outlined  label="Correo" v-model="correo" ></v-text-field>
                </v-col>
              </v-row>
              </v-container>           
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loadingEmail" text @click="enviarRecordatorio()">Enviar</v-btn>
            <v-btn color="primary" text @click="dialog4 = false;correo = null;pdfObj = null;"> Cancelar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
export default {
  props:{
    coppId:Number,
  },
  mixins: [generales],
  data() {
    return {
      guardopaciente: false,
      pacienteactual: null,
      datosVaciarPaciente: null,
      loadingCita: false,
      nuevo: false,
      menu: false,
      LoadingExp: false,
      tipo: this.$cookies.get("DEFAULT"),
      tab: "tab-1",
      correo: "",
      loading: false,
      dialog2: false,
      dialog3: false,
      pdfObj: null,
      ingreso:null,
      pdfh: null,
      isNew: true,
      copeId: null,
      isLoading: false,
      cambio: null,
      searchP: null,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      bd: [],
      url: "cita/citas/",
      empleados: [],
      pacientes: [],
      servicios: [],
      servicios2: [],
      tipojornada: [],
      tipoturno: [],
      tipoestado: [],
      tiposala: [],
      tipodetalle:[],
      dispo: [],
      tipohoras: [],
      paciente: null,
      seleccionados: [],
      enviarTodo:false,
      tabla: {
                itemKey: 'citaId',
                orderBy: 'citaFecha',
                isCheck: true,
                orderDesc: true,
                expandible: false,
                expandibleKey: 'citaId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'PACIENTE',
                        tamaño: {
                            cols: '4',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text subtitle-1',
                        valor: (item) => { return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '3',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'green--text subtitle-1',
                        valor: (item) => { return item.citaFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'IDENT.',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EDAD',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.estado.comaNombrecorto == 'FACTURADA'? 'INGRESADO': item.estado.comaNombrecorto },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'DETALLE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.detalle? item.detalle.comaNombrelargo:''  },
                        show: (item) => { return item.detalle ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'EPS',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { 
                           if(item.tblConfBasedatospacientes.length > 0){
                              return (item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre 
                            }else if(item.cocoId){
                              return (item.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfContrato.tblConfPersonasempresas[0].coemNombre 
                            }
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EMPLEADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 } ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 } - ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}` 
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'SERVICIO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre},
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ADMISIONISTA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.admisionista) ? item.admisionista.username : '' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'JORNADA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.jornada) ? item.jornada.comaNombrecorto : '' },
                        show: (item) => { return item.jornada ? true : false }
                    },
                    {
                        titulo: 'TURNO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.turno) ? item.cifaTipoTurno : '' },
                        show: (item) => { return item.turno ? true : false }
                    },
                    {
                        titulo: 'HORA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.citaHora) ? item.citaHora : '' },
                        show: (item) => { return item.citaHora ? true : false }
                    }
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                     {
                        title: 'Recordatorio de cita',
                        icon: 'mdi-email-outline',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => { return this.enviar(item) },
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ACTIVA') : false }
                    },
                    {
                        title: 'Mensaje de texto cita ',
                        icon: 'mdi-email-outline',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => { return this.enviarMensaje(item) },
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ACTIVA') : false }
                    },
                ]
            },
      filtros: [{
          campo:"citaFecha",
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo:"citaTipoEstado",
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, {
          campo:"coppId",
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
        {
          campo:"coprId",
          text: "Empleado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
          filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        }, {
          campo:"coseId",
          text: "Servicio",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "coseNombre",
          listavalue: "coseId",
        },
        {
          campo:"citaFecharango",
          text: "Rango de fecha",
          value: [],
          tipo: "rango",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo:"admisionista",
          text: "Admisionista",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
           campo:"coemId",
          text: "Empresa",
          value: null,
          tipo: "lista",
          lista: [],
          listatext: "tblConfPersonasempresas[0].coemNombre",
          listavalue: "tblConfPersonasempresas[0].coemId"
        },

      ],
      secciones: [],
      campos: [],
      Historia: [],
      historial: [],
      diagnosticos: [],
      ordenes: [],
      recetarios: [],
      remisiones: [],
      contratos: [],
      empresas: [],
      dientesplaca: [],
      primerOdontograma: [],
      dientesodontograma: [],
      historiaData: null,
      loadingEmail: false,
      dialog4:false,
      datosSMS:null,

    };
  },
  created: async function() {
      this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
      this.filtros[3].lista = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
      this.filtros[4].lista = await this.$apiService.index("sistema/servicios/index/").then(data => data);
      this.empresas = this.filtros[7].lista = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
      this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
      this.tipoturno = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTURNO").then(data => data);
      this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
      this.tipodetalle = await this.$apiService.index("sistema/maestra/index/TBL_TIPODETACITA").then(data => data);
      this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
      this.contratos = this.contratos.filter(e => new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
      this.filtros[0].value = new Date().yyyymmdd();
      this.datosSMS = await this.$apiService.index("sistema/maestra/index/TBL_DATOSSMS").then(data => data);
     await this.search(this.filtros);
  },

  methods: {
     getSelects(seleccionados){
       this.seleccionados = seleccionados;
     },
    async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            if(this.coppId){
              filtros[2].value = this.coppId;
            }
            const data = await this.$apiService.index(`cita/citas/buscar/0?${this.filtrar(filtros,this.page)}&citaTipoEstado=${this.tipoestado.find(e => e.comaNombrelargo == 'CITA ACTIVA').comaId}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
    },
    async enviar(item) {
        try {
              this.pdfObj = item;
              this.correo = item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeEmail
              this.dialog4= true;
        } catch (error) {
            console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
          });
        }
    },
    async enviarRecordatorio() {
        try {
              if (!this.correo ) {
                  this.$swal.fire({
                      title: "Correo invalido!",
                      text: "ingrese un correo valido",
                      icon: "error"
                  });
                  return false;
              }
              if (this.correo.indexOf('@') < 0) {
                  this.$swal.fire({
                      title: "Correo invalido!",
                      text: "ingrese un correo valido",
                      icon: "error"
                  });
                  return false;
              }
              this.loadingEmail = true;
              let resultado = await this.$apiService.create("cita/citas/sendrecordatorio?correo=" + this.correo, this.pdfObj).then(data => data);
              if (resultado == 'Correo enviado correctamente') {
                  this.$swal.fire({
                      title: "Completado!",
                      text: "Envio exitoso",
                      icon: "success",
                      confirmButtonText: "Ok"
                  });
              } else {
                  this.$swal.fire({
                      title: "Algo sucedio!",
                      text: "contacta con el administrador",
                      icon: "error"
                  });
              }
              
        } catch (error) {
            this.loadingEmail = false;
            console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
          });
        }
            
      this.loadingEmail = false;
    },
    async enviarMensaje(item) {
      this.LoadingExp = true;
      let datosSMS = await this.$apiService.index("sistema/maestra/index/TBL_DATOSSMS").then(data => data);
      if(item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != null &&
          item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '' &&
          item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '0' &&
          item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 0 &&
          item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 'SIN TELEFO'){
          
          try {
            let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
            let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
            let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
            
            let mensaje = `IpsTools le recorda su cita el ${item.citaFecha} a las ${item.citaHora} de ${item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}, en ${ips.tblConfPersonasempresas[0].coemNombre} - ${ips.copeDireccion} - ${ips.copeTelefono}`
            let formData = {
             'key': datosSMS.find(a => a.comaNombrecorto == 'KEY').comaValor,
             'client': datosSMS.find(a => a.comaNombrecorto == 'CLIENT').comaValor,
             'phone': '57'+item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono,
             'sms': mensaje,
             'country-code': datosSMS.find(a => a.comaNombrecorto == 'COUNTRY-CODE').comaValor
            }
             await this.$apiService.create("sistema/personas/sms", formData).then(data => data);
              this.$swal.fire({
                          title: "Completado!",
                          text: "Envio exitoso",
                          icon: "success",
                          confirmButtonText: "Ok"
              });
         } catch (error) {
            this.LoadingExp = false;
             console.log(error)
                this.$swal.fire({
                  title: "Algo sucedio!",
                  text: "contacta con el administrador",
                  icon: "error"
              });
         }
     }else{
         this.$swal.fire({
                  title: "Numero invalido!",
                  text: "verifique el numero de telefono",
                  icon: "warning"
              });
     }
      this.LoadingExp = false;
    },
    async enviarMensajes() {
     this.LoadingExp = true;
     try {
      let datosSMS = await this.$apiService.index("sistema/maestra/index/TBL_DATOSSMS").then(data => data);
      if(this.enviarTodo){
        var search = ``;

        if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
          search = search + `citaFecha=${this.filtros[0].value}&`;
        }

        if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
          search = search + `citaTipoEstado=${this.filtros[1].value}&`;

        }
        if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
          search = search + `coppId=${this.filtros[2].value}&`;

        }
        if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
          search = search + `coprId=${this.filtros[3].value}&`;

        }
        if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
          search = search + `coseId=${this.filtros[4].value}&`;

        }
        if (this.filtros[5].value != null && this.filtros[5].value.length > 1) {
          search = search + `citaFecharango=${this.filtros[5].value[0]},${this.filtros[5].value[1]}&`;
        }
        if (this.filtros[6].value != null && this.filtros[6].value.length > 1) {
          search = search + `admisionista=${this.filtros[6].value}&`;
          this.filtros[6].value = [];
        }
        if (this.filtros[7].value != null && this.filtros[7].value.toString().replace(/ /g, "") != "") {
          search = search + `coemId=${this.filtros[7].value}&`;
        }
        let citas = await this.$apiService.index(`cita/citas/buscarcompleto/0?${search}`).then(data => data);
        citas = citas.filter(e => e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != null &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '' &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '0' &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 0 &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 'SIN TELEFO');
        let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
        let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
        let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
        for (let item of citas) {
          let mensaje = `${ips.tblConfPersonasempresas[0].coemNombre} le recorda su cita el ${item.citaFecha} a las ${item.citaHora} de ${item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}, en ${ips.tblConfPersonasempresas[0].coemNombre} - ${ips.copeDireccion} - ${ips.copeTelefono}, este sms a sido generado por IpsTools sistema integral de salud no contestar`
           let formData = {
            'key': datosSMS.find(a => a.comaNombrecorto == 'KEY').comaValor,
            'client': datosSMS.find(a => a.comaNombrecorto == 'CLIENT').comaValor,
            'phone': '57'+item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono,
            'sms': mensaje,
            'country-code': datosSMS.find(a => a.comaNombrecorto == 'COUNTRY-CODE').comaValor
           }
          await this.$apiService.create("sistema/personas/sms", formData).then(data => data);
        }
      }else{
        let citas = this.lista.filter(e => e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != null &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '' &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != '0' &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 0 &&
          e.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono != 'SIN TELEFO' &&
          this.seleccionados.some(a => a== e.citaId));
          let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
        let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
        let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
        let datosSMS = await this.$apiService.index("sistema/maestra/index/TBL_DATOSSMS").then(data => data);

        for (let item of citas) {
          let mensaje = `${ips.tblConfPersonasempresas[0].coemNombre} le recorda su cita el ${item.citaFecha} a las ${item.citaHora} de ${item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}, en ${ips.tblConfPersonasempresas[0].coemNombre} - ${ips.copeDireccion} - ${ips.copeTelefono}, este sms a sido generado por IpsTools sistema integral de salud no contestar`
         let formData = {
           'key': datosSMS.find(a => a.comaNombrecorto == 'KEY').comaValor,
           'client': datosSMS.find(a => a.comaNombrecorto == 'CLIENT').comaValor,
           'phone': '57'+item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono,
           'sms': mensaje,
           'country-code': datosSMS.find(a => a.comaNombrecorto == 'COUNTRY-CODE').comaValor
          }
          await this.$apiService.create("sistema/personas/sms", formData).then(data => data);
        }
      }
      this.$swal.fire({
                      title: "Completado!",
                      text: "Envio exitoso",
                      icon: "success",
                      confirmButtonText: "Ok"
                  });
     } catch (error) {
        this.LoadingExp = false;
         console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
          });
     }
      this.LoadingExp = false;

    },
    async pdf(item ,historico = false) {
      if(this.getPermiso("verCitas")){
        await this.dibujarH(item,historico);
      }
    },
    limpiar() {
      this.form = {
        citaFecha: this.currentDate(new Date()),
        citaTipoJornada: null,
        citaTipoTurno: null,
        citaTipoSala: null,
        citaTipoEstado: null,
        citaTipo: 0,
        coppId: null,
        createdBy: this.user.id,
        coprId: null,
        cbdpId: null,
        cocsId: null,
        cocoId: null,
        citaTipoDetalle:null
      };
    },
    close() {
      this.cambio = null;
      this.guardopaciente = false;
      this.pacienteactual = null;
      this.isNew = true;
      this.dialog = false;
      this.datosVaciarPaciente = null;
      this.limpiar();
    }
  }
};

</script>

<style>

</style>
