<style>

</style>

<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 primary--text display-2 float-left">Perfiles</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-btn class="mx-2 float-right" fab  small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon> 
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                         <v-container class="pt-0">
                            <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                <v-col v-for="item in items" :key="item.name" cols="12" sm="12" md="10" lg="10">
                                    <v-card elevation="0" class="rounded-xl">
                                        <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                            <v-row no-gutters>
                                                <v-col cols="10" sm="10" md="10" lg="10">
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.primarios">
                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="2" sm="2" md="2" lg="2">
                                                    <v-menu bottom left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <template v-for="(m, i) in tabla.menu">
                                                                <template v-if="m.show(item)">
                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item v-else :key="i" @click="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                </template>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <template v-for="(p,i) in tabla.segundarios">
                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                        {{p.titulo}} <span :class="p.class">
                                                            {{ p.valor(item) }}
                                                        </span>
                                                    </v-col>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                    <v-row>
                                                        <v-col cols="2" sm="2" md="1" lg="1">
                                                            <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                <v-icon v-else>mdi-chevron-up</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="10" sm="10" md="11" lg="11">
                                                            <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <template v-if="tabla.expandible">
                                            <v-divider v-if="isExpanded(item)"></v-divider>
                                            <v-card-text v-if="isExpanded(item)">
                                                <v-container>
                                                    <template v-for="items in tabla.expandibleItems(item) ">
                                                        <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                            <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                                <template v-for="(p,i) in tabla.expanItem">
                                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(items) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                                <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                    <v-menu bottom left>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-list>
                                                                            <template v-for="(m, i) in tabla.expanMenu">
                                                                                <template v-if="m.show(items)">
                                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                    <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            </template>
                                                                        </v-list>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-sheet>
                                                    </template>
                                                </v-container>
                                            </v-card-text>
                                        </template>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>

                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" no-gutters align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>

    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent width="1400px" min-width="1400px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="loadingForm" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="nombre" outlined dense :error-messages="errores['name']" v-model="form.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="descripcion" outlined dense :error-messages="errores['description']" v-model="form.description"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <label>permisos</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-for="(padre,i ) in padres" :key="i">
                                <label class="primary--text">{{padre.padre}}</label>
                                <v-row class="grey lighten-3 mt-2">
                                    <v-col cols="4" sm="4" md="4" v-for="permiso in padre.permisos" :key="permiso.name">
                                        <v-checkbox v-model="permisos2" :label="permiso.description" :value="permiso.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';

export default {
    mixins: [generales],

    data() {
        return {

            url: "sistema/perfiles/",
            listPermisos: [],
            padres: [],
            form: {
                name: null,
                description: null,
                type: 1,
                tblAuthItemChildren: []
            },
            permisos2: [],
            tabla: {
                itemKey: 'name',
                orderBy: 'name',
                expandible: true,
                expandibleKey: 'name',
                expandibleTitle: (item) => { return `PERMISOS ${item.tblAuthItemChildren.length}` },
                expandibleItems: (item) => { return item.tblAuthItemChildren },
                primarios: [{
                        titulo: 'NOMBRE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.name },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'DESCRIPCION',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.description },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [
                ],
                expanItem: [{
                        titulo: 'PERMISO',
                        tamaño: {
                            cols: '12',
                            sm: '4',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.child },
                        show: (item) => { return item ? true : false }
                    }, 
                ],
                expanMenu: [
                ],
                menu: [ {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog = true; return this.editar(item.name) },
                        show: (item) => { return item ? this.getPermiso('editarRol') : false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.name) },
                        show: (item) => { return item ? this.getPermiso('eliminarRol') : false }

                    },
                ]
            },
            filtros: [{
                campo: 'nombre',
                text: "nombre",
                value: null,
                tipo: "text",
                search: true,
                isLoading: false,
            }, {
                campo: 'descripcion',
                text: "descripcion",
                value: null,
                tipo: "text",
                search: true,
                isLoading: false,
            }]
        };
    },
    created: async function () {
        await this.search(this.filtros);
        this.listPermisos = await this.$apiService.index(`${this.url}index/2`).then(data => data);
        this.listPermisos.sort((a, b) => (a.padre < b.padre) ? -1 : 1);
        this.listPermisos.forEach(element => {
            if (this.padres.find(e => e.padre == element.padre) == undefined) {
                this.padres.push({ padre: element.padre, permisos: this.listPermisos.filter(e => e.padre == element.padre) });
            }

        });
    },

    methods: {
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`${this.url}buscar/1?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
        async agregar() {
            try {
                this.errores = [];
                this.loadingGuardar = true;
                await this.permisos2.forEach((permiso) => {
                    this.form.tblAuthItemChildren.push((!this.isNew) ? { parent: this.form.name, child: permiso } : { child: permiso });
                });

                let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
                    await this.$apiService.update(`${this.url}update/${this.form.name}`, this.form).then((data) => data);
                if (data) {
                    this.close();
                    this.$swal.fire({
                        title: "Completado!",
                        text: "Registro exitoso",
                        icon: "success",
                        confirmButtonText: "Ok"
                    });
                    await this.search(this.filtros);
                }

            } catch (error) {
                this.getErrores(error);
            }
            this.loadingGuardar = false;
        },
        async eliminar(id) {
            try {
                let result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
                    if (data) {
                        await this.search(this.filtros);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {

            try {
                this.loadingForm = true;
                this.form = await this.$apiService.index(`${this.url}view/${id}`).then(data => data);
                this.permisos2 = [];
                this.form.tblAuthItemChildren.forEach((permiso) => {
                    this.permisos2.push(permiso.child);
                });
                this.form.tblAuthItemChildren = [];
                this.isNew = false;
                this.loadingForm = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        limpiar() {
            this.form = {
                name: null,
                description: null,
                type: 1,
                permisos: []

            };
            this.permisos2 = [];
        },
        close() {
            this.errores = [];
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>
