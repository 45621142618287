<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto" md="3" v-if="datos">
                <EditarCampSeccionEncu :onEliminarCampo="eliminarCampoSeccion" :onGuardar="guardarCampoSeccion" :onCerrarPropiedades="cerrarPropieades" v-bind:datos="datos" v-bind:validaciones="validaciones" />
            </v-col>
            <v-col cols="12" :md="datos?'9':'12'">
                <v-form>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Historia</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-dialog v-model="dialogmasivo" persistent width="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="red lighten-2" dark v-on="on" class="mx-2">
                                        Importar
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="headline grey lighten-2" primary-title>
                                        Cargar excel
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row v-if="!loader">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-file-input outlined v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                <span class="headline">CARGANDO FORMULARIO....</span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-btn color="primary" text target="_blank" :href="plantilla">
                                            Descargar formato
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                            cancelar
                                        </v-btn>
                                        <v-btn color="primary" v-if="!loader" text @click="subida">
                                            cargar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn color="primary" @click="agregarCampoSeccion('SECCION')">
                                Agregar seccion<v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-col cols="12" sm="12" md="2">
                                <v-text-field class="pt-7" outlined dense label="Version" v-model="versionActual" append-icon="search" @click:append="recargar()"></v-text-field>
                            </v-col>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text :to="{name:'Historias'}">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="guardarMasivo()">Guardar todo</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-card-text id="scroll-historia">
                            <v-container>
                                <v-row v-for="seccion in  secciones" :key="'row-'+seccion.ecseCodigo" :id="'CONTENEDOR_'+seccion.ecseCodigo" class=" mt-5 bordes rounded-xl">
                                    <v-col cols="12" sm="12" md="12" class="primary--text cursor" @click="selecccionarCampoSeccion(seccion,'SECCION')">
                                        <v-tooltip v-if="seccion.ecseDescripcion" max-width="300" color="light-blue darken-4" bottom>
                                            <template v-slot:activator="{ on }">
                                                <h3 v-on="on">{{seccion.ecseNombre}}</h3>
                                            </template>
                                            <span>{{seccion.ecseDescripcion}}</span>
                                        </v-tooltip>
                                        <h3 v-else>{{seccion.ecseNombre}}
                                        </h3>
                                    </v-col>
                                    <v-col cols="12" sm="12" :md="c.ecseCol" v-for="c in  seccion.campos" :key="'col-'+c.ecseCodigo" :id="'CAMPO_'+c.ecseCodigo" class="bordesCampos  cursor" @click="selecccionarCampoSeccion(c,'CAMPO')">
                                        <v-tooltip v-if="c.ecseDescripcion" max-width="300" color="light-blue darken-4" right>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" :for="c.ecseCodigo">{{c.ecseNombre}}</span>
                                            </template>
                                            <span>{{c.ecseDescripcion}}</span>
                                        </v-tooltip>
                                        <span v-else :for="c.ecseCodigo">{{c.ecseNombre}}</span>
                                        <br>
                                        <template v-if="c.tipodato">
                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                <input type="text" :id="c.ecseCodigo" class="campo">
                                            </template>
                                            <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                <textarea :id="c.ecseCodigo" :rows="c.ecseFilas" class="campo"></textarea>
                                            </template>
                                            <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                <select :id="c.ecseCodigo" class="campo">
                                                    <option :value="op.values" v-for="(op,index) in getValues(c.ecseValores)" :key="c.ecseCodigo+'_OPTION_'+index">{{op.texts}}</option>
                                                </select>
                                            </template>
                                            <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                <input type="checkbox" class="campo" :id="c.ecseCodigo">
                                            </template>
                                            <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                <input type="date" class="campo" :id="c.ecseCodigo">
                                            </template>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-btn color="primary" @click="agregarCampoSeccion('CAMPO',seccion)">
                                            Agregar campo<v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogMasivo" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Guardado masivo</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!guardando && errores.length > 0">
                            <v-col cols="12" sm="12" md="12">
                                <span class="blue--text">Errores al guardar las siguientes secciones</span>
                                <strong><span class="red--text" v-for="(item,i) in errores" :key="'ERROR_'+i">{{item.ecseNombre}}</span> <br></strong>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="12" class="text-center">
                                <span class="headline">CARGANDO SECCIONES POR FAVOR ESPERE....</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="text-center">
                                <v-progress-linear v-model="porcentaje" color="primary" height="25">
                                    <template v-slot:default="{ value }">
                                        <strong>{{ Math.ceil(value) }}% de {{total}} secciones..</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="dialogMasivo = false;recargar()" v-if="!guardando">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators';
import * as XLSX from 'xlsx'
import plantilla from "@/assets/files/plantilla_historia.xlsx";
export default {
  components: {
    EditarCampSeccionEncu: () => import("../../components/EditarCampSeccionEncu.vue"),
  },
  data() {
    return {
      plantilla: plantilla,
      loader: false,
      isNew: true,
      url: "sistema/encuestassecciones/",
      secciones: [],
      datos: null,
      validaciones: {
        ecseCodigo: {
          $error: false
        },
        ecseNombre: {
          $error: false
        },
      },
      tiposecciones: [],
      tipocampos: [],
      depe: [],
      file: null,
      dialogmasivo: false,
      errores: [],
      porcentaje: 0,
      total: 0,
      dialogMasivo: false,
      guardando: false,
      versionActual: 1
    };
  },
  validations: {
    datos: {
      ecseCodigo: {
        required
      },
      ecseNombre: {
        required
      },
      ecseIdTipo: {
        required
      },
      ecseOrden: {
        required
      },
      ecseCol: {
        required
      }
    },
    form: {
      ecseCodigo: {
        required,
      },
      ecseNombre: {
        required,
      },

      ecseIdTipo: {
        required,
      },

      ecseOrden: {
        required,
      },
      campos: {
        $each: {
          ecseCodigo: {
            required,
          },
          ecseNombre: {
            required,
          },

          ecseIdTipo: {
            required,
          },
          ecseTipoTipodato: {
            required,
          },
          ecseCol: {
            required,
          },
          ecseOrden: {
            required,
          },
        }

      },

    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.servicios = await this.$apiService.index("sistema/encuestas/index/").then(data => data);
    this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
    this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    if (this.$route.params.id) {
      this.secciones = await this.$apiService.index(`${this.url}secciones/${this.$route.params.id}`).then(data => data);
    }
    this.$store.commit('setCargaDatos', false);
  },
  methods: {
    async recargar() {
      this.secciones = await this.$apiService.index(`${this.url}secciones/${this.$route.params.id}?version=${this.versionActual}`).then(data => data);
    },
    getValues(values) {
      const lista = [];
      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },
    selecccionarCampoSeccion(datos, tipo) {
      this.datos = null;
      this.validaciones = {
        ecseCodigo: {
          $error: false
        },
        ecseNombre: {
          $error: false
        },
      }
      this.datos = datos;
      this.datos.tipodatos = this.tipocampos
      this.datos.tipo = tipo;
      if (tipo == 'CAMPO' && this.datos.ecseIdDependencia) {
        this.datos.dependenciaCodigo = this.secciones.find(e => e.ecseId == this.datos.ecseIdDependencia).ecseCodigo;
      }
    },
    agregarCampoSeccion(tipo, seccion = null) {
      try {
        this.datos = null;
        this.validaciones = {
          ecseCodigo: {
            $error: false
          },
          ecseNombre: {
            $error: false
          },
        }
        //this.$nextTick().then(() => {
        this.datos = {
          ecseCodigo: (tipo == 'CAMPO' && seccion) ? seccion.ecseCodigo + '_' + (seccion.campos.length + 1) : null,
          ecseNombre: null,
          ecseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == tipo).comaId,
          ecseIdDependencia: (tipo == 'CAMPO' && seccion) ? seccion.ecseId : null,
          ecseScript: null,
          ecseTipoTipodato: null,
          ecseOrden: (tipo == 'CAMPO' && seccion) ? (seccion.campos.length + 1) : (this.secciones.length + 1),
          ecseValores: null,
          ecseDefault: null,
          ecseDescripcion: null,
          ecseRequerido: 0,
          ecseCol: 3,
          ecseFilas: 4,
          ecseConsulta: null,
          ecseEstado: 1,
          ecseSexo: null,
          ecseEdadinicio: null,
          ecseEdadfinal: null,
          dependenciaCodigo: (tipo == 'CAMPO' && seccion) ? seccion.ecseCodigo : null,
          tipo: tipo,
          tipodatos: this.tipocampos,
          encuestas: (tipo == 'CAMPO' && seccion) ? [] : [{
            coenId: parseInt(this.$route.params.id)
          }],
          campos: [],
          ecseVersion: 1,
          ecseTriage: 0,
        };
        //});
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Error!",
          text: "contacte con el administrador",
          icon: "error"
        });
      }
    },
    async guardarCampoSeccion(datos) {
      this.$v.datos.$touch();
      if (!this.$v.datos.$error) {
        if (datos.tipo == 'CAMPO') {
          if (datos.ecseTipoTipodato) {
            await this.guardar(datos)
          } else {
            this.$swal.fire({
              title: "Cuidado!",
              text: "debe escojer un tipo de dato",
              icon: "warning"
            });
          }
        } else {
          await this.guardar(datos)
        }

      } else {
        this.validaciones = this.$v.datos;
      }
    },
    async eliminarCampoSeccion(datos) {
      try {
        var result = await this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          })
          .then(result => result.value);
        if (result) {
          if (datos.ecseId) {
            let data = await this.$apiService.delete(this.url + "delete/" + datos.ecseId + "/").then(data => data);
            if (data) {
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
          }

          if (datos.tipo == 'CAMPO') {
            if (this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo).campos.find(e => e.ecseCodigo == datos.ecseCodigo && e.ecseVersion == datos.ecseVersion)) {
              this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos = this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos.filter(e => e.ecseCodigo != datos.ecseCodigo);
            }
          } else {
            if (this.secciones.find(e => e.ecseCodigo == datos.ecseCodigo && e.ecseVersion == datos.ecseVersion)) {
              this.secciones = this.secciones.filter(e => e.ecseCodigo != datos.ecseCodigo && e.ecseVersion == datos.ecseVersion);
            }
          }
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    cerrarPropieades() {
      this.datos = null;
    },
    async guardar(datos) {
      try {
        this.isNew = this.datos.ecseId ? false : true;
        let data = null;
        let codigos = await this.$apiService.index(`${this.url}index/?offset=0&ecseCodigo=${this.datos.ecseCodigo}&ecseVersion=${this.datos.ecseVersion}`).then(data => data);
        codigos.rows = codigos.rows.filter(e => e.ecseCodigo == this.datos.ecseCodigo)
        if (this.isNew) {
          if (codigos.rows.length > 0) {
            this.$swal.fire({
              title: "El codigo ya esta en uso!",
              text: "por favor escoje otro codigo",
              icon: "info"
            });
            return false;
          }
          data = await this.$apiService.create(this.url + "create", this.datos).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        } else {
          if (codigos.rows.length > 0) {
            if (codigos.rows[0].ecseCodigo != this.datos.ecseCodigo) {
              this.$swal.fire({
                title: "El codigo ya esta en uso!",
                text: "por favor escoje otro codigo",
                icon: "info"
              });
              return false;
            }
          }
          data = await this.$apiService.update(this.url + "update/" + this.datos.ecseId + "/", this.datos).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        }
        data.tipodatos = this.tipocampos
        data.tipo = datos.tipo;
        if (datos.tipo == 'CAMPO') {
          if (this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos.find(e => e.ecseCodigo == datos.ecseCodigo && e.ecseVersion == datos.ecseVersion)) {
            this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos = this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos.filter(e => e.ecseCodigo != datos.ecseCodigo);
          }
          this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos.push(data)
          this.secciones.find(e => e.ecseCodigo == datos.dependenciaCodigo && e.ecseVersion == datos.ecseVersion).campos.sort((a, b) => a.ecseOrden - b.ecseOrden);
        } else {
          if (this.secciones.find(e => e.ecseCodigo == datos.ecseCodigo && e.ecseVersion == datos.ecseVersion)) {
            this.secciones = this.secciones.filter(e => e.ecseCodigo != datos.ecseCodigo);
          }
          this.secciones.push(data)
          this.secciones.sort((a, b) => a.ecseOrden - b.ecseOrden);
        }
        this.datos = data;
        return true;
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
        return false;
      }
    },
    onFileChange(file) {
      this.file = file;
    },
    subida() {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {
            type: 'binary'
          });
          let sheet_name_list = workbook.SheetNames;
          let xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
          let secciones = xlData.filter(e => e.TIPO == 'SECCION');
          let mapXlData = secciones.map(item => {
            let campos = xlData.filter(e => e.DEPENDENCIA == item.ID && e.TIPO == 'CAMPO');
            let mapCampos = campos.map(itemCamp => {
              return {
                ecseCodigo: itemCamp.CODIGO,
                ecseNombre: itemCamp.NOMBRE,
                ecseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == itemCamp.TIPO).comaId,
                ecseTipoTipodato: this.tipocampos.find(e => e.comaNombrecorto == itemCamp.TIPO_DATO).comaId,
                ecseOrden: itemCamp.ORDEN,
                ecseHistorico: (itemCamp.HISTORICO == '1') ? 1 : 0,
                ecseValores: itemCamp.VALORES,
                ecseCol: itemCamp.COLUMNAS,
                ecseDescripcion: itemCamp.DESCRIPCION,
                ecseDefault: itemCamp.DEFAULT,
                ecseRequerido: (itemCamp.REQUERIDO == '1') ? 1 : 0,
                ecseCalculado: (itemCamp.CALCULADO == '1') ? 1 : 0,
                ecseScript: itemCamp.SCRIPT,
                ecseConsulta: itemCamp.CONSULTA,
                ecseFilas: (itemCamp.FILAS) ? parseInt(itemCamp.FILAS) : null,
                ecseEstado: (itemCamp.ESTADO == '1') ? 1 : 0,
                ecseSexo: (itemCamp.SEXO) ? parseInt(itemCamp.SEXO) : null,
                ecseEdadinicio: (itemCamp.EDADINICIO) ? parseInt(itemCamp.EDADINICIO) : null,
                ecseEdadfinal: (itemCamp.EDADFINAL) ? parseInt(itemCamp.EDADFINAL) : null,
                dependenciaCodigo: item.CODIGO,
                tipo: itemCamp.TIPO,
                tipodatos: this.tipocampos,
                tipodato: this.tipocampos.find(e => e.comaNombrecorto == itemCamp.TIPO_DATO),
                encuestas: [],
                campos: [],
                ecseVersion: (itemCamp.VERSION) ? parseInt(itemCamp.VERSION) : 1,
                ecseTriage: (itemCamp.TRIAGE) ? parseInt(itemCamp.TRIAGE) : 0,
              }
            });
            let servicios = item.SERVICIOS.split(';');
            let encuestas = []
            if (servicios.length > 0) {
              for (let serv of servicios) {
                let ser = this.servicios.find(e => e.coenNombre == serv);
                if (ser) {
                  encuestas.push({
                    coenId: ser.coenId,
                  });
                }
              }
            }
            return {
              ecseCodigo: item.CODIGO,
              ecseNombre: item.NOMBRE,
              ecseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == item.TIPO).comaId,
              ecseOrden: item.ORDEN,
              ecseHistorico: (item.HISTORICO == '1') ? 1 : 0,
              ecseValores: item.VALORES,
              ecseCol: item.COLUMNAS,
              ecseDescripcion: item.DESCRIPCION,
              ecseDefault: item.DEFAULT,
              ecseRequerido: (item.REQUERIDO == '1') ? 1 : 0,
              ecseCalculado: (item.CALCULADO == '1') ? 1 : 0,
              ecseScript: item.SCRIPT,
              ecseConsulta: item.CONSULTA,
              ecseFilas: (item.FILAS) ? parseInt(item.FILAS) : null,
              ecseEstado: (item.ESTADO == '1') ? 1 : 0,
              ecseSexo: (item.SEXO) ? parseInt(item.SEXO) : null,
              ecseEdadinicio: (item.EDADINICIO) ? parseInt(item.EDADINICIO) : null,
              ecseEdadfinal: (item.EDADFINAL) ? parseInt(item.EDADFINAL) : null,
              dependenciaCodigo: null,
              tipo: item.TIPO,
              encuestas: (item.tipo == 'CAMPO') ? [] : encuestas,
              campos: mapCampos,
              ecseVersion: (item.VERSION) ? parseInt(item.VERSION) : 1,
              ecseTriage: (item.TRIAGE) ? parseInt(item.TRIAGE) : 0,
            }
          })
          let seccionesResult = [];
          for (let iterator of mapXlData) {
            if (this.secciones.find(e => e.ecseCodigo == iterator.ecseCodigo && e.ecseVersion == iterator.ecseVersion)) {
              for (let camp of this.secciones.find(e => e.ecseCodigo == iterator.ecseCodigo && e.ecseVersion == iterator.ecseVersion).campos) {
                if (this.secciones.find(e => e.ecseCodigo == iterator.ecseCodigo && e.ecseVersion == iterator.ecseVersion).campos.find(e => e.ecseCodigo == camp.ecseCodigo && e.ecseVersion == camp.ecseVersion)) {
                  iterator.campos.find(e => e.ecseCodigo == camp.ecseCodigo && e.ecseVersion == camp.ecseVersion).ecseId = this.secciones.find(e => e.ecseCodigo == iterator.ecseCodigo && e.ecseVersion == iterator.ecseVersion).campos.find(e => e.ecseCodigo == camp.ecseCodigo && e.ecseVersion == camp.ecseVersion).ecseId;
                }
              }
              iterator.ecseId = this.secciones.find(e => e.ecseCodigo == iterator.ecseCodigo && e.ecseVersion == iterator.ecseVersion).ecseId;
              //this.secciones = this.secciones.filter(e => e.ecseCodigo != iterator.ecseCodigo && e.ecseVersion != iterator.ecseVersion);
            }
            seccionesResult.push(iterator)
            seccionesResult.sort((a, b) => a.ecseOrden - b.ecseOrden);
            this.secciones = seccionesResult
          }

        };
        reader.readAsBinaryString(this.file);
        this.dialogmasivo = false;
        this.$swal.fire({
          title: "Completado!",
          text: "Cargue exitoso",
          icon: "success",
          confirmButtonText: "Ok"
        });
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
        return false;
      }
    },
    async guardarMasivo() {
      this.dialogMasivo = true;
      this.guardando = true;
      this.total = this.secciones.length;
      let cantidad = 0;
      for (let seccion of this.secciones) {
        try {
          cantidad = cantidad + 1;
          this.isNew = seccion.ecseId ? false : true;
          let codigos = await this.$apiService.index(`${this.url}index/?offset=0&ecseCodigo=${seccion.ecseCodigo}&ecseVersion=${seccion.ecseVersion}`).then(data => data);
          if (this.isNew && codigos.rows.length == 0) {
            if (codigos.rows.length > 0) {
              this.errores.push({
                ecseNombre: seccion.ecseNombre,
                ecseError: "El codigo de la seccion ya esta en uso!"
              })
            } else {
              let data = await this.$apiService.create(this.url + "create", seccion).then(data => data);
              if (data) {
                this.porcentaje = this.total > 0 ? ((cantidad / this.total) * 100) : 0;
              }
            }
          } else {
            if (codigos.rows.length > 0) {
              if (codigos.rows[0].ecseCodigo != seccion.ecseCodigo) {
                this.errores.push({
                  ecseNombre: seccion.ecseNombre,
                  ecseError: "El codigo de la seccion ya esta en uso!"
                })
              } else {
                seccion.ecseId = codigos.rows[0].ecseId;
                seccion.encuestas.forEach(e => e.ecseId = seccion.ecseId)
                seccion.campos.forEach(e => e.ecseIdDependencia = seccion.ecseId)
                let data = await this.$apiService.update(this.url + "update/" + seccion.ecseId + "/", seccion).then(data => data);
                if (data) {
                  this.porcentaje = this.total > 0 ? ((cantidad / this.total) * 100) : 0;
                }
              }
            }
          }

        } catch (error) {
          this.errores.push({
            ecseNombre: seccion.ecseNombre,
            ecseError: error
          })
        }
      }
      this.guardando = false;
    },
  },
}

</script>

<style>
.campo {
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
}

select.campo {
  -moz-appearance: listbox;
  -webkit-appearance: listbox;
}

.bordes {
  background-color: rgb(255, 255, 255) !important;
}

.bordesCampos {
  border: solid 1px rgb(206, 206, 206);
}

.cursor {
  cursor: pointer;
}

#scroll-historia::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#scroll-historia::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#scroll-historia::-webkit-scrollbar-button:increment,
#scroll-historia::-webkit-scrollbar-button {
  display: none !important;
}

#scroll-historia::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#scroll-historia::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#scroll-historia::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#scroll-historia::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#scroll-historia {
  background-color: rgb(218, 216, 216) !important;
  height: 1100px;
  overflow: auto;
  scrollbar-color: rgb(76, 113, 159) rgb(199, 220, 242);
  scrollbar-width: thin;
}

</style>
