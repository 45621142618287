<style>
</style>
<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Pacientes</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" :to="{ name: 'Pacientescreate' }">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-container class="pt-0">
                            <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                    <v-card elevation="0" class="rounded-xl">
                                        <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                            <v-row no-gutters>
                                                <v-col cols="10" sm="10" md="10" lg="10">
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.primarios">
                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="2" sm="2" md="2" lg="2">
                                                    <v-menu bottom left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <template v-for="(m, i) in tabla.menu">
                                                                <template v-if="m.show(item)">
                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item v-else :key="i" @click="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                </template>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <template v-for="(p,i) in tabla.segundarios">
                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                        {{p.titulo}} <span :class="p.class">
                                                            {{ p.valor(item) }}
                                                        </span>
                                                    </v-col>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                    <v-row>
                                                        <v-col cols="2" sm="2" md="1" lg="1">
                                                            <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                <v-icon v-else>mdi-chevron-up</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="10" sm="10" md="11" lg="11">
                                                            <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <template v-if="tabla.expandible">
                                            <v-divider v-if="isExpanded(item)"></v-divider>
                                            <v-card-text v-if="isExpanded(item)">
                                                <v-container>
                                                    <template v-for="items in tabla.expandibleItems(item) ">
                                                        <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                            <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                                <template v-for="(p,i) in tabla.expanItem">
                                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(items) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                                <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                    <v-menu bottom left>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-list>
                                                                            <template v-for="(m, i) in tabla.expanMenu">
                                                                                <template v-if="m.show(items)">
                                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                    <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            </template>
                                                                        </v-list>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-sheet>
                                                    </template>
                                                </v-container>
                                            </v-card-text>
                                        </template>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import generales from '../../../mixins/generales.js';
export default {
    mixins: [generales],
    data() {
        return {
            tabla: {
                itemKey: 'copeId',
                orderBy: 'copeId',
                expandible: false,
                expandibleKey: 'copeId',
                primarios: [{
                        titulo: 'NOMBRE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.tblConfPersonasnaturales[0].copnNombre1} ${item.tblConfPersonasnaturales[0].copnNombre2} ${item.tblConfPersonasnaturales[0].copnApellido1} ${item.tblConfPersonasnaturales[0].copnApellido2}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'IDENTIFICACION',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.tipoidentificacion.comaNombrecorto} ${item.copeIdentificacion}` },
                        show: (item) => { return item ? true : false }
                    },

                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.copeTelefono },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'EMAIL',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.copeEmail },
                        show: (item) => { return item ? true : false }
                    },
                    
                ],
                expanItem: [],
                expanMenu: [],
                menu: [ {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'link',
                        action: (item) => { return `/sistema/pacientes/update/${item.copeId}` },
                        show: (item) => { return item ? this.getPermiso('editarPacientes') : false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.copeId) },
                        show: (item) => { return item ? this.getPermiso('eliminarPacientes') : false }

                    },
                ]
            },
            filtros: [{
                    campo: "nombre",
                    text: "Nombre",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                },

                {
                    campo: "identificacion",
                    text: "Identificacion",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*/
                }
                /*  {
                              text: "Tipo de identificacion",
                              value: null,
                              tipo: "lista",
                              lista:[],
                              listatext: "comaNombrelargo",
                              listavalue: "comaId"
                          },

                          {
                              text: "Direccion",
                              value: null,
                              tipo: "text",
                              /*si es tipo lista descomentar esta parte*F/
                              /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*F/
                          },*/
            ],
            tipoidentificacion: []
        };
    },
    created: async function () {
        this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);
        await this.search(this.filtros);
    },
    methods: {
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`sistema/personas/buscar/pacientes?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
        async eliminar(id) {
            try {
                let result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    var data = await this.$apiService.delete(`sistema/personas/delete/${id}`).then(data => data);
                    if (data) {
                        await this.search(this.filtros);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
               

        },

    }
};
</script>
