<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text :to="{name:'Formularios'}">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="3" md="3">
                                    <v-text-field outlined dense label="Codigo" :error="$v.form.foseCodigo.$error" v-model="form.foseCodigo" v-on:keyup="$data.form.foseCodigo = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field outlined dense label="Nombre" :error="$v.form.foseNombre.$error" v-model="form.foseNombre" v-on:keyup="$data.form.foseNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="1" md="1">
                                    <v-text-field outlined dense label="Orden" :error="$v.form.foseOrden.$error" v-model="form.foseOrden" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-switch v-model="form.foseHistorico" label="Historico"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-switch v-model="form.foseCalculado" label="Calculado"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-switch v-model="form.foseMultiple" label="Multiple"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-switch v-model="form.foseRequerido" label="Requerido"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined label="Dependencia" v-model="form.foseIdDependencia" :items="depe" item-text="foseNombre" item-value="foseId" item-color="primary" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined dense v-on:keyup="$data.form.foseDescripcion = $event.target.value.toUpperCase()" v-model="form.foseDescripcion" label="Descripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined dense v-model="form.foseScript" label="Script"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-text>
                        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab href="#tab-1">
                                Campos
                                <v-icon>mdi-format-textbox</v-icon>
                            </v-tab>
                            <v-tab href="#tab-2">
                                Cups
                                <v-icon>mdi-format-list-numbered</v-icon>
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-row justify="space-around">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-toolbar>
                                                    <v-toolbar-title>Campos</v-toolbar-title>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <v-btn text class="" @click="agregarCampo">Agregar nuevo</v-btn>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <div class="flex-grow-1"></div>
                                                </v-toolbar>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" v-if="form.campos.length  > 0">
                                        <v-row v-for="(items,i) in form.campos" :key="'campo'+i">
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field outlined dense label="Codigo" :error="$v.form.campos.$each[i].foseCodigo.$error" v-model="items.foseCodigo"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field outlined dense label="Nombre" :error="$v.form.campos.$each[i].foseNombre.$error" v-model="items.foseNombre"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-autocomplete outlined dense :error="$v.form.campos.$each[i].foseTipoTipodato.$error"  v-model="items.foseTipoTipodato" :items="tipocampos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo dato" clearable>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1">
                                                <v-btn text icon color="error" @click="eliminarCampo(items)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1">
                                                <v-text-field outlined dense label="Orden" :error="$v.form.campos.$each[i].foseOrden.$error" v-model="items.foseOrden" number></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1">
                                                <v-text-field outlined dense label="Num. de columnas(1-12)" :error="$v.form.campos.$each[i].foseCol.$error" v-model="items.foseCol" number></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-switch v-model="items.foseRequerido" label="Requerido"></v-switch>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="8">
                                                <v-text-field outlined dense label="Valores" v-model="items.foseValores"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-textarea outlined dense v-model="items.foseDescripcion" label="Descripcion"></v-textarea>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-textarea outlined dense  v-model="items.foseDefault" label="Valor por defecto"></v-textarea>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense v-model="form.foseScript" label="Script"></v-textarea>
                                        </v-col> -->

                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea outlined dense v-model="items.foseConsulta" label="Consulta"></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </v-tab-item>
                            <v-tab-item value="tab-2">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-row justify="space-around">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-toolbar>
                                                    <v-toolbar-title>Cups</v-toolbar-title>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <v-btn text class="" @click="agregartblConfFormulariosservicios">Agregar nuevo</v-btn>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <div class="flex-grow-1"></div>
                                                </v-toolbar>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-row justify="space-around" v-for="(items,i) in form.tblConfFormulariosservicios" :key="'cup'+i">
                                            <v-col cols="12" sm="12" md="8" v-if="items.cups">
                                                <v-autocomplete outlined dense required v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="2">
                                                <v-btn text icon color="error" @click="eliminartblConfFormulariosservicios(items)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>

                </v-card>
            </v-form>
        </v-col>
    </v-row>
</v-container>
</template>

<script>

import {
    required
}
from 'vuelidate/lib/validators';
export default {
    data() {
        return {
            loader: false,
            isNew: true,
            url: "sistema/formulariosecciones/",
            token: this.$cookies.get("token"),
            
            secciones: [],
            servicios: [],
            tab: 'tab-1',
            form: {
                foseCodigo: null,
                foseNombre: null,
                foseIdTipo: null,
                foseIdDependencia: null,
                foseScript: null,
                foseTipoTipodato: null,
                foseOrden: 0,
                foseHistorico: 0,
                foseCalculado: 0,
                foseValores: null,
                foseDefault: null,
                foseDescripcion: null,
                foseRequerido: 0,
                foseCol: 3,
                foseConsulta: null,
                tblConfFormulariosservicios: [],
                campos: []

            },

            tiposecciones: [],
            tipocampos: [],
            depe: []
        };
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        },
    },
    validations: {
        form: {
            foseCodigo: {
                required,
            },
            foseNombre: {
                required,
            },

            foseIdTipo: {
                required,
            },

            foseOrden: {
                required,
            },
            campos: {
                $each: {
                    foseCodigo: {
                        required,
                    },
                    foseNombre: {
                        required,
                    },

                    foseIdTipo: {
                        required,
                    },
                    foseTipoTipodato: {
                        required,
                    },
                    foseCol: {
                        required,
                    },
                    foseOrden: {
                        required,
                    },
                }

            },

        },
    },
    created: async function () {
            this.$store.commit('setCargaDatos', true);
            this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
            this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
            this.depe = await this.$apiService.index(`${this.url}indexsecciones`).then(data => data);
            this.form.foseIdTipo = this.tiposecciones.find(e => e.comaNombrecorto == 'SECCION').comaId;

            if (this.$route.params.id) {
                await this.editar(this.$route.params.id);
            }
            this.$store.commit('setCargaDatos', false);
    },
    methods: {
        async editar(id) {
            try {
                    let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
                    if (data) {

                        this.form = data;
                        if (!this.form.tblConfFormulariosservicios) {
                            this.form.tblConfFormulariosservicios = [];
                        } else {
                            for (let model of this.form.tblConfFormulariosservicios) {
                                model.cups = {
                                    search: null,
                                    isLoading: false,
                                    cups: [model.tblConfCup]
                                };
                            }

                        }
                    }
                    this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async searchCup(cups) {
            cups.isLoading = true;
            if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
                    cups.isLoading = false;
                    cups.cups = data;

                });
            }

        },
        async agregar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                this.$store.commit('setCargaDatos', true);
                try {
                    if (this.isNew) {
                            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
                            if (data) {
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.$router.push("/sistema/formularios");
                            }
                    } else {
                            var id = this.form.foseId;
                            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
                            if (data) {
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.$router.push("/sistema/formularios");
                            }
                    }
                } catch (error) {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
                this.$store.commit('setCargaDatos', false);
            }
        },
        agregarCampo() {
            if (this.form.foseId) {
                this.form.campos.push({
                    foseCodigo: null,
                    foseNombre: null,
                    foseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId,
                    foseScript: null,
                    foseTipoTipodato: null,
                    foseOrden: 0,
                    foseValores: null,
                    foseDefault: null,
                    foseDescripcion: null,
                    foseRequerido: 0,
                    foseCol: 3,
                    foseConsulta: null,

                });
            } else {
                this.form.campos.push({
                    foseCodigo: null,
                    foseNombre: null,
                    foseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId,
                    foseIdDependencia: this.form.foseId,
                    foseScript: null,
                    foseTipoTipodato: null,
                    foseOrden: 0,
                    foseValores: null,
                    foseDefault: null,
                    foseDescripcion: null,
                    foseRequerido: 0,
                    foseCol: 3,
                    foseConsulta: null,

                });
            }
        },
        eliminarCampo(item) {
            this.form.campos = this.form.campos.filter(e => e != item);
        },
        agregartblConfFormulariosservicios() {
            if (this.form.foseId) {
                this.form.tblConfFormulariosservicios.push({
                    foseId: this.form.foseId,
                    cocuId: null,
                    cups: {
                        search: null,
                        isLoading: false,
                        cups: []
                    },
                });
            } else {
                this.form.tblConfFormulariosservicios.push({
                    cocuId: null,
                    cups: {
                        search: null,
                        isLoading: false,
                        cups: []
                    },
                });
            }

        },
        eliminartblConfFormulariosservicios(item) {
            this.form.tblConfFormulariosservicios = this.form.tblConfFormulariosservicios.filter(e => e != item);
        },
    },
}
</script>

<style>

</style>
