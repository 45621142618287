<style>
</style>

<template>
<div>
<v-container v-if="qrUrl != 'iniciado' && qrUrl != '' && !activo" lighten-5>
   <v-img  :src="qrUrl" width="400" height="400"></v-img>   
</v-container>
<v-container v-if="activo" lighten-5>
   chatbot activo  
</v-container>
</div>

</template>

<script>
import QRCode from 'qrcode'
export default {
     name: 'chatbot',
    data() {
        return {
            url: "chats/chatbot/",
            token: this.$cookies.get("token"),
             user: this.$cookies.get("user"),
            qr:"",
            qrUrl:"",
            activo:false
        };
    },
    sockets: {
        sendqr: function (data) {
            console.log(data)
            QRCode.toDataURL(data).then(url => {
                this.qrUrl =url
            })
            .catch(err => {
                console.error(err)
            });
        },
        chatbotactivo: function (data) {
            this.activo = data;
        }
    },
    mounted: async function () {
        this.$store.commit("setCargaDatos", true);
        await this.$apiService.create(this.url + "qr",{username:this.user.username}).then(data => data);
        
        this.$store.commit("setCargaDatos", false);
    },
    methods: {
       
    }
};
</script>
