<style>
</style>

<template>
    <v-container lighten-5 >
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="coreId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Reportes</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">agregar</v-btn>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                ID <span class="primary--text">
                                                    {{ item.coreId}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="10" md="10" lg="10">
                                                NOMBRE <span class="green--text"> {{item.coreNombre}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span class="green--text"> {{item.coreEstado}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ROLES <span class="green--text"> {{item.coreRoles}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                       <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="success" class="mr-2" v-on="on" @click.stop="dialog2 = true" :to="`/sistema/reportes/view/${item.coreId}`">
                                                    <v-icon>mdi-table-search</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Consultar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="purple" class="mr-2" v-on="on" @click.stop="dialog2 = true" @click="editar(item.coreId)">
                                                    <v-icon>mdi-file-chart</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ejecutar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.coreId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small class="mr-2" color="error" v-on="on" @click="eliminar(item.coreId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Nombre" :error="$v.form.coreNombre.$error" v-model="form.coreNombre" v-on:keyup="$data.form.coreNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined label="Estado" :error="$v.form.coreEstado.$error" v-model="form.coreEstado" :items="est" item-text="etext" item-value="eId" item-color="primary"></v-autocomplete>
                                </v-col>
                                 <v-col cols="12" sm="4" md="4">
                                    <v-select v-model="form.coreRoles" dense :items="perfiles" multiple chips small-chips item-text="description"  outlined item-value="name" label="perfiles" clearable></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="cmOptions != null">
                                    <codemirror ref="myCm" :value="form.coreConsulta" :options="cmOptions" @input="onCmCodeChange">
                                    </codemirror>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-toolbar>
                                                <v-toolbar-title>Parametros</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn text class @click="agregartblConfReportesparametros">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-row justify="space-around" v-for="(items,i) in form.tblConfReportesparametros" :key="items.coseId">
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined label="Label" :error="$v.form.tblConfReportesparametros.$each[i].corpLabel.$error" v-model="items.corpLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-text-field outlined label="Nombre" :error="$v.form.tblConfReportesparametros.$each[i].corpNombre.$error" v-model="items.corpNombre"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-autocomplete outlined :error="$v.form.tblConfReportesparametros.$each[i].corpTipoParametro.$error" v-model="items.corpTipoParametro" :items="tipoparametros" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined label="Valores" v-model="items.corpValor"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="10" md="10">
                                            <v-textarea outlined v-model="items.corpConsulta" label="Consulta"></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2">
                                            <v-btn text icon color="error" @click="eliminartblConfReportesparametros(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
        <v-form @submit.prevent="ejecutar">
            <v-dialog v-model="dialog2" persistent width="1000px" max-width="1000px">
                <v-card v-if="form">
                    <v-card-title>
                        <span class="headline">{{ form.coreNombre }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container v-if="!dialog">
                            <v-row v-if="form.tblConfReportesparametros.length >0">
                                <v-col cols="12" sm="12" md="4" v-for="(items,i) in form.tblConfReportesparametros" :key="i">
                                    <label :for="items.corpId+items.corpNombre">{{items.corpLabel}}</label>
                                    <template v-if="items.tipo.comaNombrecorto == 'TEXT'">
                                        <v-text-field :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-text-field>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'TEXTAREA'">
                                        <v-textarea :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-textarea>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'LISTA'">
                                        <v-autocomplete v-if="items.corpConsulta && listasParametros.length>0" :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="listasParametros.find(e => e.corpId ==items.corpId).lista" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                                        <v-autocomplete v-else :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="getValues(items.corpValor)" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>

                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'SI/NO'">
                                        <v-switch :id="items.corpId+items.corpNombre" v-model="items.Valor" :true-value="items.corpValor.split(';')[0]" :false-value="items.corpValor.split(';')[1]"></v-switch>
                                    </template>
                                    <template v-if="items.tipo.comaNombrecorto == 'FECHA'">
                                        <v-menu v-model="items.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field required outlined v-model="items.Valor" append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES" v-model="items.Valor" @input="items.menu = false"></v-date-picker>
                                        </v-menu>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" sm="12" md="12">
                                    <v-alert text prominent dense type="info">
                                        Este reporte no cuenta con parametros, puedes generarlo.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" :loading="loader" dark @click="ejecutarExcel">Generar excel</v-btn>
                        <v-btn color="primary darken-1" :loading="loader" dark @click="ejecutar">Generar cvs</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>

<script>
import {
  required
} from "vuelidate/lib/validators";
import "codemirror/mode/sql/sql.js";
import "codemirror/theme/base16-dark.css";
// active-line.js
import "codemirror/addon/selection/active-line.js";
// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";
// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";
// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

import {
  saveAs
} from "file-saver";
import converter from "json-2-csv";

export default {
  data() {
    return {
      dialog: false,
      dialog2: false,
      loader: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      isNew: true,

      url: "reporte/reportes/",
      token: this.$cookies.get("token"),
      user: this.$cookies.get("user"),

      listasParametros: [],
      cmOptions: typeof window !== "undefined" ? {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: false,
        line: true,

        //styleSelectedText: true,
        mode: "text/x-mysql",
        keyMap: "sublime",
        showCursorWhenSelecting: true,
        theme: "base16-dark",
        extraKeys: {
          Ctrl: "autocomplete"
        },
        hintOptions: {
          completeSingle: true
        }
      } : null,
      form: {
        coreId: null,
        coreRoles:null,
        coreNombre: null,
        coreEstado: null,
        coreConsulta: "",
        tblConfReportesparametros: []
      },
      filtros: [{
          text: "Nombre",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
        },
        {
          text: "Nombre",
          value: null,
          tipo: "lista",
          search: true,
          isLoading: false,
          lista: [{
              eId: "VISIBLE",
              etext: "VISIBLE"
            },
            {
              eId: "OCULTO",
              etext: "OCULTO"
            }
          ],
          listatext: "etext",
          listavalue: "eId"
        },
        {    
            campo: "role",
            text: "role",
            value: null,
            tipo: "lista",
            lista: [],
            listatext: "description",
            listavalue: "name"
        }
      ],
      est: [{
          eId: "VISIBLE",
          etext: "VISIBLE"
        },
        {
          eId: "OCULTO",
          etext: "OCULTO"
        }
      ],
      lista: [],
      listaCopia: [],
      tipoparametros: [],
      perfiles:[]
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  validations: {
    form: {
      coreNombre: {
        required
      },
      coreEstado: {
        required
      },
      coreConsulta: {
        required
      },

      tblConfReportesparametros: {
        $each: {
          corpNombre: {
            required
          },
          corpLabel: {
            required
          },
          corpTipoParametro: {
            required
          }
        }
      }
    }
  },
  created: async function() {
    this.$store.commit("setCargaDatos", true);
    await this.buscar(this.filtros);
    this.tipoparametros = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    this.filtros[2].lista = this.perfiles = await this.$apiService.index("sistema/perfiles/index/1").then(data => data);
    this.$store.commit("setCargaDatos", false);
  },

  methods: {
    onCmCodeChange(newCode) {
      this.form.coreConsulta = newCode;
    },
    getValues(values) {
      const lista = [];

      var datos = values.split("|");
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(";")[0],
          values: d.split(";")[1]
        });
      }
      return lista;
    },
    async search(searchP) {
      this.$store.commit("setCargaDatos", true);
      
      const data = await this.$apiService.index(`${this.url}index?${searchP}`).then(data => data);
      this.lista = this.listaCopia = data.rows;
      this.Count = data.count;
      this.pageCount = Math.ceil(data.count / 10);
      this.$store.commit("setCargaDatos", false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page - 1) * 10}&`;

      if (
        this.filtros[0].value != null &&
        this.filtros[0].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `coreNombre=${this.filtros[0].value}&`;
      }
      if (
        this.filtros[1].value != null &&
        this.filtros[1].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `coreEstado=${this.filtros[1].value}&`;
      }
      if (
        this.filtros[2].value != null &&
        this.filtros[2].value.toString().replace(/ /g, "") != ""
      ) {
         if (!(this.user.role.indexOf('ADMIN') > -1 )) {
            search = search + `coreRoles=${this.user.role}&`;
          }else{
            search = search + `coreRoles=${this.filtros[2].value}&`;
          }
        
      }
     

      if (search != "") {
        await this.search(search);
      }
    },

    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          this.form.coreRoles = this.form.coreRoles.join(',');
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.coreId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.close();
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.buscar(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }

        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async editar(id) {
      try {
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            if(this.form.coreRoles){
                this.form.coreRoles = this.form.coreRoles.split(',');
            }
            
            if (!this.form.tblConfReportesparametros) {
              this.form.tblConfReportesparametros = [];
            } else {
              this.form.tblConfReportesparametros.forEach(async o => {
                o.Valor = null;
                o.menu = false;
                if (o.corpConsulta) {
                  let lista = await this.$apiService.create( this.url + "consultar/", { consulta: o.corpConsulta },  this.token ).then(data => data);
                  this.listasParametros.push({
                    corpId: o.corpId,
                    lista: lista
                  });
                }
              });
            }
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    agregartblConfReportesparametros() {
      if (this.form.coreId) {
        this.form.tblConfReportesparametros.push({
          corpNombre: null,
          corpTipoParametro: null,
          corpValor: null,
          corpLabel: null,
          corpConsulta: null,
          coreId: this.form.coreId
        });
      } else {
        this.form.tblConfReportesparametros.push({
          corpNombre: null,
          corpTipoParametro: null,
          corpValor: null,
          corpLabel: null,
          corpConsulta: null
        });
      }
    },
    eliminartblConfReportesparametros(item) {
      this.form.tblConfReportesparametros = this.form.tblConfReportesparametros.filter(
        e =>
        this.form.tblConfReportesparametros.indexOf(e) !=
        this.form.tblConfReportesparametros.indexOf(item)
      );
    },
    async ejecutar() {
      this.loader = true;
      let valido = true;
     /* this.form.tblConfReportesparametros.forEach(o => {
        if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
          valido = false;
        }
      });*/
      if (valido) {
        try {
            let data = await this.$apiService
              .create(this.url + "ejecutar/", this.form)
              .then(data => data);
            if (data) {
              const csv = await converter.json2csvAsync(data, {
                prependHeader: true,
                emptyFieldValue:true,
                delimiter: {
                  field: ',',
                },
              });
              var blob = new Blob([csv], {
                type: "text/plain;charset=utf-8"
              });
              saveAs(blob, this.form.coreNombre + ".txt");
            }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
    async ejecutarExcel() {
      this.loader = true;
      let valido = true;
      /*this.form.tblConfReportesparametros.forEach(o => {
        if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
          valido = false;
        }
      });*/
      if (valido) {
        try {
            var reporte = await this.$apiService
              .create(this.url + "ejecutar/", this.form)
              .then(data => data);
            import("@/vendor/Export2Excel").then(excel => {
              let head = [{sheet:"Page1",data:Object.keys(reporte[0])}];
              let data = [{sheet:"Page1",data:reporte.map(item => {
                return Object.values(item)
              })}];
              excel.export_json_to_excel({
                header: head, //Header Required
                data, //Specific data Required
                filename: this.form.coreNombre, //Optional
                autoWidth: true, //Optional
                bookType: "xlsx" //Optional
              });
            });
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
    limpiar() {
      this.form = {
        coreId: null,
        coreEstado: null,
        coreNombre: null,
        coreConsulta: "",
        tblConfReportesparametros: []
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.dialog2 = false;
      this.limpiar();
    }
  }
};

</script>
