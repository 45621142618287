<style>
</style>

<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" item-key="hiseId" single-expand hide-default-footer sort-desc sort-by="hiseCodigo" class="elevation-1">
                    <template v-slot:header>
                        <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                        <v-toolbar dark color="primary darken-3" class="mb-1">
                            <v-toolbar-title class="white--text">Formularios de historias</v-toolbar-title>
                            <v-btn class="mx-2 primary--text" tile color="white" :to="{name:'Histcreate'}">
                                agregar
                            </v-btn>
                            <v-form @submit.prevent="subida" enctype="multipart/form-data">
                                <v-dialog v-model="dialogmasivo" persistent width="500">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="red lighten-2" dark v-on="on">
                                            Carga masiva
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="headline grey lighten-2" primary-title>
                                            Cargar excel
                                        </v-card-title>

                                        <v-card-text>
                                            <v-row v-if="!loader">
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-file-input outlined :rules="nameRules" v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <span class="headline">CARGANDO FORMULARIO....</span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-btn color="primary" text target="_blank" :href="plantilla">
                                                Descargar formato
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                                cancelar
                                            </v-btn>

                                            <v-btn color="primary" v-if="!loader" text @click="subida">
                                                cargar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-form>
                            <v-btn v-if="filtros[2].value" class="mx-2 primary--text" tile color="white" :to="`/sistema/historias/updatenuevo/${filtros[2].value}`">
                                Editar historia
                            </v-btn>
                            <v-btn v-if="filtros[2].value" class="mx-2 primary--text" tile color="white" @click="generarExcelPlantilla()">
                                Generar cargue
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab color="white" class="primary--text" :loading="LoadingExp" small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="exportar">
                                        <v-list-item-title>Exportar</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>

                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-row align="center" justify="center">
                            <v-col v-for="item in items" :key="item.hiseId" cols="12" sm="12" md="10" lg="10">
                                <v-card>
                                    <v-card-title class="subtitle-1 font-weight-bold">
                                        <v-row no-gutters>
                                            <v-col cols="9" sm="9" md="9" lg="9">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        CODIGO <span class="primary--text">
                                                            {{ item.hiseCodigo }}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" lg="12">
                                                        NOMBRE <span class="primary--text">
                                                            {{ item.hiseNombre}}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        ORDEN <span class="primary--text">
                                                            {{ item.hiseOrden }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="3" sm="3" md="3" lg="3">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined small class="mr-2" color="purple" v-on="on" @click="pdf(item.hiseId)">
                                                            <v-icon>mdi-file-pdf-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Pre vista</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <!-- @click.stop="dialog = true" @click="editar(item.hiseId)" -->
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true;form.hiseIdDependencia =item.hiseId;form.hiseIdTipo =tiposecciones.find(e => e.comaNombrecorto == 'CAMPO').comaId">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Agregar campo</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <!-- @click.stop="dialog = true" @click="editar(item.hiseId)" -->

                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined color="info" class="mr-2" v-on="on" small :to="{name:'Histupdate',params:{id:item.hiseId}}">
                                                            <v-icon>create</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined small color="error" v-on="on" @click="eliminar(item.hiseId)">
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                HISTORICO <label class="green--text" dark v-if="item.hiseHistorico">Si</label>
                                                <label class="red--text" dark v-else>No</label>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                CALCULADO <label class="green--text" dark v-if="item.hiseCalculado">Si</label>
                                                <label class="red--text" dark v-else>No</label>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2" lg="2">
                                                ESTADO <label class="green--text" dark v-if="item.hiseEstado">Si</label>
                                                <label class="red--text" dark v-else>No</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-row>
                                                    <v-col cols="1" sm="1" md="1" lg="1">
                                                        <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                            <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="11" sm="11" md="11" lg="11">
                                                        <span class="headline primary--text">CAMPOS {{item.campos.length}}</span>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="isExpanded(item)">
                                        <v-container>
                                            <template v-for="items in item.campos ">
                                                <v-sheet min-height="100%" width="100%" :key="items.cireId">
                                                    <v-row no-gutters class="grey lighten-3 mb-5 py-2 px-2">
                                                        <v-col cols="12" sm="4" md="4" lg="4">
                                                            CODIGO <span class="primary--text">
                                                                {{ items.hiseCodigo }}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="8" md="8" lg="8">
                                                            NOMBRE <span class="primary--text">
                                                                {{ items.hiseNombre}}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            ORDEN <span class="primary--text">
                                                                {{ items.hiseOrden }}
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            REQUERIDO <label class="green--text" dark v-if="items.hiseRequerido">Si</label>
                                                            <label class="red--text" dark v-else>No</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3" lg="3" v-if="items.tipodato">
                                                            TIPO <label class="primary--text" dark>{{(items.tipodato)?items.tipodato.comaNombrecorto:''}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3" lg="3">
                                                            <v-tooltip top>
                                                                <!-- @click.stop="dialog = true" @click="editar(item.hiseId)" -->

                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(items.hiseId)">
                                                                        <v-icon>create</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Editar</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn outlined small color="error" v-on="on" @click="eliminar(items.hiseId)">
                                                                        <v-icon>delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            ESTADO <label class="green--text" dark v-if="items.hiseEstado">Si</label>
                                                            <label class="red--text" dark v-else>No</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            SEXO <label class="primary--text" dark>{{items.hiseSexo}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            EDAD INICIAL <label class="primary--text" dark>{{items.hiseEdadinicio}}</label>
                                                        </v-col>
                                                        <v-col cols="12" sm="2" md="2" lg="2">
                                                            EDAD FINAL <label class="primary--text" dark>{{items.hiseEdadfinal}}</label>
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </template>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template> <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <v-row class="mt-2 pb-5" align="center" justify="center">
                            <span class="mr-4 grey--text">
                                Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                            </span>
                            <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Codigo" :error="$v.form.hiseCodigo.$error" v-model="form.hiseCodigo" v-on:keyup="$data.form.hiseCodigo = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Nombre" :error="$v.form.hiseNombre.$error" v-model="form.hiseNombre" v-on:keyup="$data.form.hiseNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <!-- <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined :rules="nameRules" :error="$v.form.hiseIdTipo.$error" v-model="form.hiseIdTipo" :items="tiposecciones" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo" clearable></v-autocomplete>
                            </v-col> -->
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-autocomplete outlined :rules="nameRules" label="Dependencia" v-model="form.hiseIdDependencia" :items="depe" item-text="hiseNombre" item-value="hiseId" item-color="primary" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-autocomplete outlined :rules="nameRules" v-model="form.hiseTipoTipodato" :items="tipocampos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo dato" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined :rules="nameRules" label="Orden" :error="$v.form.hiseOrden.$error" v-model="form.hiseOrden" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-text-field outlined :rules="nameRules" label="Valores" v-on:keyup="$data.form.hiseValores = $event.target.value.toUpperCase()" v-model="form.hiseValores"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-text-field outlined :rules="nameRules" label="Numero de columnas(1 - 12)" :error="$v.form.hiseCol.$error" v-model="form.hiseCol" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-text-field outlined :rules="nameRules" label="Numero filas TEXTAREA" v-model="form.hiseFilas" number></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined v-on:keyup="$data.form.hiseDescripcion = $event.target.value.toUpperCase()" v-model="form.hiseDescripcion" label="Descripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-textarea outlined v-on:keyup="$data.form.hiseDefault = $event.target.value.toUpperCase()" v-model="form.hiseDefault" label="Valor por defecto"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined v-model="form.hiseScript" label="Script"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='SECCION'">
                                    <v-switch v-model="form.hiseHistorico" label="Historico"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='SECCION'">
                                    <v-switch v-model="form.hiseCalculado" label="Calculado"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='SECCION'">
                                    <v-switch dense v-model="form.hiseTriage" label="Triage"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-switch v-model="form.hiseRequerido" label="Requerido"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Sexo" v-model="form.hiseSexo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Edad inicial" v-model="form.hiseEdadinicio"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Edad final" v-model="form.hiseEdadfinal"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.hiseEstado" label="Estado"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='CAMPO'">
                                    <v-textarea outlined v-model="form.hiseConsulta" label="Consulta"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='SECCION'">
                                    <v-row justify="space-around">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-toolbar>
                                                <v-toolbar-title>Servicios</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn text class @click="agregartblConfHistoriasservicios">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.hiseIdTipo,tiposecciones)=='SECCION'">
                                    <v-row justify="space-around" v-for="items in form.tblConfHistoriasservicios" :key="items.coseId">
                                        <v-col cols="12" sm="12" md="8">
                                            <v-autocomplete outlined :rules="nameRules" v-model="items.coseId" :items="servicios" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2">
                                            <v-btn text icon color="error" @click="eliminartblConfHistoriasservicios(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>

<script>
import plantilla from "@/assets/files/plantilla_historia.xlsx";
import {
  required
} from "vuelidate/lib/validators";
import {
  documentos
}
from "../../utils/documentos";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {

  data() {
    return {
      plantilla: null,
      dialogmasivo: false,
      dialog: false,
      loader: false,
      LoadingExp: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      isNew: true,
      file: null,
      url: "sistema/historiasecciones/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],
      secciones: [],
      servicios: [],
      form: {
        hiseCodigo: null,

        hiseNombre: null,

        hiseIdTipo: null,

        hiseIdDependencia: null,
        hiseScript: null,
        hiseTipoTipodato: null,
        hiseOrden: 0,
        hiseHistorico: 0,
        hiseCalculado: 0,
        hiseValores: null,
        hiseDefault: null,
        hiseDescripcion: null,
        hiseRequerido: 0,
        hiseCol: 3,
        hiseConsulta: null,
        hiseFilas: 4,
        tblConfHistoriasservicios: [],
        hiseEstado: 1,
        hiseSexo: null,
        hiseEdadinicio: null,
        hiseEdadfinal: null,
      },
      filtros: [{
          text: "Codigo",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                listatext: "nombre del campo que se mostrara",
                listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
                defaulttext: "tblConfPersonasnaturales",
                defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                defaultsubtext: "copeIdentificacion",*/
        },
        {
          text: "Nombre",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                listatext: "nombre del campo que se mostrara",
                listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
                defaulttext: "tblConfPersonasnaturales",
                defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                defaultsubtext: "copeIdentificacion",*/
        },
        {
          text: "Servicio",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "coseNombre",
          listavalue: "coseId"
          //para select y items personalizados
          /*  default: true,
                defaulttext: "tblConfPersonasnaturales",
                defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                defaultsubtext: "copeIdentificacion",*/
        }
      ],

      lista: [],
      listaCopia: [],
      tiposecciones: [],
      tipocampos: [],
      depe: []
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  validations: {
    form: {
      hiseCodigo: {
        required
      },
      hiseNombre: {
        required
      },

      hiseIdTipo: {
        required
      },

      hiseOrden: {
        required
      },
      hiseCol: {
        required
      }
    }
  },
  created: async function() {
    this.plantilla = plantilla;
    this.$store.commit("setCargaDatos", true);

    this.filtros[2].lista = this.servicios = await this.$apiService.index("sistema/servicios/index/").then(data => data);
    this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
    this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    this.depe = await this.$apiService.index(`${this.url}indexsecciones`).then(data => data);
    await this.buscar(this.filtros);
    this.$store.commit("setCargaDatos", false);
  },
  methods: {
    onFileChange(file) {
      this.file = file;
    },
    getMaestraName(id, maestra) {
      if (id != null) {
        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaNombrecorto == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaId;
          }
        }
      }
    },
    getMaestra(id, maestra) {
      if (id != null) {
        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrecorto;
          }
        }
      }
    },
    async search(searchP) {
      this.$store.commit("setCargaDatos", true);
      const data = await this.$apiService
        .index(`${this.url}index?${searchP}`)
        .then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit("setCargaDatos", false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page - 1) * 10}&`;

      if (
        this.filtros[0].value != null &&
        this.filtros[0].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `hiseCodigo=${this.filtros[0].value}&`;
      }
      if (
        this.filtros[1].value != null &&
        this.filtros[1].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `hiseNombre=${this.filtros[1].value}&`;
      }
      search = search + `hiseIdTipo=${ this.tiposecciones.find(e => e.comaNombrecorto == 'SECCION').comaId}&`;

      if (
        this.filtros[2].value != null &&
        this.filtros[2].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `coseId=${this.filtros[2].value}&`;
      }
      if (search != "") {
        await this.search(search);
      }
    },
    async subida() {
      try {
        if (this.file != null) {
          let formData = new FormData();
          formData.append("file", this.file);
          this.loader = true;
          let data = await this.$apiService
            .createFile(this.url + "masiva", formData)
            .then(data => data);
          if (data) {
            this.loader = false;
            this.file = null;
            this.$store.commit("setCargaDatos", true);
            await this.buscar(this.filtros);
            this.depe = await this.$apiService
              .index(`${this.url}indexsecciones`)
              .then(data => data);

            this.$store.commit("setCargaDatos", false);
            this.$swal.fire({
              title: "Completado!",
              text: "Subida exitosa",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Cuidado!",
            text: "Selecciona un archivo primero",
            type: "warnning",
            confirmButtonText: "Ok"
          });
        }

      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;
    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService
              .create(this.url + "create", this.form)
              .then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          } else {
            var id = this.form.hiseId;
            let data = await this.$apiService
              .update(this.url + "update/" + id + "/", this.form)
              .then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.close();
      }
    },
    async exportar() {
      this.LoadingExp = true;
      try {
        if (
          this.filtros[2].value != null &&
          this.filtros[2].value.toString().replace(/ /g, "") != ""
        ) {
          let secciones = await this.$apiService.index("sistema/historiasecciones/secciones/" + this.filtros[2].value).then(data => data);

          for (let item of secciones) {
            let servicios = await this.$apiService.index("sistema/historiasecciones/servicios/" + item.hiseId).then(data => data);
            item.servicios = servicios;
          }
          import('@/vendor/Export2Excel').then(excel => {
            let data = [{sheet:"Page1",data:[]}];
          let head = [{sheet:"Page1",data: ['ID', 'CODIGO', 'NOMBRE', 'TIPO', 'HISTORICO', 'DEPENDENCIA', 'TIPO_DATO', 'ORDEN', 'VALORES', 'COLUMNAS', 'SERVICIOS', 'CALCULADO', 'DESCRIPCION', 'REQUERIDO', 'DEFAULT', 'SCRIPT', 'CONSULTA', 'FILAS', 'ESTADO', 'SEXO', 'EDADINICIO', 'EDADFINAL', 'VERSION']}];
            for (let item of secciones) {

              data[0].data.push([
                item.hiseId,
                item.hiseCodigo,
                item.hiseNombre,
                'SECCION',
                item.hiseHistorico,
                item.hiseIdDependencia,
                (item.tipodato) ? item.tipodato.comaNombrecorto : '',
                item.hiseOrden,
                item.hiseValores,
                item.hiseCol,
                (item.servicios) ? item.servicios.map((e) => {
                  return e.tblConfServicio.coseNombre
                }).join(';') : '',
                item.hiseCalculado,
                item.hiseDescripcion,
                item.hiseRequerido,
                item.hiseDefault,
                item.hiseScript,
                item.hiseConsulta,
                item.hiseFilas,
                item.hiseEstado,
                item.hiseSexo,
                item.hiseEdadinicio,
                item.hiseEdadfinal,
                item.hiseVersion
              ]);
              for (let itemCampo of item.campos) {
                data[0].data.push([
                  itemCampo.hiseId,
                  itemCampo.hiseCodigo,
                  itemCampo.hiseNombre,
                  'CAMPO',
                  itemCampo.hiseHistorico,
                  itemCampo.hiseIdDependencia,
                  (itemCampo.tipodato) ? itemCampo.tipodato.comaNombrecorto : '',
                  itemCampo.hiseOrden,
                  itemCampo.hiseValores,
                  itemCampo.hiseCol,
                  '',
                  itemCampo.hiseCalculado,
                  itemCampo.hiseDescripcion,
                  itemCampo.hiseRequerido,
                  itemCampo.hiseDefault,
                  itemCampo.hiseScript,
                  itemCampo.hiseConsulta,
                  itemCampo.hiseFilas,
                  itemCampo.hiseEstado,
                  itemCampo.hiseSexo,
                  itemCampo.hiseEdadinicio,
                  itemCampo.hiseEdadfinal,
                  itemCampo.hiseVersion
                ]);
              }
            }
            excel.export_json_to_excel({
              header: head, //Header Required
              data, //Specific data Required
              filename: 'secciones-' + new Date().toLocaleString(), //Optional
              autoWidth: false, //Optional
              bookType: 'xlsx' //Optional
            })
          });
        } else {
          this.$swal.fire({
            title: "Escoja un servicio!",
            text: "Debe filtrar por servicio primero.",
            type: "info"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;

    },
    async generarExcelPlantilla(){
      this.LoadingExp = true;
      try {
        if (
          this.filtros[2].value != null &&
          this.filtros[2].value.toString().replace(/ /g, "") != ""
        ) {
          let secciones = await this.$apiService.index("sistema/historiasecciones/secciones/" + this.filtros[2].value).then(data => data);
          let causas = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCAUSAEXT").then(data => data);
          let tipodx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
          let clasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
          let tipoord = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSORDENES").then(data => data);
          let tipoproc = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS").then(data => data);
          for (let item of secciones) {
            let servicios = await this.$apiService.index("sistema/historiasecciones/servicios/" + item.hiseId).then(data => data);
            item.servicios = servicios
          }
          import('@/vendor/Export2Excel').then(excel => {
            let data = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[]}];
            let head = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[],hidden:true}];
            let multiHeader =[{sheet:"Historias",data:[]}];
            let headerSecciones = ["","","","","","","",""];
            let campos=[];
            let merges = [{sheet:"Historias",data:[]}];
            let hiddens = [];
            let cantidadCols = 7;
            let cantidadRowTipos = 2;
            let mergesList = [];
            let sheets = ["Historias","Diagnosticos","Ordenes","Recetarios","Remisiones","Tipos"];
          
            let dataValidacions = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[]}];
            let colsNum = secciones.reduce(
              (accumulator, currentValue) => accumulator + currentValue.campos.length,
              0
            );
            colsNum = colsNum + 7;
            dataValidacions[0].data.push({columnNum:7,lista:[`$H$3:$H$`+(causas.length + 2)],allowBlank:false});
            dataValidacions[1].data.push({columnNum:3,lista:[`$F$2:$F$`+(tipodx.length + 1)],allowBlank:false});
            dataValidacions[1].data.push({columnNum:4,lista:[`$G$2:$G$`+(clasedx.length + 1)],allowBlank:false});
            dataValidacions[2].data.push({columnNum:3,lista:[`$G$2:$G$`+(tipoord.length + 1)],allowBlank:false});
            dataValidacions[2].data.push({columnNum:4,lista:[`$H$2:$H$`+(tipoproc.length + 1)],allowBlank:false});
            hiddens.push({sheet:"Historias",columns:['H'],rows:[]})
            hiddens.push({sheet:"Diagnosticos",columns:['F','G'],rows:[]})
            hiddens.push({sheet:"Ordenes",columns:['G','H'],rows:[]})
            for (let item of secciones) {
              headerSecciones.push(`${item.hiseNombre}(${item.hiseCodigo})`);
              cantidadCols= cantidadCols+1;
              let star = { r: 1, c: cantidadCols+1 };
              let index = 1;
              for (let itemCampo of item.campos) {
                if(index > 1){
                  headerSecciones.push("");
                  cantidadCols= cantidadCols+1;
                }
                let listaValidacion = [];
                if(itemCampo.tipodato && (itemCampo.tipodato.comaNombrecorto== 'LISTA' )){
                  let datosComentarios = itemCampo.hiseValores.split('|');
                   listaValidacion =datosComentarios.map(item => {
                    return item.replace(',',' ').split(';')[1].trim();
                  });
                }else if(itemCampo.tipodato && ( itemCampo.tipodato.comaNombrecorto== 'SI/NO')){
                   listaValidacion = itemCampo.hiseValores.replace(',',' ').replace(';',',').split(',').map(item => item.trim());
                }
                if(listaValidacion.length > 0){
                  data[5].data.push([(cantidadCols+1)]);
                  let inicialc = cantidadRowTipos;
                  for(let lv of listaValidacion){
                    data[5].data.push([lv]);
                  }
                  cantidadRowTipos = cantidadRowTipos + listaValidacion.length;
                  dataValidacions[0].data.push({columnNum:(cantidadCols+1),lista:[`Tipos!$A$${(inicialc + 1)}:$A$${cantidadRowTipos}`],allowBlank:!itemCampo.hiseRequerido == 1}) 
                  cantidadRowTipos = cantidadRowTipos + 1;
                }
                campos.push(`${itemCampo.hiseNombre}${itemCampo.hiseRequerido == 1? '(*)':''}${(itemCampo.tipodato) ? '('+itemCampo.tipodato.comaNombrecorto+')' : ''}(${itemCampo.hiseCodigo})`);
                index++;
              }
              let end = { r: 1, c: cantidadCols+1 };
              let merge = { s: star, e: end };
              mergesList.push(merge);
            }
            let isOdonto = ['ODONTOLOGÍA', 'HIGIENE ORAL', 'ORTODONCIA'];
            if(isOdonto.some(a => this.filtros[2].lista.find(e => e.coseId == this.filtros[2].value).coseNombre.indexOf(a) > -1)){
              sheets.push("Odontograma y control placa");
              head.push({sheet:"Odontograma y control placa",data:["ITEM","DIENTE","CARA","PLACA","CONVENCION","LISTA CONVENCIONES","LISTA NUMERO DIENTES","LISTA NUMERO CARAS"]});
              dataValidacions.push({sheet:"Odontograma y control placa",data:[]});
              dataValidacions[6].data.push({columnNum:2,lista:[`$G$2:$G$51`],allowBlank:false})
              dataValidacions[6].data.push({columnNum:5,lista:[`$F$2:$F$34`],allowBlank:false})
              dataValidacions[6].data.push({columnNum:3,lista:[`$H$2:$H$9`],allowBlank:false})
              data.push({sheet:"Odontograma y control placa",data:[
                ["","","","0","",'EXODONCIA_REALIZADA',"11","parte1"],
                ["","","","0","",'EXODONCIA_INDICADA',"12","parte2"],
                ["","","","0","",'ENDODONCIA_REALIZADA',"13","parte3"],
                ["","","","0","",'ENDODONCIA_INDICADA',"14","parte4"],
                ["","","","0","",'EXODONCIA_QUIRURGICA_IND',"15","parte5"],
                ["","","","0","",'EXODONCIA_SIMPLE_IND',"16","parte6"],
                ["","","","0","",'PROCEDIMIENTO_REALIZADO',"17","parte7"],
                ["","","","0","",'DIENTE_PROVISIONAL_BUEN_ESTADO',"18",""],
                ["","","","0","",'DIENTE_PROVISIONAL_MAL_ESTADO',"21",""],
                ["","","","0","",'DIENTE_SIN_ERUPCIONAR',"22",""],
                ["","","","0","",'ERUPCION_BUEN_ESTADO',"23",""],
                ["","","","0","",'ERUPCION_MAL_ESTADO',"24",""],
                ["","","","0","",'PROTESIS_BUEN_ESTADO',"25",""],
                ["","","","0","",'PROTESIS_MAL_ESTADO',"26",""],
                ["","","","0","",'PROTESIS_FIJA_BUEN_ESTADO',"27",""],
                ["","","","0","",'PROTESIS_FIJA_MAL_ESTADO',"28",""],
                ["","","","0","",'DIENTE_SANO',"31",""],
                ["","","","0","",'CORONA_BUEN_ESTADO',"32",""],
                ["","","","0","",'CORONA_MAL_ESTADO',"33",""],
                ["","","","0","",'NUCLEO_BUEN_ESTADO',"34",""],
                ["","","","0","",'NUCLEO_MAL_ESTADO',"35",""],
                ["","","","0","",'SELLANTE_INDICADO',"36",""],
                ["","","","0","",'SELLANTE_PRESENTE',"37",""],
                ["","","","0","",'EROCION_ARRIBA',"38",""],
                ["","","","0","",'EROCION_DOBLE',"41",""],
                ["","","","0","",'EROCION_ABAJO',"42",""],
                ["","","","0","",'FRACTURADO',"43",""],
                ["","","","0","",'CARIADO',"44",""],
                ["","","","0","",'OBTURADO',"45",""],
                ["","","","0","",'RESINA',"46",""],
                ["","","","0","",'VIDRIO',"47",""],
                ["","","","0","",'TEMPORAL',"48",""],
                ["","","","0","",'RESINA_RECURRENTE',"51",""],
                ["","","","0","",'OBTURADO_RECURRENTE',"52",""],
                ["","","","0","",'',"53",""],
                ["","","","0","",'',"54",""],
                ["","","","0","",'',"55",""],
                ["","","","0","",'',"61",""],
                ["","","","0","",'',"62",""],
                ["","","","0","",'',"63",""],
                ["","","","0","",'',"64",""],
                ["","","","0","",'',"65",""],
                ["","","","0","",'',"71",""],
                ["","","","0","",'',"72",""],
                ["","","","0","",'',"73",""],
                ["","","","0","",'',"74",""],
                ["","","","0","",'',"75",""],
                ["","","","0","",'',"81",""],
                ["","","","0","",'',"82",""],
                ["","","","0","",'',"83",""],
                ["","","","0","",'',"84",""],
                ["","","","0","",'',"85",""]
              ]});
              hiddens.push({sheet:"Odontograma y control placa",columns:['H','F','G'],rows:[]})
            }
            multiHeader[0].data = [headerSecciones];
            head[0].data =["ITEM","PROFECIONAL","PACIENTE","FECHA","HORA ATENCION","HORA FIN ATENCION","CAUSA EXTERNA","TIPOS CAUSA EXTERNA",...campos];
            head[1].data = ["ITEM","CIE10","TIPO","CLASE","OBSERVACION","TIPOS DX","CLASE DX"];
            head[2].data = ["ITEM","CUPS","TIPO DE ORDEN","TIPO DE PROCEDIMIENTO","CANTIDAD","OBSERVACIONES","TIPOS ORDEN","TIPOS PROC"];
            head[3].data = ["ITEM","MEDICAMENTO","FRECUENCIA DE HORAS","DIAS","DOSIS","CANTIDAD","DETALLE"];
            head[4].data = ["ITEM","CUPS","OBSERVACIONES"];
            head[5].data = ["TIPOS"]
            merges[0].data = mergesList;
            for (let i = 0; i <= 50; i++) {
              data[0].data.push(new Array(colsNum)); 
              for (let y = 0; y <= colsNum; y++) {
                if(y==7 && causas[i]){
                  data[0].data[i][y]=causas[i].comaNombrelargo;
                }else{
                  data[0].data[i][y]="";
                }
              }
              data[1].data.push(new Array(7)); 
              for (let y = 0; y <= 7; y++) {
                if(y==5 && tipodx[i]){
                  data[1].data[i][y]=tipodx[i].comaNombrelargo;
                }else if(y==6 && clasedx[i]){
                  data[1].data[i][y]=clasedx[i].comaNombrelargo;
                }else {
                  data[1].data[i][y]="";
                }
              }
              data[2].data.push(new Array(8)); 
              for (let y = 0; y < 8; y++) {
                if(y==6 && tipoord[i]){
                  data[2].data[i][y]=tipoord[i].comaNombrelargo;
                }else if(y==7 && tipoproc[i]){
                  data[2].data[i][y]=tipoproc[i].comaNombrelargo;
                }else{
                  data[2].data[i][y]="";
                }
              }
            }
            excel.export_json_to_excel({
              multiHeader:multiHeader,
              header: head, //Header Required
              data, //Specific data Required
              filename: this.filtros[2].lista.find(e => e.coseId == this.filtros[2].value).coseNombre + '-plantilla-' + new Date().toLocaleString(), //Optional
              autoWidth: true, //Optional
              bookType: 'xlsx', //Optional
              merges:merges,
              dataValidations:dataValidacions,
              sheets,
              hiddens
            })
          });
        } else {
          this.$swal.fire({
            title: "Escoja un servicio!",
            text: "Debe filtrar por servicio primero.",
            type: "info"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;
    },
    async eliminar(id) {
      try {
        var result = await this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          })
          .then(result => result.value);
        if (result) {
          let data = await this.$apiService
            .delete(this.url + "delete/" + id + "/")
            .then(data => data);
          if (data) {
            await this.buscar(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async editar(id) {
      try {
        let data = await this.$apiService
          .index(this.url + "view/" + id)
          .then(data => data);
        if (data) {
          this.form = data;
          if (!this.form.tblConfHistoriasservicios) {
            this.form.tblConfHistoriasservicios = [];
          }
        }

        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    agregartblConfHistoriasservicios() {
      if (this.form.hiseId) {
        this.form.tblConfHistoriasservicios.push({
          hiseId: this.form.hiseId,
          coseId: null
        });
      } else {
        this.form.tblConfHistoriasservicios.push({
          coseId: null
        });
      }
    },
    eliminartblConfHistoriasservicios(item) {
      this.form.tblConfHistoriasservicios = this.form.tblConfHistoriasservicios.filter(
        e =>
        this.form.tblConfHistoriasservicios.indexOf(e) !=
        this.form.tblConfHistoriasservicios.indexOf(item)
      );
    },
    async pdf(id) {
      if (typeof window !== 'undefined') {
        let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("version"));
        let pdf = new jsPDF();
        let seccion = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        documento.getPrevista(pdf, seccion);
      }
    },
    limpiar() {
      this.form = {
        hiseCodigo: null,

        hiseNombre: null,

        hiseIdTipo: null,

        hiseIdDependencia: null,
        hiseScript: null,
        hiseTipoTipodato: null,
        hiseOrden: 0,
        hiseHistorico: 0,
        hiseCalculado: 0,
        hiseValores: null,
        hiseDefault: null,
        hiseDescripcion: null,
        hiseRequerido: 0,
        hiseCol: 3,
        hiseFilas: 4,
        hiseConsulta: null,
        hiseEstado: 1,
        tblConfHistoriasservicios: [],
        hiseSexo: null,
        hiseEdadinicio: null,
        hiseEdadfinal: null,
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
