<style>
</style>

<template>
    <v-container >
        <v-row>
            <v-col cols="12" sm="12" md="12" v-if="form">
                <span class="mx-2 secondary--text display-2 float-left">{{form.coreNombre}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <template v-if="form">
                    <v-row v-if="form.tblConfReportesparametros.length >0">
                        <v-col cols="12" sm="12" md="4" v-for="(items,i) in form.tblConfReportesparametros" :key="i">
                            <label :for="items.corpId+items.corpNombre">{{items.corpLabel}}</label>
                            <template v-if="items.tipo.comaNombrecorto == 'TEXT'">
                                <v-text-field dense :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-text-field>
                            </template>
                            <template v-if="items.tipo.comaNombrecorto == 'TEXTAREA'">
                                <v-textarea dense :id="items.corpId+items.corpNombre" outlined v-model="items.Valor"></v-textarea>
                            </template>
                            <template v-if="items.tipo.comaNombrecorto == 'LISTA'">
                                <v-autocomplete dense v-if="items.corpConsulta && listasParametros.length>0" :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="listasParametros.find(e => e.corpId ==items.corpId).lista" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                                <v-autocomplete dense v-else-if="items.corpValor" :id="items.corpId+items.corpNombre" outlined v-model="items.Valor" :items="getValues(items.corpValor)" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                            </template>
                            <template v-if="items.tipo.comaNombrecorto == 'SI/NO'">
                                <v-switch dense :id="items.corpId+items.corpNombre" v-model="items.Valor" :true-value="items.corpValor.split(';')[0]" :false-value="items.corpValor.split(';')[1]"></v-switch>
                            </template>
                            <template v-if="items.tipo.comaNombrecorto == 'FECHA'">
                                <v-menu v-model="items.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense required outlined v-model="items.Valor" append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="items.Valor" @input="items.menu = false"></v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" sm="12" md="12">
                            <v-alert text prominent dense type="info">
                                Este reporte no cuenta con parametros, puedes generarlo.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <v-btn class="mx-2 float-left" fab small dark color="accent" @click="$router.go(-1)">
                    <v-icon dark>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn class="mx-2 float-right" color="primary darken-1" :loading="loader" fab small dark @click="ejecutarExcel">
                  <v-icon dark>mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-btn class="mx-2 float-right" color="primary darken-1" :loading="loader" fab small dark @click="ejecutar">
                  <v-icon dark>mdi-file-delimited</v-icon>
                </v-btn>
                <v-btn class="mx-2 float-right" fab small dark color="accent" :loading="loader" @click="consultar">
                    <v-icon dark>mdi-magnify</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="lista.length > 0">
                <v-data-table :headers="headers" dense :items="lista" class="elevation-1">
                    <template v-slot:no-data>
                        <label class="primary--text">No hay datos disponibles</label>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {
  saveAs
} from "file-saver";
import converter from "json-2-csv";

export default {
  data() {
    return {
      url: "reporte/reportes/",
      token: this.$cookies.get("token"),
      user: this.$cookies.get("user"),
      listasParametros: [],
      form: null,
      lista: [],
      listaCopia: [],
      tipoparametros: [],
      headers: [],
      loader:false
    };
  },
  created: async function() {
    this.$store.commit("setCargaDatos", true);
    this.editar(this.$route.params.id)
    this.$store.commit("setCargaDatos", false);
  },
  methods: {
    getValues(values) {
      const lista = [];
      var datos = values.split("|");
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(";")[0],
          values: d.split(";")[1]
        });
      }
      return lista;
    },
    async search() {
      this.loader = true;
      this.lista = await this.$apiService.create(this.url + "ejecutar/", this.form).then(data => data);
      if (this.lista.length) {
        this.headers = Object.keys(this.lista[0]).map(elemnt => {
          return {
                    text: elemnt,
                    value: elemnt
                }
        });
      }
      this.loader = false;
    },
    async consultar() {
      await this.search();
    },
    async editar(id) {
      try {
        let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (data) {
          this.form = data;
          if (this.form.coreRoles) {
            this.form.coreRoles = this.form.coreRoles.split(',');
          }

          if (!this.form.tblConfReportesparametros) {
            this.form.tblConfReportesparametros = [];
          } else {
            this.form.tblConfReportesparametros.forEach(async o => {
              o.Valor = null;
              o.menu = false;
              if (o.corpConsulta) {
                let lista = await this.$apiService.create(this.url + "consultar/", {
                  consulta: o.corpConsulta
                }, this.token).then(data => data);
                this.listasParametros.push({
                  corpId: o.corpId,
                  lista: lista
                });
              }
            });
          }
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async ejecutar() {
      this.loader = true;
      let valido = true;
      /* this.form.tblConfReportesparametros.forEach(o => {
         if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
           valido = false;
         }
       });*/
      if (valido) {
        try {
          let data = await this.$apiService.create(this.url + "ejecutar/", this.form).then(data => data);
          if (data) {
            const csv = await converter.json2csvAsync(data, {
              prependHeader: true,
              emptyFieldValue:true,
                delimiter: {
                  field: ',',
                },
            });
            var blob = new Blob([csv], {
              type: "text/plain;charset=utf-8"
            });
            saveAs(blob, this.form.coreNombre + ".txt");
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
    async ejecutarExcel() {
      this.loader = true;
      let valido = true;
      /*this.form.tblConfReportesparametros.forEach(o => {
        if (o.Valor == null || o.Valor.toString().replace(/ /g, "") == "") {
          valido = false;
        }
      });*/
      if (valido) {
        try {
          var reporte = await this.$apiService.create(this.url + "ejecutar/", this.form).then(data => data);
          import("@/vendor/Export2Excel").then(excel => {
            let head = [{sheet:"Page1",data:Object.keys(reporte[0])}];
            let data = [{sheet:"Page1",data:reporte.map(item => {
              return Object.values(item)
            })}];
            excel.export_json_to_excel({
              header: head, //Header Required
              data, //Specific data Required
              filename: this.form.coreNombre, //Optional
              autoWidth: true, //Optional
              bookType: "xlsx" //Optional
            });
          });
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
      this.loader = false;
    },
  }
};

</script>
