<style>
</style>
<template>
    <div>
        <v-container lighten-5>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <span class="mx-2 secondary--text display-2 float-left">Formularios de encuestas</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                        <v-icon dark>filter_list</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="tabla">
                    <v-card elevation="0" class="rounded-xl">
                        <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="true">
                            <template v-slot:header>
                                <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                                <v-toolbar dark class="mb-1">
                                   <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" v-if="getPermiso('crearConfiguracionEncuesta')">
                                    agregar
                                </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:default="{ items, isExpanded, expand }">
                                <v-container class="pt-0">
                                    <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                        <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                            <v-card elevation="0" class="rounded-xl">
                                                <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                    <v-row no-gutters>
                                                        <v-col cols="10" sm="10" md="10" lg="10">
                                                            <v-row no-gutters>
                                                                <template v-for="(p,i) in tabla.primarios">
                                                                    <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(item) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="2" sm="2" md="2" lg="2">
                                                            <v-menu bottom left>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <template v-for="(m, i) in tabla.menu">
                                                                        <template v-if="m.show(item)">
                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                            <v-list-item v-else :key="i" @click="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </template>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.segundarios">
                                                            <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                        <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                            <v-row>
                                                                <v-col cols="2" sm="2" md="1" lg="1">
                                                                    <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                        <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col cols="10" sm="10" md="11" lg="11">
                                                                    <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <template v-if="tabla.expandible">
                                                    <v-divider v-if="isExpanded(item)"></v-divider>
                                                    <v-card-text v-if="isExpanded(item)">
                                                        <v-container>
                                                            <template v-for="items in tabla.expandibleItems(item) ">
                                                                <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                    <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                                        <template v-for="(p,i) in tabla.expanItem">
                                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                                {{p.titulo}} <span :class="p.class">
                                                                                    {{ p.valor(items) }}
                                                                                </span>
                                                                            </v-col>
                                                                        </template>
                                                                        <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                            <v-menu bottom left>
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <v-list>
                                                                                    <template v-for="(m, i) in tabla.expanMenu">
                                                                                        <template v-if="m.show(items)">
                                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                            <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                        </template>
                                                                                    </template>
                                                                                </v-list>
                                                                            </v-menu>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-sheet>
                                                            </template>
                                                        </v-container>
                                                    </v-card-text>
                                                </template>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:no-data>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="10" sm="10" md="10" lg="10">
                                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                            No se encontro nada.
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:loading>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <Loader />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:footer>
                                <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                            </template>
                        </v-data-iterator>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined v-model="form.coenNombre" :error="$v.form.coenNombre.$error" label="Nombre"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.coenTipoEstado" label="Estado"></v-switch>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </div>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  required
} from "vuelidate/lib/validators";
export default {
  mixins: [generales],
  data() {
    return {
      dialog: false,
      loader: false,
      LoadingExp: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      isNew: true,
      file: null,
      url: "sistema/encuestas/",
      form: {
        coenNombre: null,
        coenFecha: new Date().yyyymmdd(),
        coenTipoEstado: 1
      },
      tabla: {
        itemKey: 'coenId',
        orderBy: 'coenFecha',
        expandible: false,
        expandibleKey: 'coenId',
        expandibleTitle: () => {
          return ``
        },
        expandibleItems: () => {
          return []
        },
        primarios: [{
            titulo: 'NOMBRE',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return `${item.coenNombre}`
            },
            show: (item) => {
              return item ? true : false
            }
          }, {
            titulo: 'FECHA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'green--text',
            valor: (item) => {
              return item.coenFecha
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'ESTADO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.coenTipoEstado ? 'ACTIVO' : 'INACTIVO'
            },
            show: (item) => {
              return item ? true : false
            }
          },
        ],
        segundarios: [],
        expanItem: [],
        expanMenu: [],
        menu: [{
            title: 'Editar encuesta',
            icon: 'mdi-ballot-outline',
            color: 'success',
            tipeAction: 'link',
            action: (item) => {
              return '/sistema/encuestas/updatenuevo/' + item.coenId
            },
            show: (item) => {
              return item ? this.getPermiso('editarConfiguracionEncuesta') : false
            }
          },
          {
            title: 'Exportar',
            icon: 'mdi-database-export',
            color: 'primary',
            tipeAction: 'funcion',
            action: (item) => {
              return this.exportar(item.coenId)
            },
            show: (item) => {
              return item ? this.getPermiso('verConfiguracionEncuesta') : false
            }
          },
          {
            title: 'Editar',
            icon: 'create',
            color: 'info',
            tipeAction: 'funcion',
            action: (item) => {
              this.editar(item.coenId)
            },
            show: (item) => {
              return item ? this.getPermiso('editarConfiguracionEncuesta') : false
            }
          },
          {
            title: 'Eliminar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => {
              this.eliminar(item.coenId)
            },
            show: (item) => {
              return item ? this.getPermiso('eliminarConfiguracionEncuesta') : false
            }
          }
        ]
      },
      filtros: [{
        campo: "coenNombre",
        text: "Nombre",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
              listatext: "nombre del campo que se mostrara",
              listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
              defaulttext: "tblConfPersonasnaturales",
              defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
              defaultsubtext: "copeIdentificacion",*/
      }, ],
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  validations: {
    form: {
      coenNombre: {
        required
      },
    }
  },
  created: async function() {
    await this.search(this.filtros);
  },
  methods: {
    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      const data = await this.$apiService.index(`sistema/encuestas/buscar/?${this.filtrar(filtros,this.page)}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          } else {
            var id = this.form.coenId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.close();
      }
    },
    async exportar(id) {
      this.LoadingExp = true;
      try {
        if (
          this.filtros[2].value != null &&
          this.filtros[2].value.toString().replace(/ /g, "") != ""
        ) {
          let secciones = await this.$apiService.index("sistema/historiasecciones/secciones/" + id).then(data => data);

          for (let item of secciones) {
            let encuestas = await this.$apiService.index("sistema/historiasecciones/encuestas/" + item.ecseId).then(data => data);
            item.encuestas = encuestas;
          }
          import('@/vendor/Export2Excel').then(excel => {
            let data = [];
            let head = ['ID', 'CODIGO', 'NOMBRE', 'TIPO', 'HISTORICO', 'DEPENDENCIA', 'TIPO_DATO', 'ORDEN', 'VALORES', 'COLUMNAS', 'SERVICIOS', 'CALCULADO', 'DESCRIPCION', 'REQUERIDO', 'DEFAULT', 'SCRIPT', 'CONSULTA', 'FILAS', 'ESTADO', 'SEXO', 'EDADINICIO', 'EDADFINAL', 'VERSION'];
            for (let item of secciones) {

              data.push([
                item.ecseId,
                item.ecseCodigo,
                item.ecseNombre,
                'SECCION',
                item.ecseHistorico,
                item.ecseIdDependencia,
                (item.tipodato) ? item.tipodato.comaNombrecorto : '',
                item.ecseOrden,
                item.ecseValores,
                item.ecseCol,
                (item.encuestas) ? item.encuestas.map((e) => {
                  return e.coenNombre
                }).join(';') : '',
                item.ecseCalculado,
                item.ecseDescripcion,
                item.ecseRequerido,
                item.ecseDefault,
                item.ecseScript,
                item.ecseConsulta,
                item.ecseFilas,
                item.ecseEstado,
                item.ecseSexo,
                item.ecseEdadinicio,
                item.ecseEdadfinal,
                item.ecseVersion
              ]);
              for (let itemCampo of item.campos) {
                data.push([
                  itemCampo.ecseId,
                  itemCampo.ecseCodigo,
                  itemCampo.ecseNombre,
                  'CAMPO',
                  itemCampo.ecseHistorico,
                  itemCampo.ecseIdDependencia,
                  (itemCampo.tipodato) ? itemCampo.tipodato.comaNombrecorto : '',
                  itemCampo.ecseOrden,
                  itemCampo.ecseValores,
                  itemCampo.ecseCol,
                  '',
                  itemCampo.ecseCalculado,
                  itemCampo.ecseDescripcion,
                  itemCampo.ecseRequerido,
                  itemCampo.ecseDefault,
                  itemCampo.ecseScript,
                  itemCampo.ecseConsulta,
                  itemCampo.ecseFilas,
                  itemCampo.ecseEstado,
                  itemCampo.ecseSexo,
                  itemCampo.ecseEdadinicio,
                  itemCampo.ecseEdadfinal,
                  itemCampo.ecseVersion
                ]);
              }
            }
            excel.export_json_to_excel({
              header: head, //Header Required
              data, //Specific data Required
              filename: 'secciones-' + new Date().toLocaleString(), //Optional
              autoWidth: false, //Optional
              bookType: 'xlsx' //Optional
            })
          });
        } else {
          this.$swal.fire({
            title: "Escoja un servicio!",
            text: "Debe filtrar por servicio primero.",
            type: "info"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;

    },
    async eliminar(id) {
      try {
        var result = await this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          })
          .then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.search(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async editar(id) {
      try {
        let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (data) {
          this.form = data;
        }
        this.isNew = false;
        this.dialog = true;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    limpiar() {
      this.form = {
        coenNombre: null,
        coenFecha: new Date().yyyymmdd(),
        coenTipoEstado: 1
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
