<template>
    <v-container lighten-5>
        <v-row v-if="loadingForm" align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
                <Loader />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="auto" md="12">
                <v-form @submit.prevent="agregar" enctype="multipart/form-data">
                    <v-card elevation="0" class="rounded-xl">
                        <v-card-title>
                            <span class="headline">{{formTitle}} empleado</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text :to="{name:'Empleados'}">Cancelar</v-btn>
                            <v-btn color="primary darken-1" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        Datos del empleado
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error-messages="errores['tblConfPersonasnaturales[0].copnNombre1']" label="Primer Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()" :error-messages="errores['tblConfPersonasnaturales[0].copnApellido1']" label="Primer Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.copeIdentificacion = $event.target.value.toUpperCase()" label="Identificacion" :error-messages="errores['copeIdentificacion']" v-model="form.copeIdentificacion"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dense outlined v-model="form.copeTipoIdentificacion" :error-messages="errores['copeTipoIdentificacion']" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.copeTelefono = $event.target.value.toUpperCase()" label="Telefono" v-model="form.copeTelefono"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.copeEmail = $event.target.value.toUpperCase()" label="Email" v-model="form.copeEmail"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dense outlined v-on:keyup="$data.form.copeDireccion = $event.target.value.toUpperCase()" label="Direccion" v-model="form.copeDireccion"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dense outlined v-model="form.copeTipoPais" :error-messages="errores['copeTipoPais']" :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa(form.copeTipoPais)"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.copeTipoPais">
                                        <v-autocomplete dense outlined v-model="form.copeTipoDepartamento" :error-messages="errores['copeTipoDepartamento']" :items="tipodepartamento" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni(form.copeTipoDepartamento)"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.copeTipoDepartamento">
                                        <v-autocomplete dense outlined v-model="form.copeTipoMunicipio" :error-messages="errores['copeTipoMunicipio']" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field dense outlined :error-messages="errores['username']"  label="Usuario" v-model="form.username"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="mt-2">
                                    <v-col cols="12" sm="12" md="4">
                                        <v-autocomplete dense outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoCargo" :error-messages="errores['tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoCargo']" :items="tipocargo" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Cargo" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-autocomplete dense outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoEstado" :error-messages="errores['tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoEstado']" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field dense outlined label="Pie de firma" v-model="form.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprPieFirma"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-file-input dense outlined v-model="file" accept="image/png" chips show-size label="Firma" @change="onFileChange"></v-file-input>
                                        <img v-if="srcfirma" :src="srcfirma" width="300px" height="200px" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';
export default {
  mixins: [generales],
  data() {
    return {
      menu: false,
      files: [],
      file: null,
      url: "sistema/personas/",
      srcfirma: null,
      users: [],
      form: {
        copeId: null,
        copeTelefono: null,
        copeEmail: null,
        copeDireccion: null,
        copeIdentificacion: null,
        copeTipoIdentificacion: null,
        copeTipoPais: null,
        copeTipoDepartamento: null,
        copeTipoMunicipio: null,
        userId: null,
        username:null,
        tblConfPersonasnaturales: [{
          copnNombre1: "",
          copnNombre2: null,
          copnApellido1: null,
          copnApellido2: null,
          tblConfPersonasempleados: [{
            coprTipoCargo: null,
            coprTipoEstado: null,
            coprFirma: null,
            coprPieFirma: null
          }]
        }]
      },
      tipoidentificacion: [],
      tipodepartamento: [],
      tipomunicipio: [],
      tipopais: [],
      tipoestado: [],
      tipocargo: []
    };
  },
  created: async function() {
    let {
      tipopais,
      tipoestado,
      tipocargo,
      tipoidentificacion
    } = await this.$apiService.index("sistema/personas/generalesCreateEmpleado").then(data => data);
    this.tipopais = tipopais;
    this.tipoestado = tipoestado;
    this.tipocargo = tipocargo;
    this.tipoidentificacion = tipoidentificacion;
    this.form.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVO').comaId;
    this.limpiar();
    if (this.$route.params.id) {
      await this.editar(this.$route.params.id);

    }
  },
  methods: {
    onFileChange(file) {
      this.file = file;
    },
    async getDepa(d) {
      this.tipodepartamento = await this.$apiService
        .index("sistema/maestra/index/TBL_TIPODEPART/" + d, )
        .then(data => data);
    },
    async getMuni(d) {
      this.tipomunicipio = await this.$apiService
        .index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + d, )
        .then(data => data);
    },
    async editar(id) {
      try {
        this.loadingForm = true;
        this.form = await this.$apiService.index(this.url + "view/" + id + "/empleados").then(data => data);
        await this.getDepa(this.form.copeTipoPais);
        await this.getMuni(this.form.copeTipoDepartamento);
        this.form.username = this.form.tblAuthUser?.username;
        this.isNew = false;
        this.loadingForm = false;
        if (this.form.copeFirma != null) {
          try {
            this.srcfirma = '/' + this.form.copeFirma;
          } catch (error) {
            console.log(error);
          }
        }
        let data = await this.$apiService.index(this.url + `getFirmaPaciente?copeIdentificacion=${this.form.copeIdentificacion}`).then(data => data);
        if (data && data.mensaje == undefined && data.mensaje != 'No data') {
          this.srcfirma = 'data:image/png;base64,' + data;
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },

    async agregar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let data = (this.isNew) ? await this.$apiService.create(`${this.url}empleado/create/empleados`, this.form).then((data) => data) :
          await this.$apiService.update(`${this.url}empleado/update/${this.form.copeId}/empleados`, this.form).then((data) => data);
        if (data) {
          if (this.file) {
            let formData = new FormData();
            formData.append('file', this.file);
            await this.$apiService.createFile(this.url + `firmaPaciente?copeIdentificacion=${this.form.copeIdentificacion}&copeId=${data.copeId}`, formData).then(data => data);
          }
          this.$swal.fire({
            title: "Completado!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.$router.push("/sistema/empleados");
        }

      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.loadingGuardar = false;
    },
    limpiar() {
      this.form = {
        copeId: null,
        copeTelefono: null,
        copeEmail: null,
        copeDireccion: null,
        copeIdentificacion: null,
        copeTipoIdentificacion: null,
        copeTipoPais: null,
        copeTipoDepartamento: null,
        copeTipoMunicipio: null,
        userId: null,
        username:null,
        tblConfPersonasnaturales: [{
          copnNombre1: null,
          copnNombre2: null,
          copnApellido1: null,
          copnApellido2: null,
          tblConfPersonasempleados: [{
            coprTipoCargo: null,
            coprTipoEstado: null,
            coprFirma: null,
            coprPieFirma: null
          }]
        }]
      };
    }
  }
};

</script>
