<style>
</style>
<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <span class="mx-2 secondary--text display-2 float-left">Documentos</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                    <v-icon dark>filter_list</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="tabla">
                <v-card elevation="0" class="rounded-xl">
                    <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                        <template v-slot:header>
                            <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                            <v-toolbar dark class="mb-1">
                                <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                    agregar
                                </v-btn>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <div class="flex-grow-1"></div>
                            </v-toolbar>
                        </template>
                        <template v-slot:default="{ items, isExpanded, expand }">
                            <v-container class="pt-0">
                                <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                    <v-col v-for="item in items" :key="item.codoId" cols="12" sm="12" md="10" lg="10">
                                        <v-card elevation="0" class="rounded-xl">
                                            <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                <v-row no-gutters>
                                                    <v-col cols="10" sm="10" md="10" lg="10">
                                                        <v-row no-gutters>
                                                            <template v-for="(p,i) in tabla.primarios">
                                                                <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                    {{p.titulo}} <span :class="p.class">
                                                                        {{ p.valor(item) }}
                                                                    </span>
                                                                </v-col>
                                                            </template>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" md="2" lg="2">
                                                        <v-menu bottom left>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <template v-for="(m, i) in tabla.menu">
                                                                    <template v-if="m.show(item)">
                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item v-else :key="i" @click="m.action(item)">
                                                                            <v-list-item-icon>
                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                        </v-list-item>
                                                                    </template>
                                                                </template>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row no-gutters>
                                                    <template v-for="(p,i) in tabla.segundarios">
                                                        <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                            {{p.titulo}} <span :class="p.class">
                                                                {{ p.valor(item) }}
                                                            </span>
                                                        </v-col>
                                                    </template>
                                                    <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                        <v-row>
                                                            <v-col cols="2" sm="2" md="1" lg="1">
                                                                <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                    <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="10" sm="10" md="11" lg="11">
                                                                <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <template v-if="tabla.expandible">
                                                <v-divider v-if="isExpanded(item)"></v-divider>
                                                <v-card-text v-if="isExpanded(item)">
                                                    <v-container>
                                                        <template v-for="items in tabla.expandibleItems(item) ">
                                                            <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                                    <template v-for="(p,i) in tabla.expanItem">
                                                                        <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                            {{p.titulo}} <span :class="p.class">
                                                                                {{ p.valor(items) }}
                                                                            </span>
                                                                        </v-col>
                                                                    </template>
                                                                    <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                        <v-menu bottom left>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <template v-for="(m, i) in tabla.expanMenu">
                                                                                    <template v-if="m.show(items)">
                                                                                        <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                        <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                            <v-list-item-icon>
                                                                                                <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                            </v-list-item-icon>
                                                                                            <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                    </template>
                                                                                </template>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-sheet>
                                                        </template>
                                                    </v-container>
                                                </v-card-text>
                                            </template>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:no-data>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="10" sm="10" md="10" lg="10">
                                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                        No se encontro nada.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:loading>
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:footer>
                            <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                        </template>
                    </v-data-iterator>
                </v-card>
            </v-col>
        </v-row>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="loadingForm" align="center" justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <Loader />
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field label="Codigo" v-on:keyup="$data.form.codoCodigo = $event.target.value.toUpperCase()" outlined v-model="form.codoCodigo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field label="Nombre" v-on:keyup="$data.form.codoNombre = $event.target.value.toUpperCase()" outlined v-model="form.codoNombre"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete v-model="form.codoTipo" :items="tipodocumentos" item-text="comaNombrelargo" item-value="comaId" label="tipo" clearable outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-textarea label="Descripcion" v-on:keyup="$data.form.codoDescripcion = $event.target.value.toUpperCase()" outlined v-model="form.codoDescripcion"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="!isNew">
                                    <v-switch v-model="form.codoEstado" label="Estado"></v-switch>
                                </v-col>
                                <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab href="#tab-1">
                                        Servicios
                                    </v-tab>
                                    <v-tab href="#tab-2">
                                        Cups
                                    </v-tab>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item value="tab-1">
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-row justify="space-around">
                                                        <v-col cols="12" sm="12" md="12">
                                                            <v-toolbar>
                                                                <v-toolbar-title>Servicios</v-toolbar-title>
                                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                                <v-btn text class="" @click="agregarservicios">Agregar nuevo</v-btn>
                                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                                <div class="flex-grow-1"></div>
                                                            </v-toolbar>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-row justify="space-around" v-for="(items,i) in form.servicios" :key="'servicio'+i">
                                                        <v-col cols="12" sm="12" md="8">
                                                            <v-autocomplete outlined v-model="items.coseId" :items="servicios" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio" clearable></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="2">
                                                            <v-btn text icon color="error" @click="eliminarservicios(items)">
                                                                <v-icon>delete</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                        <v-tab-item value="tab-2">
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-row justify="space-around">
                                                        <v-col cols="12" sm="12" md="12">
                                                            <v-toolbar>
                                                                <v-toolbar-title>Cups</v-toolbar-title>
                                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                                <v-btn text class="" @click="agregarcups">Agregar nuevo</v-btn>
                                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                                <div class="flex-grow-1"></div>
                                                            </v-toolbar>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-row justify="space-around" v-for="(items,i) in form.cups" :key="'cup'+i">
                                                        <v-col cols="12" sm="12" md="8">
                                                            <v-autocomplete outlined v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="2">
                                                            <v-btn text icon color="error" @click="eliminarcups(items)">
                                                                <v-icon>delete</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-tabs>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>

<script>
import generales from '../../mixins/generales.js';

export default {
  mixins: [generales],
  data() {
    return {
      url: "sistema/documentos/",
      form: {
        codoId: null,
        codoCodigo: null,
        codoNombre: null,
        codoDescripcion: null,
        codoEstado: 1,
        servicios: [],
        cups: []
      },
      tabla: {
        itemKey: 'codoId',
        orderBy: 'codoId',
        expandible: false,
        expandibleKey: 'codoId',
        expandibleTitle: () => {
          return ``
        },
        expandibleItems: () => {
          return []
        },
        primarios: [{
            titulo: 'CODIGO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.codoCodigo
            },
            show: (item) => {
              return item ? true : false
            }
          }, {
            titulo: 'NOMBRE',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.codoNombre
            },
            show: (item) => {
              return item ? true : false
            }
          },

          {
            titulo: 'ESTADO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return (item.codoEstado) ? 'ACTIVO' : 'INACTIVO'
            },
            show: (item) => {
              return item ? true : false
            }
          },

        ],
        segundarios: [{
          titulo: 'TIPO',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '12',
            lg: '12'
          },
          class: 'primary--text',
          valor: (item) => {
            return (item.tipo) ? item.tipo.comaNombrelargo : ''
          },
          show: (item) => {
            return item ? true : false
          }
        }, ],
        expanItem: [],
        expanMenu: [],
        menu: [
          {
            title: 'Construir',
            icon: 'mdi-file-document-edit',
            color: 'primary',
            tipeAction: 'link',
            action: (item) => {
              return `/sistema/documentos/create/${item.codoId}`
            },
            show: (item) => {
              return item ? this.getPermiso('editarDocumentos') : false
            }
          },{
            title: 'Editar',
            icon: 'create',
            color: 'info',
            tipeAction: 'funcion',
            action: (item) => {
              this.dialog = true;
              return this.editar(item.codoId)
            },
            show: (item) => {
              return item ? this.getPermiso('editarDocumentos') : false
            }
          },
          {
            title: 'Eliminar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => {
              this.eliminar(item.codoId)
            },
            show: (item) => {
              return item ? this.getPermiso('eliminarDocumentos') : false
            }

          },
        ]
      },
      filtros: [{
          campo: "codoCodigo",
          text: "Codigo",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
        },
        {
          campo: "codoNombre",
          text: "Nombre",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
        },
        {
          campo: "codoTipo",
          text: "tipo",
          value: null,
          tipo: "lista",
          lista: [],
          listatext: "codoNombrelargo",
          listavalue: "codoId",
          isLoading: false,
          search: null,
        },
      ],
      tipodocumentos: []
    };
  },
  created: async function() {
    let {
      tipodocumentos
    } = await this.$apiService.index("sistema/documentos/generales").then(data => data);
    this.servicios = await this.$apiService.index("sistema/servicios/index/").then(data => data);
    this.filtros[2].lista = this.tipodocumentos = tipodocumentos;
    await this.search(this.filtros);
  },
  methods: {
    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      const data = await this.$apiService.index(`sistema/documentos/buscar?${this.filtrar(filtros,this.page)}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
     async searchCup(cups) {
      cups.isLoading = true;
      if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
          cups.isLoading = false;
          cups.cups = data;

        });
      }
    },
    async agregar() {
      try {
        this.errores = [];
        this.loadingGuardar = true;
        let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
          await this.$apiService.update(`${this.url}update/${this.form.codoId}`, this.form).then((data) => data);
        if (data) {
          this.close();
          this.$swal.fire({
            title: "Completado!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Ok"
          });
          await this.search(this.filtros);
        }

      } catch (error) {
        if (error.response && 422 == error.response.status) {
          this.errores = error.response.data.errors;
        } else {
          if (error.response && 403 == error.response.status) {
            this.$router.push('/403');
          } else {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.loadingGuardar = false;
    },
    async eliminar(id) {
      try {
        let result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
          if (data) {
            await this.search(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.loadingForm = true;
        this.form = await this.$apiService.index(`${this.url}view/${id}`).then(data => data);
        this.loadingForm = false;
        for (let model of this.form.cups) {
              model.cups = {
                search: null,
                isLoading: false,
                cups: [model.cup]
              };
            }
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    agregarservicios() {
      if (this.form.codoId) {
        this.form.servicios.push({
          codoId: this.form.codoId,
          coseId: null
        });
      } else {
        this.form.servicios.push({
          coseId: null
        });
      }
    },
    eliminarservicios(item) {
      this.form.servicios = this.form.servicios.filter(e => e != item);
    },
    agregarcups() {
      if (this.form.codoId) {
        this.form.cups.push({
          codoId: this.form.codoId,
          cocuId: null,
          cups: {
                search: null,
                isLoading: false,
                cups: []
          }
        });
      } else {
        this.form.cups.push({
          cocuId: null,
          cups: {
                search: null,
                isLoading: false,
                cups: []
          }
        });
      }
    },
    eliminarcups(item) {
      this.form.cups = this.form.cups.filter(e => e != item);
    },
    limpiar() {
      this.form = {
        codoId: null,
        codoCodigo: null,
        codoNombre: null,
        codoDescripcion: null,
        codoEstado: 1,
        servicios: [],
        cups: []
      };
    },
    close() {
      this.errores = [];
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
