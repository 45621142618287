<style>

</style>

<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-data-table :headers="headers" dense :items="lista" sort-desc sort-by="copeId" class="elevation-1">
                    <template v-slot:top>

                        <v-toolbar>
                            <v-toolbar-title class="primary--text">Empresas</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>

                            <v-btn class="mx-2" tile color="primary" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                        <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                    </template>
                    <template v-slot:[`item.tblConfPersonasempresas[0].coemTipoTipoempresa`]="{ item }">
                        {{ getMaestra(item.tblConfPersonasempresas[0].coemTipoTipoempresa,tipoempresa)}}
                    </template>
                    <template v-slot:[`item.copeTipoIdentificacion`]="{ item }">
                        {{ getMaestra(item.copeTipoIdentificacion,tipoidentificacion)}}
                    </template>
                    <template v-slot:[`item.copeTipoMunicipio`]="{ item }">
                        {{ getMaestra(item.copeTipoMunicipio,tipomunicipio)}}
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon fab color="info" class="mr-2" v-on="on" x-small @click.stop="dialog = true" @click="editar(item.copeId)">
                                    <v-icon>create</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn fab x-small icon color="error" v-on="on" @click="eliminar(item.copeId)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <label class="primary--text">No hay datos disponibles</label>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent scrollable max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field :rules="nameRules" outlined label="Nombre" v-on:keyup="$data.form.tblConfPersonasempresas[0].coemNombre = $event.target.value.toUpperCase()" :error="$v.form.tblConfPersonasempresas.$each[0].coemNombre.$error" v-model="form.tblConfPersonasempresas[0].coemNombre"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Codigo habilitacion" v-on:keyup="$data.form.tblConfPersonasempresas[0].coemCodigohabilitacion = $event.target.value.toUpperCase()" v-model="form.tblConfPersonasempresas[0].coemCodigohabilitacion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Codigo subsidiado" v-on:keyup="$data.form.tblConfPersonasempresas[0].coemCodigosubsidiado = $event.target.value.toUpperCase()" v-model="form.tblConfPersonasempresas[0].coemCodigosubsidiado"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field :rules="nameRules" outlined label="Codigo contributivo" v-on:keyup="$data.form.tblConfPersonasempresas[0].coemCodigocontributivo= $event.target.value.toUpperCase()" v-model="form.tblConfPersonasempresas[0].coemCodigocontributivo"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete v-model="form.tblConfPersonasempresas[0].coemTipoTipoempresa" :error="$v.form.tblConfPersonasempresas.$each[0].coemTipoTipoempresa.$error" :items="tipoempresa" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de empresa" clearable outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined :rules="nameRules" :error="$v.form.copeIdentificacion.$error" v-on:keyup="$data.form.copeIdentificacion = $event.target.value.toUpperCase()" label="Identificacion" v-model="form.copeIdentificacion"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete v-model="form.copeTipoIdentificacion" :error="$v.form.copeTipoIdentificacion.$error" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" clearable outlined></v-autocomplete>

                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined :rules="nameRules" label="Telefono" :error="$v.form.copeTelefono.$error" v-model="form.copeTelefono"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined :rules="nameRules" :error="$v.form.copeEmail.$error" v-on:keyup="$data.form.copeEmail = $event.target.value.toUpperCase()" label="Email" v-model="form.copeEmail"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined :rules="nameRules" :error="$v.form.copeDireccion.$error" v-on:keyup="$data.form.copeDireccion = $event.target.value.toUpperCase()" label="Direccion" v-model="form.copeDireccion"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete v-model="form.copeTipoPais" :error="$v.form.copeTipoPais.$error" :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable outlined @change="getDepa(form.copeTipoPais)"></v-autocomplete>

                                </v-col>

                                <v-col cols="12" sm="12" md="4" v-if="form.copeTipoPais">
                                    <v-autocomplete v-model="form.copeTipoDepartamento" :error="$v.form.copeTipoDepartamento.$error" :items="tipodepartamento" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable outlined @change="getMuni(form.copeTipoDepartamento)"></v-autocomplete>

                                </v-col>
                                <v-col cols="12" sm="12" md="4" v-if="form.copeTipoDepartamento">
                                    <v-autocomplete v-model="form.copeTipoMunicipio" :error="$v.form.copeTipoMunicipio.$error" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable outlined></v-autocomplete>

                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="getMaestra(form.tblConfPersonasempresas[0].coemTipoTipoempresa,tipoempresa) == 'IPS'">
                                    <v-switch v-model="form.tblConfPersonasempresas[0].coemPrincipal" label="Principal?"></v-switch>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators'
export default {
  components: {

  },
  data() {
    return {
      dialog: false,
      isNew: true,
      url: "sistema/personas/",
      token: this.$cookies.get("token"),
      nameRules: [v => !!v || "Campo requirido"],
      form: {
        copeId: null,

        copeTelefono: null,

        copeEmail: null,

        copeDireccion: null,

        copeIdentificacion: null,

        copeTipoIdentificacion: null,

        copeTipoPais: null,

        copeTipoDepartamento: null,

        copeTipoMunicipio: null,
        tblConfPersonasempresas: [{
          coemNombre: null,
          coemCodigohabilitacion: null,
          coemCodigosubsidiado: null,
          coemCodigocontributivo: null,
          coemTipoTipoempresa: null,
          coemPrincipal: 0,
        }],

      },
      filtros: [{
          text: "Nombre",
          value: null,
          tipo: "text",
        },

        {
          text: "Identificacion",
          value: null,
          tipo: "text",
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        }, {
          text: "Tipo de identificacion",
          value: null,
          tipo: "lista",
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        },
      ],
      headers: [

        {
          text: "Nombre",
          value: "tblConfPersonasempresas[0].coemNombre"
        }, {
          text: "Codigo habilitacion",
          value: "tblConfPersonasempresas[0].coemCodigohabilitacion"
        }, {
          text: "Codigo subsidiado",
          value: "tblConfPersonasempresas[0].coemCodigosubsidiado"
        }, {
          text: "Codigo contributivo",
          value: "tblConfPersonasempresas[0].coemCodidocontributivo"
        }, {
          text: "Tipo",
          value: "tblConfPersonasempresas[0].coemTipoTipoempresa"
        }, {
          text: "Telefono",
          value: "copeTelefono"
        }, {
          text: "Email",
          value: "copeEmail"
        }, {
          text: "Identificacion",
          value: "copeIdentificacion"
        }, {
          text: "Tipo de Identificacion",
          value: "copeTipoIdentificacion"
        },

        {
          text: "Municipio",
          value: "copeTipoMunicipio"
        }, {
          text: "Acciones",
          value: "action",
          sortable: false
        }
      ],
      lista: [],
      listaCopia: [],
      tipoempresa: [],
      tipoidentificacion: [],
      tipodepartamento: [],
      tipomunicipio: [],
      tipopais: [],
    };
  },
  validations: {
    form: {

      copeTelefono: {
        required,
      },

      copeEmail: {
        required,
      },

      copeDireccion: {
        required,
      },

      copeIdentificacion: {
        required,
      },

      copeTipoIdentificacion: {
        required,
      },

      copeTipoPais: {
        required,
      },

      copeTipoDepartamento: {
        required,
      },

      copeTipoMunicipio: {
        required,
      },

      tblConfPersonasempresas: {
        $each: {

          coemNombre: {
            required,
          },
          coemCodigohabilitacion: {
            required,
          },
          coemTipoTipoempresa: {
            required,
          },
        }
      },

    },
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/empresas").then(data => data);

    this.tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
    this.filtros[2].lista = this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);
    this.tipopais = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAIS").then(data => data);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },

    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => (e.tblConfPersonasempresas[0].coemNombre).indexOf(this.filtros[0].value) != -1
        );
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.copeIdentificacion.indexOf(this.filtros[1].value) != -1
        );
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.copeTipoIdentificacion == this.filtros[2].value
        );
      }

    },

    async getDepa(d) {
      this.tipodepartamento = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + d).then(data => data);
    },
    async getMuni(d) {
      this.tipomunicipio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + d).then(data => data);
    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
           
            let data = await this.$apiService.create(this.url + "create/empresas", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          } else {
          
            var id = this.form.copeId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/empresas", this.form).then(data => data);
            if (data) {
              this.lista = this.lista.filter(e => e.copeId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }

      }
    },
    async eliminar(id) {
      try {
      
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id).then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.copeId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
      
        this.form = await this.$apiService.index(this.url + "view/" + id + "/empresas").then(data => data);
        this.getDepa(this.form.copeTipoPais);
        this.getMuni(this.form.copeTipoDepartamento);

        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {
      this.form = {
        copeId: null,

        copeTelefono: null,

        copeEmail: null,

        copeDireccion: null,

        copeIdentificacion: null,

        copeTipoIdentificacion: null,

        copeTipoPais: null,

        copeTipoDepartamento: null,

        copeTipoMunicipio: null,
        tblConfPersonasempresas: [{
          coemNombre: null,
          coemCodigohabilitacion: null,
          coemCodigosubsidiado: null,
          coemCodigocontributivo: null,
          coemTipoTipoempresa: null,
          coemPrincipal: 0,
        }],
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
