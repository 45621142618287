<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-form @submit.prevent="agregar">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text :to="{name:'Paquetes'}">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="3" md="6">
                                        <v-text-field outlined dense label="Nombre" :error="$v.form.copoNombre.$error" v-model="form.copoNombre" v-on:keyup="$data.form.copoNombre = $event.target.value.toUpperCase()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="6">
                                        <v-autocomplete outlined dense :error="$v.form.copoTipoPaquete.$error" v-model="form.copoTipoPaquete" :items="tipopaquetes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de paquete" clearable>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-text class="grey lighten-3">
                            <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab href="#tab-1">
                                    Cups
                                    <v-icon>mdi-format-list-numbered</v-icon>
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item value="tab-1">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-toolbar>
                                                        <v-toolbar-title>Cups</v-toolbar-title>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <v-btn text class="" @click="agregartblConfPaquetescups">Agregar nuevo</v-btn>
                                                        <v-divider class="mx-4" inset vertical></v-divider>
                                                        <div class="flex-grow-1"></div>
                                                    </v-toolbar>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-row v-for="(items,i) in form.tblConfPaquetescups" :key="'cup'+i">
                                                <v-col cols="12" sm="12" md="12" v-if="items.cups">
                                                    <v-autocomplete outlined dense required v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="4" md="8">
                                                    <v-autocomplete outlined dense :error="$v.form.tblConfPaquetescups.$each[i].copoTipoOrden.$error" v-model="items.copoTipoOrden" :items="tipoordenes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de orden" clearable>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="2">
                                                    <v-btn text icon color="error" @click="eliminartblConfPaquetescups(items)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      loader: false,
      isNew: true,
      url: "sistema/paquetesordenes/",
      token: this.$cookies.get("token"),

      tab: 'tab-1',
      form: {
        copoNombre: null,
        copoTipoPaquete: null,
        tblConfPaquetescups: []
      },

      tipoordenes: [],
      tipopaquetes: []
    };
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  validations: {
    form: {
      copoNombre: {
        required,
      },
      copoTipoPaquete: {
        required,
      },
      tblConfPaquetescups: {
        $each: {
          cocuId: {
            required,
          },
          copoTipoOrden: {
            required,
          },
        }
      },
    },
  },
  created: async function() {
      this.$store.commit('setCargaDatos', true);
      this.tipopaquetes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAQUETES").then(data => data);
      this.tipoordenes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSORDENES").then(data => data);
      if (this.$route.params.id) {
        await this.editar(this.$route.params.id);
      }
      this.$store.commit('setCargaDatos', false);
  },
  methods: {
    async searchCup(cups) {
      cups.isLoading = true;
      if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
          cups.isLoading = false;
          cups.cups = data;
        });
      }
    },
    async editar(id) {
      try {
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            if (!this.form.tblConfPaquetescups) {
              this.form.tblConfPaquetescups = [];
            } else {
              for (let model of this.form.tblConfPaquetescups) {
                model.cups = {
                  search: null,
                  isLoading: false,
                  cups: [model.tblConfCup]
                };
              }
            }
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        this.$store.commit('setCargaDatos', true);
        try {
          if (this.isNew) {
           
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
              if (data) {
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
                this.$router.push("/sistema/paquetes");
            }

          } else {
              var id = this.form.copoId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
              if (data) {
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
                this.$router.push("/sistema/paquetes");
              
            }

          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.$store.commit('setCargaDatos', false);
      }
    },
    agregartblConfPaquetescups() {
      if (this.form.copoId) {
        this.form.tblConfPaquetescups.push({
          copoId: this.form.copoId,
          cocuId: null,
          copoTipoOrden: null,
          cups: {
            search: null,
            isLoading: false,
            cups: []
          },
        });
      } else {
        this.form.tblConfPaquetescups.push({
          cocuId: null,
          copoTipoOrden: null,
          cups: {
            search: null,
            isLoading: false,
            cups: []
          },
        });
      }
    },
    eliminartblConfPaquetescups(item) {
      this.form.tblConfPaquetescups = this.form.tblConfPaquetescups.filter(e => e != item);
    },
  },
}

</script>

<style>

</style>
