<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto" md="3" v-if="datos">
                <EditarCampSeccion :onEliminarCampo="eliminarCampoSeccion" :onGuardar="guardarCampoSeccion" :onCerrarPropiedades="cerrarPropieades" v-bind:datos="datos" v-bind:validaciones="validaciones" />
            </v-col>
            <v-col cols="12" :md="datos?'9':'12'">
                <v-form>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Historia</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-dialog v-model="dialogmasivo" persistent width="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="red lighten-2" dark v-on="on" class="mx-2">
                                        Importar
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="headline grey lighten-2" primary-title>
                                        Cargar excel
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row v-if="!loader">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-file-input outlined v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                <span class="headline">CARGANDO FORMULARIO....</span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-btn color="primary" text target="_blank" :href="plantilla">
                                            Descargar formato
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                            cancelar
                                        </v-btn>
                                        <v-btn color="primary" v-if="!loader" text @click="subida">
                                            cargar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn color="primary" @click="agregarCampoSeccion('SECCION')">
                                Agregar seccion<v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-col cols="12" sm="12" md="2">
                                <v-text-field class="pt-7" outlined dense label="Version" v-model="versionActual" append-icon="search" @click:append="recargar()"></v-text-field>
                            </v-col>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text :to="{name:'Historias'}">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="guardarMasivo()">Guardar todo</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-card-text id="scroll-historia">
                            <v-container>
                                <v-row v-for="seccion in  secciones" :key="'row-'+seccion.hiseCodigo" :id="'CONTENEDOR_'+seccion.hiseCodigo" class=" mt-5 bordes rounded-xl">
                                    <v-col cols="12" sm="12" md="12" class="primary--text cursor" @click="selecccionarCampoSeccion(seccion,'SECCION')">
                                        <v-tooltip v-if="seccion.hiseDescripcion" max-width="300" color="light-blue darken-4" bottom>
                                            <template v-slot:activator="{ on }">
                                                <h3 v-on="on">{{seccion.hiseNombre}}</h3>
                                            </template>
                                            <span>{{seccion.hiseDescripcion}}</span>
                                        </v-tooltip>
                                        <h3 v-else>{{seccion.hiseNombre}}
                                        </h3>
                                    </v-col>
                                    <v-col cols="12" sm="12" :md="c.hiseCol" v-for="c in  seccion.campos" :key="'col-'+c.hiseCodigo" :id="'CAMPO_'+c.hiseCodigo" class="bordesCampos  cursor" @click="selecccionarCampoSeccion(c,'CAMPO')">
                                        <v-tooltip v-if="c.hiseDescripcion" max-width="300" color="light-blue darken-4" right>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" :for="c.hiseCodigo">{{c.hiseNombre}}</span>
                                            </template>
                                            <span>{{c.hiseDescripcion}}</span>
                                        </v-tooltip>
                                        <span v-else :for="c.hiseCodigo">{{c.hiseNombre}}</span>
                                        <br>
                                        <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                            <input type="text" :id="c.hiseCodigo" class="campo">
                                        </template>
                                        <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                            <textarea :id="c.hiseCodigo" :rows="c.hiseFilas" class="campo"></textarea>
                                        </template>
                                        <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                            <select :id="c.hiseCodigo" class="campo">
                                                <option :value="op.values" v-for="(op,index) in getValues(c.hiseValores)" :key="c.hiseCodigo+'_OPTION_'+index">{{op.texts}}</option>
                                            </select>
                                        </template>
                                        <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                            <input type="checkbox" class="campo" :id="c.hiseCodigo">
                                        </template>
                                        <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                            <input type="date" class="campo" :id="c.hiseCodigo">
                                        </template>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-btn color="primary" @click="agregarCampoSeccion('CAMPO',seccion)">
                                            Agregar campo<v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogMasivo" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Guardado masivo</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="!guardando && errores.length > 0">
                            <v-col cols="12" sm="12" md="12">
                                <span class="blue--text">Errores al guardar las siguientes secciones</span>
                                <strong><span class="red--text" v-for="(item,i) in errores" :key="'ERROR_'+i">{{item.hiseNombre}}</span> <br></strong>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="12" class="text-center">
                                <span class="headline">CARGANDO SECCIONES POR FAVOR ESPERE....</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="text-center">
                                <v-progress-linear v-model="porcentaje" color="primary" height="25">
                                    <template v-slot:default="{ value }">
                                        <strong>{{ Math.ceil(value) }}% de {{total}} secciones..</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="dialogMasivo = false;recargar()" v-if="!guardando">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators';
import * as XLSX from 'xlsx'
import plantilla from "@/assets/files/plantilla_historia.xlsx";
export default {
  components: {
    EditarCampSeccion: () => import("../../components/EditarCampSeccion.vue"),
  },
  data() {
    return {
      plantilla: plantilla,
      loader: false,
      isNew: true,
      url: "sistema/historiasecciones/",
      secciones: [],
      datos: null,
      validaciones: {
        hiseCodigo: {
          $error: false
        },
        hiseNombre: {
          $error: false
        },
      },
      tiposecciones: [],
      tipocampos: [],
      depe: [],
      file: null,
      dialogmasivo: false,
      errores: [],
      porcentaje: 0,
      total: 0,
      dialogMasivo: false,
      guardando: false,
      versionActual: 1
    };
  },
  validations: {
    datos: {
      hiseCodigo: {
        required
      },
      hiseNombre: {
        required
      },
      hiseIdTipo: {
        required
      },
      hiseOrden: {
        required
      },
      hiseCol: {
        required
      }
    },
    form: {
      hiseCodigo: {
        required,
      },
      hiseNombre: {
        required,
      },

      hiseIdTipo: {
        required,
      },

      hiseOrden: {
        required,
      },
      campos: {
        $each: {
          hiseCodigo: {
            required,
          },
          hiseNombre: {
            required,
          },

          hiseIdTipo: {
            required,
          },
          hiseTipoTipodato: {
            required,
          },
          hiseCol: {
            required,
          },
          hiseOrden: {
            required,
          },
        }

      },

    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.servicios = await this.$apiService.index("sistema/servicios/index/").then(data => data);
    this.tiposecciones = await this.$apiService.index("sistema/maestra/index/TBL_TIPOHISTORIASECCION").then(data => data);
    this.tipocampos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODATO").then(data => data);
    if (this.$route.params.id) {
      this.secciones = await this.$apiService.index(`${this.url}secciones/${this.$route.params.id}`).then(data => data);
    }
    this.$store.commit('setCargaDatos', false);
  },
  methods: {
    async recargar() {
      this.secciones = await this.$apiService.index(`${this.url}secciones/${this.$route.params.id}?version=${this.versionActual}`).then(data => data);
    },
    getValues(values) {
      const lista = [];
      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },
    selecccionarCampoSeccion(datos, tipo) {
      this.datos = null;
      this.validaciones = {
        hiseCodigo: {
          $error: false
        },
        hiseNombre: {
          $error: false
        },
      }
      this.datos = datos;
      this.datos.tipodatos = this.tipocampos
      this.datos.tipo = tipo;
      if (tipo == 'CAMPO' && this.datos.hiseIdDependencia) {
        this.datos.dependenciaCodigo = this.secciones.find(e => e.hiseId == this.datos.hiseIdDependencia).hiseCodigo;
      }
    },
    agregarCampoSeccion(tipo, seccion = null) {
      try {
        this.datos = null;
        this.validaciones = {
          hiseCodigo: {
            $error: false
          },
          hiseNombre: {
            $error: false
          },
        }
        //this.$nextTick().then(() => {
        this.datos = {
          hiseCodigo: (tipo == 'CAMPO' && seccion) ? seccion.hiseCodigo + '_' + (seccion.campos.length + 1) : null,
          hiseNombre: null,
          hiseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == tipo).comaId,
          hiseIdDependencia: (tipo == 'CAMPO' && seccion) ? seccion.hiseId : null,
          hiseScript: null,
          hiseTipoTipodato: null,
          hiseOrden: (tipo == 'CAMPO' && seccion) ? (seccion.campos.length + 1) : (this.secciones.length + 1),
          hiseValores: null,
          hiseDefault: null,
          hiseDescripcion: null,
          hiseRequerido: 0,
          hiseCol: 3,
          hiseFilas: 4,
          hiseConsulta: null,
          hiseEstado: 1,
          hiseSexo: null,
          hiseEdadinicio: null,
          hiseEdadfinal: null,
          dependenciaCodigo: (tipo == 'CAMPO' && seccion) ? seccion.hiseCodigo : null,
          tipo: tipo,
          tipodatos: this.tipocampos,
          tblConfHistoriasservicios: (tipo == 'CAMPO' && seccion) ? [] : [{
            coseId: parseInt(this.$route.params.id)
          }],
          campos: [],
          hiseVersion: 1,
          hiseTriage: 0,
          hiseImpresion:1,
        };
        //});
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Error!",
          text: "contacte con el administrador",
          icon: "error"
        });
      }
    },
    async guardarCampoSeccion(datos) {
      this.$v.datos.$touch();
      if (!this.$v.datos.$error) {
        await this.guardar(datos)

      } else {
        this.validaciones = this.$v.datos;
      }
    },
    async eliminarCampoSeccion(datos) {
      try {
        var result = await this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          })
          .then(result => result.value);
        if (result) {
          if (datos.hiseId) {
            let data = await this.$apiService.delete(this.url + "delete/" + datos.hiseId + "/").then(data => data);
            if (data) {
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
          }

          if (datos.tipo == 'CAMPO') {
            if (this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo).campos.find(e => e.hiseCodigo == datos.hiseCodigo && e.hiseVersion == datos.hiseVersion)) {
              this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos = this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos.filter(e => e.hiseCodigo != datos.hiseCodigo);
            }
          } else {
            if (this.secciones.find(e => e.hiseCodigo == datos.hiseCodigo && e.hiseVersion == datos.hiseVersion)) {
              this.secciones = this.secciones.filter(e => e.hiseCodigo != datos.hiseCodigo && e.hiseVersion == datos.hiseVersion);
            }
          }
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    cerrarPropieades() {
      this.datos = null;
    },
    async guardar(datos) {
      try {
        this.isNew = this.datos.hiseId ? false : true;
        let data = null;
        let codigos = await this.$apiService.index(`${this.url}index/?offset=0&hiseCodigo=${this.datos.hiseCodigo}&hiseVersion=${this.datos.hiseVersion}`).then(data => data);
        codigos.rows = codigos.rows.filter(e => e.hiseCodigo == this.datos.hiseCodigo)
        if (this.isNew) {
          if (codigos.rows.length > 0) {
            this.$swal.fire({
              title: "El codigo ya esta en uso!",
              text: "por favor escoje otro codigo",
              icon: "info"
            });
            return false;
          }
          data = await this.$apiService.create(this.url + "create", this.datos).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        } else {
          if (codigos.rows.length > 0) {
            if (codigos.rows[0].hiseCodigo != this.datos.hiseCodigo) {
              this.$swal.fire({
                title: "El codigo ya esta en uso!",
                text: "por favor escoje otro codigo",
                icon: "info"
              });
              return false;
            }
          }
          data = await this.$apiService.update(this.url + "update/" + this.datos.hiseId + "/", this.datos).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        }
        data.tipodatos = this.tipocampos
        data.tipo = datos.tipo;
        if (datos.tipo == 'CAMPO') {
          console.log(datos)
          if (this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos.find(e => e.hiseCodigo == datos.hiseCodigo && e.hiseVersion == datos.hiseVersion)) {
            this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos = this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos.filter(e => e.hiseCodigo != datos.hiseCodigo);
          }
          this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos.push(data)
          this.secciones.find(e => e.hiseCodigo == datos.dependenciaCodigo && e.hiseVersion == datos.hiseVersion).campos.sort((a, b) => a.hiseOrden - b.hiseOrden);
        } else {
          if (this.secciones.find(e => e.hiseCodigo == datos.hiseCodigo && e.hiseVersion == datos.hiseVersion)) {
            this.secciones = this.secciones.filter(e => e.hiseCodigo != datos.hiseCodigo);
          }
          this.secciones.push(data)
          this.secciones.sort((a, b) => a.hiseOrden - b.hiseOrden);
        }
        this.datos = data;
        return true;
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
        return false;
      }
    },
    onFileChange(file) {
      this.file = file;
    },
    subida() {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {
            type: 'binary'
          });
          let sheet_name_list = workbook.SheetNames;
          let xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
          let secciones = xlData.filter(e => e.TIPO == 'SECCION');
          let mapXlData = secciones.map(item => {
            let campos = xlData.filter(e => e.DEPENDENCIA == item.ID && e.TIPO == 'CAMPO');
            let mapCampos = campos.map(itemCamp => {
              return {
                hiseCodigo: itemCamp.CODIGO,
                hiseNombre: itemCamp.NOMBRE,
                hiseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == itemCamp.TIPO).comaId,
                hiseTipoTipodato: this.tipocampos.find(e => e.comaNombrecorto == itemCamp.TIPO_DATO).comaId,
                hiseOrden: itemCamp.ORDEN,
                hiseHistorico: (itemCamp.HISTORICO == '1') ? 1 : 0,
                hiseValores: itemCamp.VALORES,
                hiseCol: itemCamp.COLUMNAS,
                hiseDescripcion: itemCamp.DESCRIPCION,
                hiseDefault: itemCamp.DEFAULT,
                hiseRequerido: (itemCamp.REQUERIDO == '1') ? 1 : 0,
                hiseCalculado: (itemCamp.CALCULADO == '1') ? 1 : 0,
                hiseScript: itemCamp.SCRIPT,
                hiseConsulta: itemCamp.CONSULTA,
                hiseFilas: (itemCamp.FILAS) ? parseInt(itemCamp.FILAS) : null,
                hiseEstado: (itemCamp.ESTADO == '1') ? 1 : 0,
                hiseSexo: (itemCamp.SEXO) ? parseInt(itemCamp.SEXO) : null,
                hiseEdadinicio: (itemCamp.EDADINICIO) ? parseInt(itemCamp.EDADINICIO) : null,
                hiseEdadfinal: (itemCamp.EDADFINAL) ? parseInt(itemCamp.EDADFINAL) : null,
                dependenciaCodigo: item.CODIGO,
                tipo: itemCamp.TIPO,
                tipodatos: this.tipocampos,
                tipodato: this.tipocampos.find(e => e.comaNombrecorto == itemCamp.TIPO_DATO),
                tblConfHistoriasservicios: [],
                campos: [],
                hiseVersion: (itemCamp.VERSION) ? parseInt(itemCamp.VERSION) : 1,
                hiseTriage: (itemCamp.TRIAGE) ? parseInt(itemCamp.TRIAGE) : 0,
                hiseImpresion:1,
              }
            });
            let servicios = item.SERVICIOS.split(';');
            let tblConfHistoriasservicios = []
            if (servicios.length > 0) {
              for (let serv of servicios) {
                let ser = this.servicios.find(e => e.coseNombre == serv);
                if (ser) {
                  tblConfHistoriasservicios.push({
                    coseId: ser.coseId,
                  });
                }
              }
            }
            return {
              hiseCodigo: item.CODIGO,
              hiseNombre: item.NOMBRE,
              hiseIdTipo: this.tiposecciones.find(e => e.comaNombrecorto == item.TIPO).comaId,
              hiseOrden: item.ORDEN,
              hiseHistorico: (item.HISTORICO == '1') ? 1 : 0,
              hiseValores: item.VALORES,
              hiseCol: item.COLUMNAS,
              hiseDescripcion: item.DESCRIPCION,
              hiseDefault: item.DEFAULT,
              hiseRequerido: (item.REQUERIDO == '1') ? 1 : 0,
              hiseCalculado: (item.CALCULADO == '1') ? 1 : 0,
              hiseScript: item.SCRIPT,
              hiseConsulta: item.CONSULTA,
              hiseFilas: (item.FILAS) ? parseInt(item.FILAS) : null,
              hiseEstado: (item.ESTADO == '1') ? 1 : 0,
              hiseSexo: (item.SEXO) ? parseInt(item.SEXO) : null,
              hiseEdadinicio: (item.EDADINICIO) ? parseInt(item.EDADINICIO) : null,
              hiseEdadfinal: (item.EDADFINAL) ? parseInt(item.EDADFINAL) : null,
              dependenciaCodigo: null,
              tipo: item.TIPO,
              tblConfHistoriasservicios: (item.tipo == 'CAMPO') ? [] : tblConfHistoriasservicios,
              campos: mapCampos,
              hiseVersion: (item.VERSION) ? parseInt(item.VERSION) : 1,
              hiseTriage: (item.TRIAGE) ? parseInt(item.TRIAGE) : 0,
              hiseImpresion:1,
            }
          })
          let seccionesResult = [];
          for (let iterator of mapXlData) {
            if (this.secciones.find(e => e.hiseCodigo == iterator.hiseCodigo && e.hiseVersion == iterator.hiseVersion)) {
              for (let camp of this.secciones.find(e => e.hiseCodigo == iterator.hiseCodigo && e.hiseVersion == iterator.hiseVersion).campos) {
                if (this.secciones.find(e => e.hiseCodigo == iterator.hiseCodigo && e.hiseVersion == iterator.hiseVersion).campos.find(e => e.hiseCodigo == camp.hiseCodigo && e.hiseVersion == camp.hiseVersion)) {
                  iterator.campos.find(e => e.hiseCodigo == camp.hiseCodigo && e.hiseVersion == camp.hiseVersion).hiseId = this.secciones.find(e => e.hiseCodigo == iterator.hiseCodigo && e.hiseVersion == iterator.hiseVersion).campos.find(e => e.hiseCodigo == camp.hiseCodigo && e.hiseVersion == camp.hiseVersion).hiseId;
                }
              }
              iterator.hiseId = this.secciones.find(e => e.hiseCodigo == iterator.hiseCodigo && e.hiseVersion == iterator.hiseVersion).hiseId;
              //this.secciones = this.secciones.filter(e => e.hiseCodigo != iterator.hiseCodigo && e.hiseVersion != iterator.hiseVersion);
            }
            seccionesResult.push(iterator)
            seccionesResult.sort((a, b) => a.hiseOrden - b.hiseOrden);
            this.secciones = seccionesResult
          }
          
        };
        reader.readAsBinaryString(this.file);
        this.dialogmasivo = false;
        this.$swal.fire({
          title: "Completado!",
          text: "Cargue exitoso",
          icon: "success",
          confirmButtonText: "Ok"
        });
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
        return false;
      }
    },
    async guardarMasivo() {
      this.dialogMasivo = true;
      this.guardando = true;
      this.total = this.secciones.length;
      let cantidad = 0;
      for (let seccion of this.secciones) {
        try {
          cantidad = cantidad + 1;
          this.isNew = seccion.hiseId ? false : true;
          let codigos = await this.$apiService.index(`${this.url}index/?offset=0&hiseCodigo=${seccion.hiseCodigo}&hiseVersion=${seccion.hiseVersion}`).then(data => data);
          if (this.isNew && codigos.rows.length == 0) {
            if (codigos.rows.length > 0) {
              this.errores.push({
                hiseNombre: seccion.hiseNombre,
                hiseError: "El codigo de la seccion ya esta en uso!"
              })
              console.log(this.errores)
            } else {
              let data = await this.$apiService.create(this.url + "create", seccion).then(data => data);
              if (data) {
                this.porcentaje = this.total > 0 ? ((cantidad / this.total) * 100) : 0;
              }
            }
          } else {
            if (codigos.rows.length > 0) {
              if (codigos.rows[0].hiseCodigo != seccion.hiseCodigo) {
                this.errores.push({
                  hiseNombre: seccion.hiseNombre,
                  hiseError: "El codigo de la seccion ya esta en uso!"
                })
                console.log(this.errores)
              } else {
                seccion.hiseId = codigos.rows[0].hiseId;
                seccion.tblConfHistoriasservicios.forEach(e => e.hiseId = seccion.hiseId)
                seccion.campos.forEach(e => e.hiseIdDependencia = seccion.hiseId)
                let data = await this.$apiService.update(this.url + "update/" + seccion.hiseId + "/", seccion).then(data => data);
                if (data) {
                  this.porcentaje = this.total > 0 ? ((cantidad / this.total) * 100) : 0;
                }
              }
            }
          }

        } catch (error) {
          this.errores.push({
            hiseNombre: seccion.hiseNombre,
            hiseError: error
          })
          console.log(this.errores)
        }
      }
      this.guardando = false;
    },
  },
}

</script>

<style>
.campo {
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
}

select.campo {
  -moz-appearance: listbox;
  -webkit-appearance: listbox;
}

.bordes {
  background-color: rgb(255, 255, 255) !important;
}

.bordesCampos {
  border: solid 1px rgb(206, 206, 206);
}

.cursor {
  cursor: pointer;
}

#scroll-historia::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#scroll-historia::-webkit-scrollbar:vertical {
  width: 10px !important;
}

#scroll-historia::-webkit-scrollbar-button:increment,
#scroll-historia::-webkit-scrollbar-button {
  display: none !important;
}

#scroll-historia::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

#scroll-historia::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

#scroll-historia::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

#scroll-historia::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

#scroll-historia {
  background-color: rgb(218, 216, 216) !important;
  height: 1100px;
  overflow: auto;
  scrollbar-color: rgb(76, 113, 159) rgb(199, 220, 242);
  scrollbar-width: thin;
}

</style>
