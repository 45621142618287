<style>
</style>
<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12" v-if="tablaActual">
            <span class="mx-2 secondary--text display-2 float-left">{{tablaActual.comaNombrelargo}}</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
         <v-btn class="mx-2 float-left" fab small dark color="accent" @click="$router.go(-1)">
                <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-container class="pt-0">
                            <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                    <v-card elevation="0" class="rounded-xl">
                                        <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                            <v-row no-gutters>
                                                <v-col cols="10" sm="10" md="10" lg="10">
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.primarios">
                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="2" sm="2" md="2" lg="2">
                                                    <v-menu bottom left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <template v-for="(m, i) in tabla.menu">
                                                                <template v-if="m.show(item)">
                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item v-else :key="i" @click="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                </template>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <template v-for="(p,i) in tabla.segundarios">
                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                        {{p.titulo}} <span :class="p.class">
                                                            {{ p.valor(item) }}
                                                        </span>
                                                    </v-col>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                    <v-row>
                                                        <v-col cols="2" sm="2" md="1" lg="1">
                                                            <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                <v-icon v-else>mdi-chevron-up</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="10" sm="10" md="11" lg="11">
                                                            <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <template v-if="tabla.expandible">
                                            <v-divider v-if="isExpanded(item)"></v-divider>
                                            <v-card-text v-if="isExpanded(item)">
                                                <v-container>
                                                    <template v-for="items in tabla.expandibleItems(item) ">
                                                        <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                            <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                                <template v-for="(p,i) in tabla.expanItem">
                                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(items) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                                <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                    <v-menu bottom left>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-list>
                                                                            <template v-for="(m, i) in tabla.expanMenu">
                                                                                <template v-if="m.show(items)">
                                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                    <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            </template>
                                                                        </v-list>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-sheet>
                                                    </template>
                                                </v-container>
                                            </v-card-text>
                                        </template>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>

    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="loadingForm" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field :rules="nameRules" label="Nombre corto" v-on:keyup="$data.form.comaNombrecorto = $event.target.value.toUpperCase()" outlined v-model="form.comaNombrecorto"></v-text-field>
                            </v-col>

                            <!-- <v-col cols="12" sm="12" md="4">
                                <v-autocomplete v-model="form.comaTipo" :loading="tipos.isLoading" :search-input.sync="tipos.search" hide-no-data hide-selected no-filter :items="tipos.tipos" @keypress.enter="getTipos" item-text="comaNombrelargo" item-value="comaId" label="tipo" clearable outlined></v-autocomplete>
                            </v-col> -->

                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete :return-object="false" v-model="form.comaDependencia" :value="form.comaDependencia" :loading="dependencias.isLoading" :search-input.sync="dependencias.search" hide-no-data hide-selected no-filter :items="dependencias.dependencias" @keypress.enter="getDependencia" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Dependencia" clearable outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-textarea :rules="nameRules" label="Nombre largo" v-on:keyup="$data.form.comaNombrelargo = $event.target.value.toUpperCase()" outlined v-model="form.comaNombrelargo"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-textarea label="Valor" outlined v-model="form.comaValor"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="!isNew">
                                <v-switch v-model="form.comaEstado" label="Estado"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog2" persistent max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="loadingForm" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                           <v-col cols="12" sm="12" md="4">
                                <v-text-field :rules="nameRules" label="Nombre corto" v-on:keyup="$data.form.comaNombrecorto = $event.target.value.toUpperCase()" outlined v-model="form.comaNombrecorto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete :return-object="false" v-model="form.comaDependencia" :value="form.comaDependencia" :loading="dependencias.isLoading" :search-input.sync="dependencias.search" hide-no-data hide-selected no-filter :items="dependencias.dependencias" @keypress.enter="getDependencia" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Dependencia" clearable outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea :rules="nameRules" label="Nombre largo" v-on:keyup="$data.form.comaNombrelargo = $event.target.value.toUpperCase()" outlined v-model="form.comaNombrelargo"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea label="Valor" outlined v-model="form.comaValor"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="!isNew">
                                <v-switch v-model="form.comaEstado" label="Estado"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>
<script>
import generales from '../../../mixins/generales.js';

export default {
    mixins: [generales],
    data() {
        return {
            url: "sistema/maestra/",
            tipos: {
                isLoading: false,
                search: null,
                tipos: []
            },
            dependencias: {
                isLoading: false,
                search: null,
                dependencias: []
            },
            form: {
                comaId: null,
                comaNombrecorto: null,
                comaNombrelargo: null,
                comaTipo: null,
                comaDependencia: null,
                comaValor: null,
                comaEstado: 1
            },
            tabla: {
                itemKey: 'comaId',
                orderBy: 'comaId',
                expandible: true,
                expandibleKey: 'comaId',
                expandibleTitle: (item) => { return `HIJOS ${item.tipos?item.tipos.length:0}` },
                expandibleItems: (item) => { return item.tipos?item.tipos:[] },
                primarios: [{
                        titulo: 'CODIGO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.comaNombrecorto },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'NOMBRE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.comaNombrelargo },
                        show: (item) => { return item ? true : false }
                    },

                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.comaEstado) ? 'ACTIVO' : 'INACTIVO' },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'TIPO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.tipo) ? item.tipo.comaNombrelargo : '' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'DEPENDENCIA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.dependencia) ? item.dependencia.comaNombrelargo : '' },
                        show: (item) => { return item ? true : false }
                    }
                ],
                expanItem: [{
                        titulo: 'CODIGO',
                        tamaño: {
                            cols: '12',
                            sm: '4',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.comaNombrecorto },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'NOMBRE',
                        tamaño: {
                            cols: '12',
                            sm: '8',
                            md: '8',
                            lg: '8'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.comaNombrelargo },
                        show: (item) => { return item ? true : false }
                    },

                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '2',
                            md: '2',
                            lg: '2'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.comaEstado) ? 'ACTIVO' : 'INACTIVO' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'DEPENDENCIA',
                        tamaño: {
                            cols: '12',
                            sm: '3',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.dependencia) ? item.dependencia.comaNombrelargo : '' },
                        show: (item) => { return item ? true : false }
                    }
                ],
                expanMenu: [{
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog = true; return this.editar(item.comaId) },
                        show: (item) => { return item ? this.getPermiso('editarMaestro') : false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.comaId) },
                        show: (item) => { return item ? this.getPermiso('eliminarMaestro') : false }

                    },
                ],
                menu: [
                 {
                        title: 'Ver tabla',
                        icon: 'mdi-table-search',
                        color: 'primary',
                        tipeAction: 'link',
                        action: (item) => {
                            return `/sistema/maestra/tabla/${item.comaId}`
                        },
                        show: (item) => { return item ? this.getPermiso('verMaestro') : false }
                    },
                 {
                        title: 'Agregar',
                        icon: 'mdi-plus',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => {
                            this.dialog2 = true;
                            this.form.comaTipo = item.comaId
                        },
                        show: (item) => { return item ? this.getPermiso('crearMaestro') : false }
                    }, {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog = true; return this.editar(item.comaId) },
                        show: (item) => { return item ? this.getPermiso('editarMaestro') : false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.comaId) },
                        show: (item) => { return item ? this.getPermiso('eliminarMaestro') : false }

                    },
                ]
            },
            filtros: [{
                    campo: "comaId",
                    text: "id",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                },
                {
                    campo: "comaNombrecorto",
                    text: "Nombre corto",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                },
                {
                    campo: "comaNombrelargo",
                    text: "Nombre largo",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                },

                /*{
                    campo: "comaTipo",
                    text: "tipo",
                    value: null,
                    tipo: "lista",
                    lista: [],
                    funcionSearch: (search) => { return this.searchMaestra(search) },
                    listatext: "comaNombrelargo",
                    listavalue: "comaId",
                    buscar: 'maestra',
                    isLoading: false,
                    search: null,
                },*/
                {
                    campo: "comaDependencia",
                    text: "Dependencia",
                    value: null,
                    tipo: "lista",
                    lista: [],
                    funcionSearch: (search) => { return this.searchMaestra(search) },
                    listatext: "comaNombrelargo",
                    listavalue: "comaId",
                    buscar: 'maestra',
                    isLoading: false,
                    search: null,
                },
                {
                    campo: "comaValor",
                    text: "Valor",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                }
            ],
            tablaActual:null
        };
    },
    created: async function () {
      if(this.$route.params.id){
        await this.search(this.filtros);
        this.form.comaTipo = this.$route.params.id
        this.tablaActual = await this.$apiService.index(`${this.url}view/${this.$route.params.id}`).then(data => data);
      }
        
    },

    methods: {
        async getDependencia() {
            this.dependencias.isLoading = true;
            if (this.dependencias.search.length > 0 && this.dependencias.search.toString().replace(/ /g, "") != "") {
                await this.$apiService.index("sistema/maestra/buscar/" + this.dependencias.search.toUpperCase()).then(data => {
                    this.dependencias.dependencias = data;
                });
            }
            this.dependencias.isLoading = false;
        },
        async getTipos() {
            this.tipos.isLoading = true;
            if (this.tipos.search.length > 0 && this.tipos.search.toString().replace(/ /g, "") != "") {
                await this.$apiService.index("sistema/maestra/buscar/" + this.tipos.search.toUpperCase()).then(data => {
                    this.tipos.tipos = data;
                });
            }
            this.tipos.isLoading = false;
        },
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`sistema/maestra/buscar2?${this.filtrar(filtros,this.page)}&comaTipo=${this.$route.params.id}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },

        getDepe(id) {
            if (id != null) {
                if (this.dependencias.length > 0) {
                    var dependencias = this.dependencias.filter(e => e.comaId == id);
                    if (dependencias.length > 0) {
                        var dependencia = dependencias[0];
                        return dependencia.comaNombrelargo;
                    }
                }
            }
        },

        async agregar() {
            try {
                this.errores = [];
                this.loadingGuardar = true;
                let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
                    await this.$apiService.update(`${this.url}update/${this.form.comaId}`, this.form).then((data) => data);
                if (data) {
                    this.close();
                    this.$swal.fire({
                        title: "Completado!",
                        text: "Registro exitoso",
                        icon: "success",
                        confirmButtonText: "Ok"
                    });
                    await this.search(this.filtros);
                }

            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }

                }
            }
            this.loadingGuardar = false;
        },
        async eliminar(id) {
            try {
                let result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
                    if (data) {
                        await this.search(this.filtros);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {
            try {
                this.loadingForm = true;
                this.form = await this.$apiService.index(`${this.url}view/${id}`).then(data => data);
                if (this.form.comaTipo) {
                    this.tipos.tipos = [];
                    this.tipos.tipos.push(this.form.tipo);
                    //this.tipos.tipos.push(await this.$apiService.index(`${this.url}${this.form.comaTipo}`).then(data => data));
                }
                if (this.form.comaDependencia) {
                    this.dependencias.dependencias = [];
                    this.dependencias.dependencias.push(this.form.dependencia);
                    // this.dependencias.dependencias.push(await this.$apiService.index(`${this.url}${this.form.comaDependencia}`).then(data => data));
                }
                this.loadingForm = false;
                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        limpiar() {
            this.form = {
                comaId: null,
                comaNombrecorto: null,
                comaNombrelargo: null,
                comaTipo: this.$route.params.id,
                comaDependencia: null,
                comaValor: null,
                comaEstado: 1
            };
            this.tipos.tipos = [];
            this.dependencias.dependencias = [];
        },
        close() {
            this.errores = [];
            this.isNew = true;
            this.dialog = false;
            this.dialog2 = false;
            this.limpiar();
        }
    }
};
</script>
