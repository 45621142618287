<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{formTitle}} representante</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text :to="{name:'Representantes'}">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    Datos del representante
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error="$v.form.tblConfPersonasnaturales.$each[0].copnNombre1.$error" :rules="nameRules" label="Primer Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()" :rules="nameRules" :error="$v.form.tblConfPersonasnaturales.$each[0].copnApellido1.$error" label="Primer Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined v-on:keyup="$data.form.copeIdentificacion = $event.target.value.toUpperCase()" :rules="nameRules" label="Identificacion" :error="$v.form.copeIdentificacion.$error" v-model="form.copeIdentificacion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" v-model="form.copeTipoIdentificacion" :error="$v.form.copeTipoIdentificacion.$error" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined v-on:keyup="$data.form.copeTelefono = $event.target.value.toUpperCase()" :rules="nameRules" label="Telefono" v-model="form.copeTelefono"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined v-on:keyup="$data.form.copeEmail = $event.target.value.toUpperCase()" :rules="nameRules" label="Email" v-model="form.copeEmail"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined v-on:keyup="$data.form.copeDireccion = $event.target.value.toUpperCase()" :rules="nameRules" label="Direccion" v-model="form.copeDireccion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined v-model="form.copeTipoPais" :rules="nameRules" :error="$v.form.copeTipoPais.$error" :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa(form.copeTipoPais)"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.copeTipoPais">
                                    <v-autocomplete outlined v-model="form.copeTipoDepartamento" :rules="nameRules" :error="$v.form.copeTipoDepartamento.$error" :items="tipodepartamento" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni(form.copeTipoDepartamento)"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.copeTipoDepartamento">
                                    <v-autocomplete outlined v-model="form.copeTipoMunicipio" :rules="nameRules" :error="$v.form.copeTipoMunicipio.$error" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>

                                <v-col cols="12" sm="12" md="3">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :rules="nameRules" outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].cpreFechainicio" label="Fecha inicio" prepend-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].cpreFechainicio" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonasrepresentantes.$each[0].cpreFechainicio.$error" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :rules="nameRules" outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].cpreFechafinal" label="Fecha final" prepend-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].cpreFechafinal" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonasrepresentantes.$each[0].cpreFechafinal.$error" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete outlined :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].cpreTipoEstado" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonasrepresentantes.$each[0].cpreTipoEstado.$error" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required v-model="form.tblConfPersonasnaturales[0].tblConfPersonasrepresentantes[0].coemId" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonasrepresentantes.$each[0].coemId.$error" :items="empresas" item-text="tblConfPersonasempresas[0].coemNombre" item-value="tblConfPersonasempresas[0].coemId" item-color="primary" label="Empresa" clearable>
                                    </v-autocomplete>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                </v-card>
            </v-form>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    required
}
from 'vuelidate/lib/validators'
export default {

    data() {
        return {
            isNew: true,
            url: "sistema/personas/",
            token: this.$cookies.get("token"),
            
            nameRules: [v => !!v || "Campo requirido",

            ],
            menu: false,
            menu2: false,
            empresas: [],
            maestra: [],
            form: {

                copeTelefono: null,

                copeEmail: null,

                copeDireccion: null,

                copeIdentificacion: null,

                copeTipoIdentificacion: null,

                copeTipoPais: null,

                copeTipoDepartamento: null,

                copeTipoMunicipio: null,
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnNombre2: null,
                    copnApellido1: null,
                    copnApellido2: null,
                    tblConfPersonasrepresentantes: [{

                        cpreFechainicio: new Date().yyyymmdd(),

                        cpreFechafinal: new Date().yyyymmdd(),

                        cpreTipoEstado: null,

                        coemId: null,

                    }]
                }],

            },
            tipoidentificacion: [],
            tipodepartamento: [],
            tipomunicipio: [],
            tipopais: [],

            tipoestado: [],

        }
    },
    validations: {
        form: {

            copeIdentificacion: {
                required,
            },

            copeTipoIdentificacion: {
                required,
            },

            copeTipoPais: {
                required,
            },

            copeTipoDepartamento: {
                required,
            },

            copeTipoMunicipio: {
                required,
            },
            tblConfPersonasnaturales: {
                $each: {
                    copnNombre1: {
                        required,
                    },
                    copnApellido1: {
                        required,
                    },
                    tblConfPersonasrepresentantes: {
                        $each: {
                            cpreFechainicio: {
                                required,
                            },

                            cpreFechafinal: {
                                required,
                            },

                            cpreTipoEstado: {
                                required,
                            },

                            coemId: {
                                required,
                            },

                        }

                    },

                }
            },

        },
    },
    created: async function () {
        

        this.tipoidentificacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOIDENT").then(data => data);
        this.tipopais = this.tipopaisn = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAIS").then(data => data);
        this.tipoestado = await this.$apiService.index("sistema/maestra/index/TBL_TIPOESTADO").then(data => data);
        this.empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
        this.limpiar();

        if (this.$route.params.id) {
            this.editar(this.$route.params.id);
        }
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Registrar nuevo" : "Actualizar";
        }
    },

    methods: {

        async getDepa(d) {
            this.tipodepartamento = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + d).then(data => data);
        },
        async getMuni(d) {
            this.tipomunicipio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + d).then(data => data);
        },

        async editar(id) {
            try {
                this.form = await this.$apiService.index(this.url + "view/" + id + "/representantes").then(data => data);
                this.getDepa(this.form.copeTipoPais);
                this.getMuni(this.form.copeTipoDepartamento);
                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

        async agregar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    if (this.isNew) {
                     
                        let data = await this.$apiService.create(this.url + "create/representantes", this.form).then(data => data);
                        if (data) {
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                            this.$router.push("/sistema/representantes");

                        }

                    } else {
                     
                        var id = this.form.copeId;
                        let data = await this.$apiService.update(this.url + "update/" + id + "/representantes", this.form).then(data => data);
                        if (data) {
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                            this.$router.push("/sistema/representantes");

                        }

                    }
                } catch (error) {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }

            }
        },
        limpiar() {
            this.form = {

                copeTelefono: null,

                copeEmail: null,

                copeDireccion: null,

                copeIdentificacion: null,

                copeTipoIdentificacion: null,

                copeTipoPais: null,

                copeTipoDepartamento: null,

                copeTipoMunicipio: null,
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnNombre2: null,
                    copnApellido1: null,
                    copnApellido2: null,
                    tblConfPersonasrepresentantes: [{

                        cpreFechainicio: new Date().yyyymmdd(),

                        cpreFechafinal: new Date().yyyymmdd(),

                        cpreTipoEstado: null,

                        coemId: null,

                    }]
                }],

            };
        },
    }

}
</script>
