<style>
</style>

<template>
    <v-container lighten-5>
        <v-row>
            <v-col cols="auto" md="12">
                <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" item-key="copoId" single-expand hide-default-footer sort-desc sort-by="copoNombre" class="elevation-1">
                    <template v-slot:header>
                        <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                        <v-toolbar dark color="primary darken-3" class="mb-1">
                            <v-toolbar-title class="white--text">Paquetes</v-toolbar-title>
                            <v-btn class="mx-2 primary--text" tile color="white" :to="{name:'Paquetecreate'}">
                                agregar
                            </v-btn>
                            <v-form @submit.prevent="subida" enctype="multipart/form-data">
                                <v-dialog v-model="dialogmasivo" persistent width="500">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="red lighten-2" dark v-on="on">
                                            Carga masiva
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="headline grey lighten-2" primary-title>
                                            Cargar excel
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row v-if="!loader">
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-file-input outlined :rules="nameRules" v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <span class="headline">CARGANDO PAQUETE....</span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" class="text-center">
                                                    <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-btn color="primary" text target="_blank" :href="plantilla">
                                                Descargar formato
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                                cancelar
                                            </v-btn>

                                            <v-btn color="primary" v-if="!loader" text @click="subida">
                                                cargar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-form>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab color="white" class="primary--text" :loading="LoadingExp" small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="exportar">
                                        <v-list-item-title>Exportar</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-row align="center" justify="center">
                            <v-col v-for="item in items" :key="item.copoId" cols="12" sm="12" md="10" lg="10">
                                <v-card>
                                    <v-card-title class="subtitle-1 font-weight-bold">
                                        <v-row no-gutters>
                                            <v-col cols="9" sm="9" md="9" lg="9">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="12" md="12" lg="12">
                                                        NOMBRE <span class="primary--text">
                                                            {{ item.copoNombre}}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        TIPO <span class="primary--text">
                                                            {{ item.tipo.comaNombrelargo }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="3" sm="3" md="3" lg="3">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined color="info" class="mr-2" v-on="on" small :to="{name:'Paqueteupdate',params:{id:item.copoId}}">
                                                            <v-icon>create</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined small color="error" v-on="on" @click="eliminar(item.copoId)">
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-row>
                                                    <v-col cols="1" sm="1" md="1" lg="1">
                                                        <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                            <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="11" sm="11" md="11" lg="11">
                                                        <span class="headline primary--text">CUPS {{item.tblConfPaquetescups.length}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text v-if="isExpanded(item)">
                                        <v-container>
                                            <template v-for="items in item.tblConfPaquetescups ">
                                                <v-sheet min-height="100%" width="100%" :key="items.copcId">
                                                    <v-row no-gutters class="grey lighten-3 mb-5 py-2 px-2">
                                                        <v-col cols="12" sm="9" md="9" lg="9">
                                                            CODIGO <span class="primary--text">
                                                                {{ items.tblConfCup.cocuCups }}</span> ,
                                                            NOMBRE <span class="primary--text">
                                                                {{ items.tblConfCup.cocuNombre}}

                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3" lg="3">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn outlined small color="error" v-on="on" @click="eliminarCup(items.copcId)">
                                                                        <v-icon>delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </template>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template> <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <v-row class="mt-2 pb-5" align="center" justify="center">
                            <span class="mr-4 grey--text">
                                Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                            </span>
                            <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import plantilla from "@/assets/files/plantilla_paquetes.xlsx";

export default {

  data() {
    return {
      plantilla: null,
      dialogmasivo: false,
      dialog: false,
      loader: false,
      LoadingExp: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      isNew: true,
      file: null,
      url: "sistema/paquetesordenes/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],
      secciones: [],
      servicios: [],
      filtros: [{
          text: "Nombre",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                listatext: "nombre del campo que se mostrara",
                listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
                defaulttext: "tblConfPersonasnaturales",
                defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                defaultsubtext: "copeIdentificacion",*/
        },
        {
          text: "Tipo",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
          //para select y items personalizados
          /*  default: true,
                defaulttext: "tblConfPersonasnaturales",
                defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                defaultsubtext: "copeIdentificacion",*/
        }
      ],
      lista: [],
      listaCopia: []
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {

      this.plantilla = plantilla;
      this.$store.commit("setCargaDatos", true);
      this.filtros[1].lista = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPAQUETES").then(data => data);
      await this.buscar(this.filtros);
      this.$store.commit("setCargaDatos", false);
  },

  methods: {
    onFileChange(file) {
      this.file = file;
    },
    async search(searchP) {
      this.$store.commit("setCargaDatos", true);
      const data = await this.$apiService.index(`${this.url}buscar?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit("setCargaDatos", false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page - 1) * 10}&`;

      if (
        this.filtros[0].value != null &&
        this.filtros[0].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `copoNombre=${this.filtros[0].value}&`;
      }
      if (
        this.filtros[1].value != null &&
        this.filtros[1].value.toString().replace(/ /g, "") != ""
      ) {
        search = search + `copoTipoPaquete=${this.filtros[1].value}&`;
      }

      if (search != "") {
        await this.search(search);
      }
    },
    async subida() {
      try {
          if (this.file != null) {
            let formData = new FormData();
            formData.append("file", this.file);
            this.loader = true;
            let data = await this.$apiService
              .createFile(this.url + "masiva", formData)
              .then(data => data);
            if (data) {
              this.loader = false;
              this.file = null;
              this.$store.commit("setCargaDatos", true);
              await this.buscar(this.filtros);
              this.$store.commit("setCargaDatos", false);
              this.$swal.fire({
                title: "Completado!",
                text: "Subida exitosa",
                icon: "success",
                confirmButtonText: "Ok"
              });
            
          } else {
            this.loader = false;
            this.$swal.fire({
              title: "Cuidado!",
              text: "Selecciona un archivo primero",
              type: "warnning",
              confirmButtonText: "Ok"
            });
          }
        }
      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;
    },
    async exportar() {
      this.LoadingExp = true;
      try {
        var search = ``;

        if (
          this.filtros[0].value != null &&
          this.filtros[0].value.toString().replace(/ /g, "") != ""
        ) {
          search = search + `copoNombre=${this.filtros[0].value}&`;
        }
        if (
          this.filtros[1].value != null &&
          this.filtros[1].value.toString().replace(/ /g, "") != ""
        ) {
          search = search + `copoTipoPaquete=${this.filtros[1].value}&`;
        }
        let paquetes = await this.$apiService
          .index(`${this.url}index?${search}`)
          .then(data => data);

        import('@/vendor/Export2Excel').then(excel => {
          let data = [];
          let head = ['PAQUETENOMBRE', 'TIPOPAQUETE', 'CUPS', 'TIPOORDEN'];
          for (let item of paquetes) {
            for (let cup of item.tblConfPaquetescups) {
              data[0].data.push([
                item.copoNombre,
                item.tipo.comaNombrecorto,
                cup.tblConfCup.cocuCups,
                cup.tipo.comaNombrecorto
              ]);
            }
          }
          excel.export_json_to_excel({
            header: head, //Header Required
            data, //Specific data Required
            filename: 'paquetes-' + new Date().toLocaleString(), //Optional
            autoWidth: false, //Optional
            bookType: 'xlsx' //Optional
          })
        });

      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;

    },
    async eliminar(id) {
      try {
          var result = await this.$swal
            .fire({
              title: "Estas seguro?",
              text: "No podras revertir esto!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, eliminar!"
            })
            .then(result => result.value);
          if (result) {
            let data = await this.$apiService
              .delete(this.url + "delete/" + id + "/")
              .then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async eliminarCup(id) {
      try {
          var result = await this.$swal
            .fire({
              title: "Estas seguro?",
              text: "No podras revertir esto!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, eliminar!"
            })
            .then(result => result.value);
          if (result) {
            let data = await this.$apiService
              .delete(this.url + "delete/cup/" + id + "/")
              .then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },

  }
};

</script>
