<template>
    <v-container lighten-5>

        <v-row v-if="model">

            <v-col cols="12" sm="12" md="12">

                <v-btn class="mx-2 float-left rounded-xl" fab small @click="$router.push('/sistema/empleados')">

                    <v-icon dark>navigate_before</v-icon>

                </v-btn>

            </v-col>

            <v-col cols="12" sm="12" md="12">

                <v-row>

                    <v-col cols="auto" md="12">

                        <v-card elevation="0" class="rounded-xl">

                            <v-card-title>

                                <span class="primary--text display-2">Empleado</span>

                            </v-card-title>

                            <v-divider></v-divider>

                            <v-card-text>

                                <v-container>

                                    <v-row>

                                        <v-col cols="12" sm="12" md="12"><span class="primary--text headline">Datos del empleado</span></v-col>

                                        <v-col cols="12" sm="12" md="4">

                                            <span class="primary--text"> NOMBRE </span>{{ model.tblConfPersonasnaturales[0].copnNombre1 }} {{ model.tblConfPersonasnaturales[0].copnNombre2 }} {{ model.tblConfPersonasnaturales[0].copnApellido1 }} {{ model.tblConfPersonasnaturales[0].copnApellido2

}}

                                        </v-col>

                                        <v-col cols="12" sm="12" md="4">

                                            <span class="primary--text"> CARGO </span>{{ model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo?model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo.comaNombrelargo:'NO REGISTRADO' }}

                                        </v-col>

                                        <v-col cols="12" sm="12" md="4">

                                            <span class="primary--text"> ESTADO </span>{{ model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado? model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto:'NO REGISTRADO' }}

                                        </v-col>

                                    </v-row>

                                </v-container>

                            </v-card-text>

                        </v-card>

                    </v-col>

                </v-row>

            </v-col>

            <v-col cols="12" sm="12" md="12">

                <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text>

                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">

                        Horario

                        <v-icon>mdi-calendar</v-icon>

                    </v-tab>

                    <v-tab href="#tab-2">

                        Permisos

                        <v-icon>mdi-account-box</v-icon>

                    </v-tab>

                    <v-tab href="#tab-3">

                        Servicios

                        <v-icon>mdi-account-box</v-icon>

                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">

                    <v-tab-item value="tab-1">

                        <Horario :coprId="model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" />

                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <EmpleadoPermiso :coprId="model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" v-if="model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" />
                    </v-tab-item>

                    <v-tab-item value="tab-3">

                        <Servicio :coprId="model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" v-if="model.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" />

                    </v-tab-item>

                </v-tabs-items>

            </v-col>

        </v-row>

        <v-row v-else align="center" justify="center">

            <v-col cols="12" sm="12" md="12" lg="12">

                <Loader />

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
import generales from "../../../mixins/generales.js";
export default {
  mixins: [generales],
  components: {
    Horario: () =>
      import("./Horarios.vue"),
    Servicio: () =>
      import("./Servicios.vue"),
    EmpleadoPermiso: () =>
      import("./EmpleadoPermiso.vue")
  },
  data() {
    return {
      user: null,
      url: "sistema/personas/",
      model: null
    };
  },
  created: async function() {
    if (this.$route.params.id) {
      this.model = await this.$apiService
        .index(
          this.url + "view/" + this.$route.params.id + "/empleados",
        )
        .then(data => data);
    }
  }
};

</script>
